import { Component, Input, OnInit } from '@angular/core';
import * as moment from 'moment';
import { AxTask } from 'src/app/_generated/axova-rest-api';

@Component({
  selector: 'ax-appointment',
  templateUrl: './ax-appointment.component.html',
  styleUrls: ['./ax-appointment.component.scss'],
})
export class AxAppointmentComponent implements OnInit {
  @Input() appointment: AxTask;
  @Input() isFirst: boolean = false;
  @Input() isLast: boolean = false;
  public formattedDate;
  public isStartAndEndDateOnSameDay = true;
  public isGeruestBau = true;

  constructor() {
  }

  public ngOnInit() {
    this.setFormattedDate();
    this.setIsStartAndEndDateOnSameDay();
    this.setIsGeruestbau();
  }

  private setFormattedDate() {
    this.formattedDate = moment(this.appointment.start).clone()
      .locale('de')
      .local()
      .calendar(null, {
        lastDay: '[Gestern]',
        sameDay: '[Heute]',
        nextDay: '[Morgen]',
        lastWeek: '[letzten] dddd',
        nextWeek: 'dddd',
        sameElse: 'D. MMMM',
      });
  }

  private setIsStartAndEndDateOnSameDay() {
    const startDate = moment(this.appointment.start);
    const endDate = moment(this.appointment.end);
    this.isStartAndEndDateOnSameDay = startDate.isSame(endDate, 'day');
  }

  private setIsGeruestbau() {
    this.isGeruestBau = this.appointment.name.indexOf('Gerüst') > -1;
  }

  protected readonly moment = moment;
}
