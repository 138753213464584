import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { LoggerService } from 'src/app/ax-commons-logger/logger/logger.service';
import { AppState } from 'src/app/ngxs/app/app.state';

@Injectable({
  providedIn: 'root',
})
export class AxIsLoggedInGuard implements CanActivate {
  constructor(
    private store: Store,
    private router: Router,
  ) {
  }

  /**
   * Checks if the user is logged in or not.
   *
   * @param next
   * @param state
   */
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    if (!this.store.selectSnapshot(AppState.profile)) {
      this.router.navigate(['/auth/login'], {queryParams: {returnUrl: state.url}}).then();
      return false;
    }
    return true;
  }
}
