import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'lineBreaks'})
export class LineBreaksPipe implements PipeTransform {
  transform(value: any) {
    if (!value) {
      return '';
    }
    try {
      return value.replaceAll('\r\n', '<br>');
    } catch (e) {
      return value;
    }
  }
}
