import { Injectable } from '@angular/core';
import { DocumentScanner, ResponseType } from 'capacitor-document-scanner';
import { PermissionsService } from 'src/app/services/permissions.service';

@Injectable({
  providedIn: 'root',
})
export class ScannerService {
  constructor(
    private permissionsService: PermissionsService,
  ) {
  }

  /**
   * Start scan after checking and asking for permissions.
   */
  public async startScan(): Promise<string[]> {
    const hasPermissions = await this.permissionsService.checkCameraPermissions('camera');
    if (hasPermissions) {
      return this.doScan();
    } else {
      return [];
    }
  }

  /**
   * Actual scan, returns an array of URIs pointing to the scanned images.
   *
   * @private
   */
  private async doScan(): Promise<string[]> {
    const { scannedImages } = await DocumentScanner.scanDocument({ responseType: ResponseType.Base64 });
    if (scannedImages.length > 0) {
      const imageUrls = [];
      for (const scannedImage of scannedImages) {
        imageUrls.push('data:image/jpeg;base64,' + scannedImage);
      }
      return imageUrls;
    }
  }
}
