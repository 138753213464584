import { Component, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { IonInfiniteScroll, ModalController } from '@ionic/angular';
import { Store } from '@ngxs/store';
import { AxMegasearchResultDto, AxProject, AxSearchV2Service } from 'src/app/_generated/axova-rest-api';
import { AppState } from 'src/app/ngxs/app/app.state';

@Component({
  selector: 'app-project-selector',
  templateUrl: './project-selector-modal.page.html',
  styleUrls: ['./project-selector-modal.page.scss'],
})
export class ProjectSelectorModalPage {
  @ViewChild(IonInfiniteScroll) infiniteScroll: IonInfiniteScroll;
  public megasearchResultDto: AxMegasearchResultDto | undefined;
  public searchWord: string;
  public isLoading = false;
  public roles$ = this.store.select(AppState.roles);

  constructor(
    private store: Store,
    private axSearchV2Service: AxSearchV2Service,
    private title: Title,
    private modalController: ModalController,
  ) {
  }

  ionViewDidEnter() {
    this.title.setTitle('Projekt auswählen');
  }

  /**
   * Search either users or contacts by keyword. Reset to original values if search word
   * was deleted. Otherwise search locally for users or via API for contacts.
   *
   * @param searchWord
   */
  public async search(searchWord: any) {
    this.isLoading = true;
    try {
      this.searchWord = searchWord.detail.value;
    } catch (e) {
      this.searchWord = searchWord;
    }
    if (!this.searchWord || this.searchWord === '') {
      this.infiniteScroll.disabled = false;
      return;
    }
    if (this.searchWord.length < 3) {
      return;
    }
    this.megasearchResultDto = await this.axSearchV2Service.searchControllerMegasearchBySearchterm({ searchterm: this.searchWord }).toPromise();
    this.isLoading = false;
  }

  public async selectProject(project: AxProject) {
    await this.modalController.dismiss({ project });
  }
}
