/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { AxInsertMontageplanBlockDto } from '../models/ax-insert-montageplan-block-dto';
import { AxMontageplanBlockDateDto } from '../models/ax-montageplan-block-date-dto';
import { AxMontageplanBlockOrientationTypeEnum } from '../models/ax-montageplan-block-orientation-type-enum';
import { AxMontageplanDayDto } from '../models/ax-montageplan-day-dto';
import { AxMontageplanLockStatusDtoEvent } from '../models/ax-montageplan-lock-status-dto-event';
import { AxMontageplanLog } from '../models/ax-montageplan-log';
import { AxMontageplanSyncStatusDtoEvent } from '../models/ax-montageplan-sync-status-dto-event';
import { AxMontageplanUnplannedBlockDto } from '../models/ax-montageplan-unplanned-block-dto';
import { AxMontageplanUserAvailabilityDto } from '../models/ax-montageplan-user-availability-dto';
import { AxMontageplanUsersDto } from '../models/ax-montageplan-users-dto';
import { AxMoveMontageplanBlockDto } from '../models/ax-move-montageplan-block-dto';
import { AxPaginatedDocumented } from '../models/ax-paginated-documented';
import { AxSuccessDto } from '../models/ax-success-dto';
import { AxUpdateFixedDateDto } from '../models/ax-update-fixed-date-dto';
import { AxUpdateMontageplanBlockDatesDto } from '../models/ax-update-montageplan-block-dates-dto';

@Injectable({ providedIn: 'root' })
export class AxMontageplanV2Service extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `montageplanControllerGetMontageplanBlocksByTeamIdAndDaterange()` */
  static readonly MontageplanControllerGetMontageplanBlocksByTeamIdAndDaterangePath = '/v2/projects/montageplan/blocks/byTeamsAndDaterange/{fromDate}/{toDate}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `montageplanControllerGetMontageplanBlocksByTeamIdAndDaterange()` instead.
   *
   * This method doesn't expect any request body.
   */
  montageplanControllerGetMontageplanBlocksByTeamIdAndDaterange$Response(
    params: {
      fromDate: string;
      toDate: string;
      teamIds: Array<string>;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<AxMontageplanDayDto>>> {
    const rb = new RequestBuilder(this.rootUrl, AxMontageplanV2Service.MontageplanControllerGetMontageplanBlocksByTeamIdAndDaterangePath, 'get');
    if (params) {
      rb.path('fromDate', params.fromDate, {});
      rb.path('toDate', params.toDate, {});
      rb.query('teamIds', params.teamIds, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AxMontageplanDayDto>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `montageplanControllerGetMontageplanBlocksByTeamIdAndDaterange$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  montageplanControllerGetMontageplanBlocksByTeamIdAndDaterange(
    params: {
      fromDate: string;
      toDate: string;
      teamIds: Array<string>;
    },
    context?: HttpContext
  ): Observable<Array<AxMontageplanDayDto>> {
    return this.montageplanControllerGetMontageplanBlocksByTeamIdAndDaterange$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<AxMontageplanDayDto>>): Array<AxMontageplanDayDto> => r.body)
    );
  }

  /** Path part for operation `montageplanControllerGetMontageplanBlocksByUserIdAndDaterange()` */
  static readonly MontageplanControllerGetMontageplanBlocksByUserIdAndDaterangePath = '/v2/projects/montageplan/blocks/byUserAndDaterange/{userId}/{fromDate}/{toDate}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `montageplanControllerGetMontageplanBlocksByUserIdAndDaterange()` instead.
   *
   * This method doesn't expect any request body.
   */
  montageplanControllerGetMontageplanBlocksByUserIdAndDaterange$Response(
    params: {
      userId: number;
      fromDate: string;
      toDate: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<AxMontageplanDayDto>>> {
    const rb = new RequestBuilder(this.rootUrl, AxMontageplanV2Service.MontageplanControllerGetMontageplanBlocksByUserIdAndDaterangePath, 'get');
    if (params) {
      rb.path('userId', params.userId, {});
      rb.path('fromDate', params.fromDate, {});
      rb.path('toDate', params.toDate, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AxMontageplanDayDto>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `montageplanControllerGetMontageplanBlocksByUserIdAndDaterange$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  montageplanControllerGetMontageplanBlocksByUserIdAndDaterange(
    params: {
      userId: number;
      fromDate: string;
      toDate: string;
    },
    context?: HttpContext
  ): Observable<Array<AxMontageplanDayDto>> {
    return this.montageplanControllerGetMontageplanBlocksByUserIdAndDaterange$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<AxMontageplanDayDto>>): Array<AxMontageplanDayDto> => r.body)
    );
  }

  /** Path part for operation `montageplanControllerGetMontageplanUnplannedBlocksProjects()` */
  static readonly MontageplanControllerGetMontageplanUnplannedBlocksProjectsPath = '/v2/projects/montageplan/unplannedBlocksProjects';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `montageplanControllerGetMontageplanUnplannedBlocksProjects()` instead.
   *
   * This method doesn't expect any request body.
   */
  montageplanControllerGetMontageplanUnplannedBlocksProjects$Response(
    params?: {
      nothing?: AxMontageplanBlockOrientationTypeEnum;

    /**
     * Page number to retrieve.If you provide invalid value the default page number will applied
     *         <p>
     *              <b>Example: </b> 1
     *           </p>
     *         <p>
     *              <b>Default Value: </b> 1
     *           </p>
     */
      page?: number;

    /**
     * Number of records per page.
     *       <p>
     *              <b>Example: </b> 20
     *           </p>
     *       <p>
     *              <b>Default Value: </b> 25
     *           </p>
     *       <p>
     *              <b>Max Value: </b> 100
     *           </p>
     *
     *       If provided value is greater than max value, max value will be applied.
     */
      limit?: number;

    /**
     * Filter by container.contact.businessunitId query param.
     *           <p>
     *              <b>Format: </b> filter.container.contact.businessunitId={$not}:OPERATION:VALUE
     *           </p>
     *           <p>
     *              <b>Example: </b> filter.container.contact.businessunitId=$not:$like:John Doe&filter.container.contact.businessunitId=like:John
     *           </p>
     *           <h4>Available Operations</h4><ul><li>$eq</li></ul>
     */
      'filter.container.contact.businessunitId'?: Array<string>;

    /**
     * Filter by container.containertypeId query param.
     *           <p>
     *              <b>Format: </b> filter.container.containertypeId={$not}:OPERATION:VALUE
     *           </p>
     *           <p>
     *              <b>Example: </b> filter.container.containertypeId=$not:$like:John Doe&filter.container.containertypeId=like:John
     *           </p>
     *           <h4>Available Operations</h4><ul><li>$eq</li></ul>
     */
      'filter.container.containertypeId'?: Array<string>;

    /**
     * Filter by statusId query param.
     *           <p>
     *              <b>Format: </b> filter.statusId={$not}:OPERATION:VALUE
     *           </p>
     *           <p>
     *              <b>Example: </b> filter.statusId=$not:$like:John Doe&filter.statusId=like:John
     *           </p>
     *           <h4>Available Operations</h4><ul><li>$eq</li></ul>
     */
      'filter.statusId'?: Array<string>;

    /**
     * Filter by sale query param.
     *           <p>
     *              <b>Format: </b> filter.sale={$not}:OPERATION:VALUE
     *           </p>
     *           <p>
     *              <b>Example: </b> filter.sale=$not:$like:John Doe&filter.sale=like:John
     *           </p>
     *           <h4>Available Operations</h4><ul><li>$null</li>
     * <li>$not</li></ul>
     */
      'filter.sale'?: Array<string>;

    /**
     * Filter by installationStart query param.
     *           <p>
     *              <b>Format: </b> filter.installationStart={$not}:OPERATION:VALUE
     *           </p>
     *           <p>
     *              <b>Example: </b> filter.installationStart=$not:$like:John Doe&filter.installationStart=like:John
     *           </p>
     *           <h4>Available Operations</h4><ul><li>$null</li>
     * <li>$not</li>
     * <li>$gte</li></ul>
     */
      'filter.installationStart'?: Array<string>;

    /**
     * Filter by teamId query param.
     *           <p>
     *              <b>Format: </b> filter.teamId={$not}:OPERATION:VALUE
     *           </p>
     *           <p>
     *              <b>Example: </b> filter.teamId=$not:$like:John Doe&filter.teamId=like:John
     *           </p>
     *           <h4>Available Operations</h4><ul><li>$null</li>
     * <li>$not</li></ul>
     */
      'filter.teamId'?: Array<string>;

    /**
     * Parameter to sort by.
     *       <p>To sort by multiple fields, just provide query param multiple types. The order in url defines an order of sorting</p>
     *       <p>
     *              <b>Format: </b> fieldName:DIRECTION
     *           </p>
     *       <p>
     *              <b>Example: </b> sortBy=id:DESC&sortBy=createdAt:ASC
     *           </p>
     *       <p>
     *              <b>Default Value: </b> sale:ASC
     *           </p>
     *       <h4>Available Fields</h4><ul><li>id</li></ul>
     */
      sortBy?: Array<string>;

    /**
     * Search term to filter result values
     *         <p>
     *              <b>Example: </b> John
     *           </p>
     *         <p>
     *              <b>Default Value: </b> No default value
     *           </p>
     */
      search?: string;

    /**
     * List of fields to search by term to filter result values
     *         <p>
     *              <b>Example: </b> numberQualified,container.contact.firstName,container.contact.lastName
     *           </p>
     *         <p>
     *              <b>Default Value: </b> By default all fields mentioned below will be used to search by term
     *           </p>
     *         <h4>Available Fields</h4><ul><li>numberQualified</li>
     * <li>container.contact.firstName</li>
     * <li>container.contact.lastName</li></ul>
     */
      searchBy?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AxPaginatedDocumented & {
'data'?: Array<AxMontageplanUnplannedBlockDto>;
'meta'?: {
'select'?: Array<string>;
'filter'?: {
'container.contact.businessunitId'?: (string | Array<string>);
'container.containertypeId'?: (string | Array<string>);
'statusId'?: (string | Array<string>);
'sale'?: (string | Array<string>);
'installationStart'?: (string | Array<string>);
'teamId'?: (string | Array<string>);
};
};
}>> {
    const rb = new RequestBuilder(this.rootUrl, AxMontageplanV2Service.MontageplanControllerGetMontageplanUnplannedBlocksProjectsPath, 'get');
    if (params) {
      rb.query('nothing', params.nothing, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.query('filter.container.contact.businessunitId', params['filter.container.contact.businessunitId'], {});
      rb.query('filter.container.containertypeId', params['filter.container.containertypeId'], {});
      rb.query('filter.statusId', params['filter.statusId'], {});
      rb.query('filter.sale', params['filter.sale'], {});
      rb.query('filter.installationStart', params['filter.installationStart'], {});
      rb.query('filter.teamId', params['filter.teamId'], {});
      rb.query('sortBy', params.sortBy, {});
      rb.query('search', params.search, {});
      rb.query('searchBy', params.searchBy, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AxPaginatedDocumented & {
        'data'?: Array<AxMontageplanUnplannedBlockDto>;
        'meta'?: {
        'select'?: Array<string>;
        'filter'?: {
        'container.contact.businessunitId'?: (string | Array<string>);
        'container.containertypeId'?: (string | Array<string>);
        'statusId'?: (string | Array<string>);
        'sale'?: (string | Array<string>);
        'installationStart'?: (string | Array<string>);
        'teamId'?: (string | Array<string>);
        };
        };
        }>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `montageplanControllerGetMontageplanUnplannedBlocksProjects$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  montageplanControllerGetMontageplanUnplannedBlocksProjects(
    params?: {
      nothing?: AxMontageplanBlockOrientationTypeEnum;

    /**
     * Page number to retrieve.If you provide invalid value the default page number will applied
     *         <p>
     *              <b>Example: </b> 1
     *           </p>
     *         <p>
     *              <b>Default Value: </b> 1
     *           </p>
     */
      page?: number;

    /**
     * Number of records per page.
     *       <p>
     *              <b>Example: </b> 20
     *           </p>
     *       <p>
     *              <b>Default Value: </b> 25
     *           </p>
     *       <p>
     *              <b>Max Value: </b> 100
     *           </p>
     *
     *       If provided value is greater than max value, max value will be applied.
     */
      limit?: number;

    /**
     * Filter by container.contact.businessunitId query param.
     *           <p>
     *              <b>Format: </b> filter.container.contact.businessunitId={$not}:OPERATION:VALUE
     *           </p>
     *           <p>
     *              <b>Example: </b> filter.container.contact.businessunitId=$not:$like:John Doe&filter.container.contact.businessunitId=like:John
     *           </p>
     *           <h4>Available Operations</h4><ul><li>$eq</li></ul>
     */
      'filter.container.contact.businessunitId'?: Array<string>;

    /**
     * Filter by container.containertypeId query param.
     *           <p>
     *              <b>Format: </b> filter.container.containertypeId={$not}:OPERATION:VALUE
     *           </p>
     *           <p>
     *              <b>Example: </b> filter.container.containertypeId=$not:$like:John Doe&filter.container.containertypeId=like:John
     *           </p>
     *           <h4>Available Operations</h4><ul><li>$eq</li></ul>
     */
      'filter.container.containertypeId'?: Array<string>;

    /**
     * Filter by statusId query param.
     *           <p>
     *              <b>Format: </b> filter.statusId={$not}:OPERATION:VALUE
     *           </p>
     *           <p>
     *              <b>Example: </b> filter.statusId=$not:$like:John Doe&filter.statusId=like:John
     *           </p>
     *           <h4>Available Operations</h4><ul><li>$eq</li></ul>
     */
      'filter.statusId'?: Array<string>;

    /**
     * Filter by sale query param.
     *           <p>
     *              <b>Format: </b> filter.sale={$not}:OPERATION:VALUE
     *           </p>
     *           <p>
     *              <b>Example: </b> filter.sale=$not:$like:John Doe&filter.sale=like:John
     *           </p>
     *           <h4>Available Operations</h4><ul><li>$null</li>
     * <li>$not</li></ul>
     */
      'filter.sale'?: Array<string>;

    /**
     * Filter by installationStart query param.
     *           <p>
     *              <b>Format: </b> filter.installationStart={$not}:OPERATION:VALUE
     *           </p>
     *           <p>
     *              <b>Example: </b> filter.installationStart=$not:$like:John Doe&filter.installationStart=like:John
     *           </p>
     *           <h4>Available Operations</h4><ul><li>$null</li>
     * <li>$not</li>
     * <li>$gte</li></ul>
     */
      'filter.installationStart'?: Array<string>;

    /**
     * Filter by teamId query param.
     *           <p>
     *              <b>Format: </b> filter.teamId={$not}:OPERATION:VALUE
     *           </p>
     *           <p>
     *              <b>Example: </b> filter.teamId=$not:$like:John Doe&filter.teamId=like:John
     *           </p>
     *           <h4>Available Operations</h4><ul><li>$null</li>
     * <li>$not</li></ul>
     */
      'filter.teamId'?: Array<string>;

    /**
     * Parameter to sort by.
     *       <p>To sort by multiple fields, just provide query param multiple types. The order in url defines an order of sorting</p>
     *       <p>
     *              <b>Format: </b> fieldName:DIRECTION
     *           </p>
     *       <p>
     *              <b>Example: </b> sortBy=id:DESC&sortBy=createdAt:ASC
     *           </p>
     *       <p>
     *              <b>Default Value: </b> sale:ASC
     *           </p>
     *       <h4>Available Fields</h4><ul><li>id</li></ul>
     */
      sortBy?: Array<string>;

    /**
     * Search term to filter result values
     *         <p>
     *              <b>Example: </b> John
     *           </p>
     *         <p>
     *              <b>Default Value: </b> No default value
     *           </p>
     */
      search?: string;

    /**
     * List of fields to search by term to filter result values
     *         <p>
     *              <b>Example: </b> numberQualified,container.contact.firstName,container.contact.lastName
     *           </p>
     *         <p>
     *              <b>Default Value: </b> By default all fields mentioned below will be used to search by term
     *           </p>
     *         <h4>Available Fields</h4><ul><li>numberQualified</li>
     * <li>container.contact.firstName</li>
     * <li>container.contact.lastName</li></ul>
     */
      searchBy?: string;
    },
    context?: HttpContext
  ): Observable<AxPaginatedDocumented & {
'data'?: Array<AxMontageplanUnplannedBlockDto>;
'meta'?: {
'select'?: Array<string>;
'filter'?: {
'container.contact.businessunitId'?: (string | Array<string>);
'container.containertypeId'?: (string | Array<string>);
'statusId'?: (string | Array<string>);
'sale'?: (string | Array<string>);
'installationStart'?: (string | Array<string>);
'teamId'?: (string | Array<string>);
};
};
}> {
    return this.montageplanControllerGetMontageplanUnplannedBlocksProjects$Response(params, context).pipe(
      map((r: StrictHttpResponse<AxPaginatedDocumented & {
'data'?: Array<AxMontageplanUnplannedBlockDto>;
'meta'?: {
'select'?: Array<string>;
'filter'?: {
'container.contact.businessunitId'?: (string | Array<string>);
'container.containertypeId'?: (string | Array<string>);
'statusId'?: (string | Array<string>);
'sale'?: (string | Array<string>);
'installationStart'?: (string | Array<string>);
'teamId'?: (string | Array<string>);
};
};
}>): AxPaginatedDocumented & {
'data'?: Array<AxMontageplanUnplannedBlockDto>;
'meta'?: {
'select'?: Array<string>;
'filter'?: {
'container.contact.businessunitId'?: (string | Array<string>);
'container.containertypeId'?: (string | Array<string>);
'statusId'?: (string | Array<string>);
'sale'?: (string | Array<string>);
'installationStart'?: (string | Array<string>);
'teamId'?: (string | Array<string>);
};
};
} => r.body)
    );
  }

  /** Path part for operation `montageplanControllerGetMontageplanUnplannedBlocksTasks()` */
  static readonly MontageplanControllerGetMontageplanUnplannedBlocksTasksPath = '/v2/projects/montageplan/unplannedBlocksTasks';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `montageplanControllerGetMontageplanUnplannedBlocksTasks()` instead.
   *
   * This method doesn't expect any request body.
   */
  montageplanControllerGetMontageplanUnplannedBlocksTasks$Response(
    params?: {
      nothing?: AxMontageplanBlockOrientationTypeEnum;

    /**
     * Page number to retrieve.If you provide invalid value the default page number will applied
     *         <p>
     *              <b>Example: </b> 1
     *           </p>
     *         <p>
     *              <b>Default Value: </b> 1
     *           </p>
     */
      page?: number;

    /**
     * Number of records per page.
     *       <p>
     *              <b>Example: </b> 20
     *           </p>
     *       <p>
     *              <b>Default Value: </b> 25
     *           </p>
     *       <p>
     *              <b>Max Value: </b> 100
     *           </p>
     *
     *       If provided value is greater than max value, max value will be applied.
     */
      limit?: number;

    /**
     * Filter by project.container.contact.businessunitId query param.
     *           <p>
     *              <b>Format: </b> filter.project.container.contact.businessunitId={$not}:OPERATION:VALUE
     *           </p>
     *           <p>
     *              <b>Example: </b> filter.project.container.contact.businessunitId=$not:$like:John Doe&filter.project.container.contact.businessunitId=like:John
     *           </p>
     *           <h4>Available Operations</h4><ul><li>$eq</li></ul>
     */
      'filter.project.container.contact.businessunitId'?: Array<string>;

    /**
     * Filter by project.container.containertypeId query param.
     *           <p>
     *              <b>Format: </b> filter.project.container.containertypeId={$not}:OPERATION:VALUE
     *           </p>
     *           <p>
     *              <b>Example: </b> filter.project.container.containertypeId=$not:$like:John Doe&filter.project.container.containertypeId=like:John
     *           </p>
     *           <h4>Available Operations</h4><ul><li>$eq</li></ul>
     */
      'filter.project.container.containertypeId'?: Array<string>;

    /**
     * Filter by teamId query param.
     *           <p>
     *              <b>Format: </b> filter.teamId={$not}:OPERATION:VALUE
     *           </p>
     *           <p>
     *              <b>Example: </b> filter.teamId=$not:$like:John Doe&filter.teamId=like:John
     *           </p>
     *           <h4>Available Operations</h4><ul><li>$null</li></ul>
     */
      'filter.teamId'?: Array<string>;

    /**
     * Filter by start query param.
     *           <p>
     *              <b>Format: </b> filter.start={$not}:OPERATION:VALUE
     *           </p>
     *           <p>
     *              <b>Example: </b> filter.start=$not:$like:John Doe&filter.start=like:John
     *           </p>
     *           <h4>Available Operations</h4><ul><li>$null</li>
     * <li>$gte</li></ul>
     */
      'filter.start'?: Array<string>;

    /**
     * Filter by tasktypeId query param.
     *           <p>
     *              <b>Format: </b> filter.tasktypeId={$not}:OPERATION:VALUE
     *           </p>
     *           <p>
     *              <b>Example: </b> filter.tasktypeId=$not:$like:John Doe&filter.tasktypeId=like:John
     *           </p>
     *           <h4>Available Operations</h4><ul><li>$eq</li></ul>
     */
      'filter.tasktypeId'?: Array<string>;

    /**
     * Filter by standardtask.showInMontageplan query param.
     *           <p>
     *              <b>Format: </b> filter.standardtask.showInMontageplan={$not}:OPERATION:VALUE
     *           </p>
     *           <p>
     *              <b>Example: </b> filter.standardtask.showInMontageplan=$not:$like:John Doe&filter.standardtask.showInMontageplan=like:John
     *           </p>
     *           <h4>Available Operations</h4><ul><li>$eq</li></ul>
     */
      'filter.standardtask.showInMontageplan'?: Array<string>;

    /**
     * Filter by standardtaskId query param.
     *           <p>
     *              <b>Format: </b> filter.standardtaskId={$not}:OPERATION:VALUE
     *           </p>
     *           <p>
     *              <b>Example: </b> filter.standardtaskId=$not:$like:John Doe&filter.standardtaskId=like:John
     *           </p>
     *           <h4>Available Operations</h4><ul><li>$in</li></ul>
     */
      'filter.standardtaskId'?: Array<string>;

    /**
     * Filter by mandatory query param.
     *           <p>
     *              <b>Format: </b> filter.mandatory={$not}:OPERATION:VALUE
     *           </p>
     *           <p>
     *              <b>Example: </b> filter.mandatory=$not:$like:John Doe&filter.mandatory=like:John
     *           </p>
     *           <h4>Available Operations</h4><ul><li>$eq</li></ul>
     */
      'filter.mandatory'?: Array<string>;

    /**
     * Filter by project.sale query param.
     *           <p>
     *              <b>Format: </b> filter.project.sale={$not}:OPERATION:VALUE
     *           </p>
     *           <p>
     *              <b>Example: </b> filter.project.sale=$not:$like:John Doe&filter.project.sale=like:John
     *           </p>
     *           <h4>Available Operations</h4><ul><li>$null</li>
     * <li>$not</li></ul>
     */
      'filter.project.sale'?: Array<string>;

    /**
     * Filter by active query param.
     *           <p>
     *              <b>Format: </b> filter.active={$not}:OPERATION:VALUE
     *           </p>
     *           <p>
     *              <b>Example: </b> filter.active=$not:$like:John Doe&filter.active=like:John
     *           </p>
     *           <h4>Available Operations</h4><ul><li>$eq</li></ul>
     */
      'filter.active'?: Array<string>;

    /**
     * Parameter to sort by.
     *       <p>To sort by multiple fields, just provide query param multiple types. The order in url defines an order of sorting</p>
     *       <p>
     *              <b>Format: </b> fieldName:DIRECTION
     *           </p>
     *       <p>
     *              <b>Example: </b> sortBy=id:DESC&sortBy=createdAt:ASC
     *           </p>
     *       <p>
     *              <b>Default Value: </b> project.installationStart:ASC
     *           </p>
     *       <h4>Available Fields</h4><ul><li>id</li></ul>
     */
      sortBy?: Array<string>;

    /**
     * Search term to filter result values
     *         <p>
     *              <b>Example: </b> John
     *           </p>
     *         <p>
     *              <b>Default Value: </b> No default value
     *           </p>
     */
      search?: string;

    /**
     * List of fields to search by term to filter result values
     *         <p>
     *              <b>Example: </b> name,project.numberQualified
     *           </p>
     *         <p>
     *              <b>Default Value: </b> By default all fields mentioned below will be used to search by term
     *           </p>
     *         <h4>Available Fields</h4><ul><li>name</li>
     * <li>project.numberQualified</li></ul>
     */
      searchBy?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AxPaginatedDocumented & {
'data'?: Array<AxMontageplanUnplannedBlockDto>;
'meta'?: {
'select'?: Array<string>;
'filter'?: {
'project.container.contact.businessunitId'?: (string | Array<string>);
'project.container.containertypeId'?: (string | Array<string>);
'teamId'?: (string | Array<string>);
'start'?: (string | Array<string>);
'tasktypeId'?: (string | Array<string>);
'standardtask.showInMontageplan'?: (string | Array<string>);
'standardtaskId'?: (string | Array<string>);
'mandatory'?: (string | Array<string>);
'project.sale'?: (string | Array<string>);
'active'?: (string | Array<string>);
};
};
}>> {
    const rb = new RequestBuilder(this.rootUrl, AxMontageplanV2Service.MontageplanControllerGetMontageplanUnplannedBlocksTasksPath, 'get');
    if (params) {
      rb.query('nothing', params.nothing, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.query('filter.project.container.contact.businessunitId', params['filter.project.container.contact.businessunitId'], {});
      rb.query('filter.project.container.containertypeId', params['filter.project.container.containertypeId'], {});
      rb.query('filter.teamId', params['filter.teamId'], {});
      rb.query('filter.start', params['filter.start'], {});
      rb.query('filter.tasktypeId', params['filter.tasktypeId'], {});
      rb.query('filter.standardtask.showInMontageplan', params['filter.standardtask.showInMontageplan'], {});
      rb.query('filter.standardtaskId', params['filter.standardtaskId'], {});
      rb.query('filter.mandatory', params['filter.mandatory'], {});
      rb.query('filter.project.sale', params['filter.project.sale'], {});
      rb.query('filter.active', params['filter.active'], {});
      rb.query('sortBy', params.sortBy, {});
      rb.query('search', params.search, {});
      rb.query('searchBy', params.searchBy, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AxPaginatedDocumented & {
        'data'?: Array<AxMontageplanUnplannedBlockDto>;
        'meta'?: {
        'select'?: Array<string>;
        'filter'?: {
        'project.container.contact.businessunitId'?: (string | Array<string>);
        'project.container.containertypeId'?: (string | Array<string>);
        'teamId'?: (string | Array<string>);
        'start'?: (string | Array<string>);
        'tasktypeId'?: (string | Array<string>);
        'standardtask.showInMontageplan'?: (string | Array<string>);
        'standardtaskId'?: (string | Array<string>);
        'mandatory'?: (string | Array<string>);
        'project.sale'?: (string | Array<string>);
        'active'?: (string | Array<string>);
        };
        };
        }>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `montageplanControllerGetMontageplanUnplannedBlocksTasks$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  montageplanControllerGetMontageplanUnplannedBlocksTasks(
    params?: {
      nothing?: AxMontageplanBlockOrientationTypeEnum;

    /**
     * Page number to retrieve.If you provide invalid value the default page number will applied
     *         <p>
     *              <b>Example: </b> 1
     *           </p>
     *         <p>
     *              <b>Default Value: </b> 1
     *           </p>
     */
      page?: number;

    /**
     * Number of records per page.
     *       <p>
     *              <b>Example: </b> 20
     *           </p>
     *       <p>
     *              <b>Default Value: </b> 25
     *           </p>
     *       <p>
     *              <b>Max Value: </b> 100
     *           </p>
     *
     *       If provided value is greater than max value, max value will be applied.
     */
      limit?: number;

    /**
     * Filter by project.container.contact.businessunitId query param.
     *           <p>
     *              <b>Format: </b> filter.project.container.contact.businessunitId={$not}:OPERATION:VALUE
     *           </p>
     *           <p>
     *              <b>Example: </b> filter.project.container.contact.businessunitId=$not:$like:John Doe&filter.project.container.contact.businessunitId=like:John
     *           </p>
     *           <h4>Available Operations</h4><ul><li>$eq</li></ul>
     */
      'filter.project.container.contact.businessunitId'?: Array<string>;

    /**
     * Filter by project.container.containertypeId query param.
     *           <p>
     *              <b>Format: </b> filter.project.container.containertypeId={$not}:OPERATION:VALUE
     *           </p>
     *           <p>
     *              <b>Example: </b> filter.project.container.containertypeId=$not:$like:John Doe&filter.project.container.containertypeId=like:John
     *           </p>
     *           <h4>Available Operations</h4><ul><li>$eq</li></ul>
     */
      'filter.project.container.containertypeId'?: Array<string>;

    /**
     * Filter by teamId query param.
     *           <p>
     *              <b>Format: </b> filter.teamId={$not}:OPERATION:VALUE
     *           </p>
     *           <p>
     *              <b>Example: </b> filter.teamId=$not:$like:John Doe&filter.teamId=like:John
     *           </p>
     *           <h4>Available Operations</h4><ul><li>$null</li></ul>
     */
      'filter.teamId'?: Array<string>;

    /**
     * Filter by start query param.
     *           <p>
     *              <b>Format: </b> filter.start={$not}:OPERATION:VALUE
     *           </p>
     *           <p>
     *              <b>Example: </b> filter.start=$not:$like:John Doe&filter.start=like:John
     *           </p>
     *           <h4>Available Operations</h4><ul><li>$null</li>
     * <li>$gte</li></ul>
     */
      'filter.start'?: Array<string>;

    /**
     * Filter by tasktypeId query param.
     *           <p>
     *              <b>Format: </b> filter.tasktypeId={$not}:OPERATION:VALUE
     *           </p>
     *           <p>
     *              <b>Example: </b> filter.tasktypeId=$not:$like:John Doe&filter.tasktypeId=like:John
     *           </p>
     *           <h4>Available Operations</h4><ul><li>$eq</li></ul>
     */
      'filter.tasktypeId'?: Array<string>;

    /**
     * Filter by standardtask.showInMontageplan query param.
     *           <p>
     *              <b>Format: </b> filter.standardtask.showInMontageplan={$not}:OPERATION:VALUE
     *           </p>
     *           <p>
     *              <b>Example: </b> filter.standardtask.showInMontageplan=$not:$like:John Doe&filter.standardtask.showInMontageplan=like:John
     *           </p>
     *           <h4>Available Operations</h4><ul><li>$eq</li></ul>
     */
      'filter.standardtask.showInMontageplan'?: Array<string>;

    /**
     * Filter by standardtaskId query param.
     *           <p>
     *              <b>Format: </b> filter.standardtaskId={$not}:OPERATION:VALUE
     *           </p>
     *           <p>
     *              <b>Example: </b> filter.standardtaskId=$not:$like:John Doe&filter.standardtaskId=like:John
     *           </p>
     *           <h4>Available Operations</h4><ul><li>$in</li></ul>
     */
      'filter.standardtaskId'?: Array<string>;

    /**
     * Filter by mandatory query param.
     *           <p>
     *              <b>Format: </b> filter.mandatory={$not}:OPERATION:VALUE
     *           </p>
     *           <p>
     *              <b>Example: </b> filter.mandatory=$not:$like:John Doe&filter.mandatory=like:John
     *           </p>
     *           <h4>Available Operations</h4><ul><li>$eq</li></ul>
     */
      'filter.mandatory'?: Array<string>;

    /**
     * Filter by project.sale query param.
     *           <p>
     *              <b>Format: </b> filter.project.sale={$not}:OPERATION:VALUE
     *           </p>
     *           <p>
     *              <b>Example: </b> filter.project.sale=$not:$like:John Doe&filter.project.sale=like:John
     *           </p>
     *           <h4>Available Operations</h4><ul><li>$null</li>
     * <li>$not</li></ul>
     */
      'filter.project.sale'?: Array<string>;

    /**
     * Filter by active query param.
     *           <p>
     *              <b>Format: </b> filter.active={$not}:OPERATION:VALUE
     *           </p>
     *           <p>
     *              <b>Example: </b> filter.active=$not:$like:John Doe&filter.active=like:John
     *           </p>
     *           <h4>Available Operations</h4><ul><li>$eq</li></ul>
     */
      'filter.active'?: Array<string>;

    /**
     * Parameter to sort by.
     *       <p>To sort by multiple fields, just provide query param multiple types. The order in url defines an order of sorting</p>
     *       <p>
     *              <b>Format: </b> fieldName:DIRECTION
     *           </p>
     *       <p>
     *              <b>Example: </b> sortBy=id:DESC&sortBy=createdAt:ASC
     *           </p>
     *       <p>
     *              <b>Default Value: </b> project.installationStart:ASC
     *           </p>
     *       <h4>Available Fields</h4><ul><li>id</li></ul>
     */
      sortBy?: Array<string>;

    /**
     * Search term to filter result values
     *         <p>
     *              <b>Example: </b> John
     *           </p>
     *         <p>
     *              <b>Default Value: </b> No default value
     *           </p>
     */
      search?: string;

    /**
     * List of fields to search by term to filter result values
     *         <p>
     *              <b>Example: </b> name,project.numberQualified
     *           </p>
     *         <p>
     *              <b>Default Value: </b> By default all fields mentioned below will be used to search by term
     *           </p>
     *         <h4>Available Fields</h4><ul><li>name</li>
     * <li>project.numberQualified</li></ul>
     */
      searchBy?: string;
    },
    context?: HttpContext
  ): Observable<AxPaginatedDocumented & {
'data'?: Array<AxMontageplanUnplannedBlockDto>;
'meta'?: {
'select'?: Array<string>;
'filter'?: {
'project.container.contact.businessunitId'?: (string | Array<string>);
'project.container.containertypeId'?: (string | Array<string>);
'teamId'?: (string | Array<string>);
'start'?: (string | Array<string>);
'tasktypeId'?: (string | Array<string>);
'standardtask.showInMontageplan'?: (string | Array<string>);
'standardtaskId'?: (string | Array<string>);
'mandatory'?: (string | Array<string>);
'project.sale'?: (string | Array<string>);
'active'?: (string | Array<string>);
};
};
}> {
    return this.montageplanControllerGetMontageplanUnplannedBlocksTasks$Response(params, context).pipe(
      map((r: StrictHttpResponse<AxPaginatedDocumented & {
'data'?: Array<AxMontageplanUnplannedBlockDto>;
'meta'?: {
'select'?: Array<string>;
'filter'?: {
'project.container.contact.businessunitId'?: (string | Array<string>);
'project.container.containertypeId'?: (string | Array<string>);
'teamId'?: (string | Array<string>);
'start'?: (string | Array<string>);
'tasktypeId'?: (string | Array<string>);
'standardtask.showInMontageplan'?: (string | Array<string>);
'standardtaskId'?: (string | Array<string>);
'mandatory'?: (string | Array<string>);
'project.sale'?: (string | Array<string>);
'active'?: (string | Array<string>);
};
};
}>): AxPaginatedDocumented & {
'data'?: Array<AxMontageplanUnplannedBlockDto>;
'meta'?: {
'select'?: Array<string>;
'filter'?: {
'project.container.contact.businessunitId'?: (string | Array<string>);
'project.container.containertypeId'?: (string | Array<string>);
'teamId'?: (string | Array<string>);
'start'?: (string | Array<string>);
'tasktypeId'?: (string | Array<string>);
'standardtask.showInMontageplan'?: (string | Array<string>);
'standardtaskId'?: (string | Array<string>);
'mandatory'?: (string | Array<string>);
'project.sale'?: (string | Array<string>);
'active'?: (string | Array<string>);
};
};
} => r.body)
    );
  }

  /** Path part for operation `montageplanControllerMoveBlock()` */
  static readonly MontageplanControllerMoveBlockPath = '/v2/projects/montageplan/blocks/move';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `montageplanControllerMoveBlock()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  montageplanControllerMoveBlock$Response(
    params: {
      body: AxMoveMontageplanBlockDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AxSuccessDto>> {
    const rb = new RequestBuilder(this.rootUrl, AxMontageplanV2Service.MontageplanControllerMoveBlockPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AxSuccessDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `montageplanControllerMoveBlock$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  montageplanControllerMoveBlock(
    params: {
      body: AxMoveMontageplanBlockDto
    },
    context?: HttpContext
  ): Observable<AxSuccessDto> {
    return this.montageplanControllerMoveBlock$Response(params, context).pipe(
      map((r: StrictHttpResponse<AxSuccessDto>): AxSuccessDto => r.body)
    );
  }

  /** Path part for operation `montageplanControllerMarkAsBuilt()` */
  static readonly MontageplanControllerMarkAsBuiltPath = '/v2/projects/montageplan/blocks/markAsBuilt/{projectId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `montageplanControllerMarkAsBuilt()` instead.
   *
   * This method doesn't expect any request body.
   */
  montageplanControllerMarkAsBuilt$Response(
    params: {
      projectId: number;
      updateInstallationEnd?: boolean;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AxSuccessDto>> {
    const rb = new RequestBuilder(this.rootUrl, AxMontageplanV2Service.MontageplanControllerMarkAsBuiltPath, 'post');
    if (params) {
      rb.path('projectId', params.projectId, {});
      rb.query('updateInstallationEnd', params.updateInstallationEnd, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AxSuccessDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `montageplanControllerMarkAsBuilt$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  montageplanControllerMarkAsBuilt(
    params: {
      projectId: number;
      updateInstallationEnd?: boolean;
    },
    context?: HttpContext
  ): Observable<AxSuccessDto> {
    return this.montageplanControllerMarkAsBuilt$Response(params, context).pipe(
      map((r: StrictHttpResponse<AxSuccessDto>): AxSuccessDto => r.body)
    );
  }

  /** Path part for operation `montageplanControllerInsertBlock()` */
  static readonly MontageplanControllerInsertBlockPath = '/v2/projects/montageplan/blocks/insert';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `montageplanControllerInsertBlock()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  montageplanControllerInsertBlock$Response(
    params: {
      body: AxInsertMontageplanBlockDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AxSuccessDto>> {
    const rb = new RequestBuilder(this.rootUrl, AxMontageplanV2Service.MontageplanControllerInsertBlockPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AxSuccessDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `montageplanControllerInsertBlock$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  montageplanControllerInsertBlock(
    params: {
      body: AxInsertMontageplanBlockDto
    },
    context?: HttpContext
  ): Observable<AxSuccessDto> {
    return this.montageplanControllerInsertBlock$Response(params, context).pipe(
      map((r: StrictHttpResponse<AxSuccessDto>): AxSuccessDto => r.body)
    );
  }

  /** Path part for operation `montageplanControllerUpdateFixedDate()` */
  static readonly MontageplanControllerUpdateFixedDatePath = '/v2/projects/montageplan/blocks/updateFixedDate';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `montageplanControllerUpdateFixedDate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  montageplanControllerUpdateFixedDate$Response(
    params: {
      body: AxUpdateFixedDateDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AxSuccessDto>> {
    const rb = new RequestBuilder(this.rootUrl, AxMontageplanV2Service.MontageplanControllerUpdateFixedDatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AxSuccessDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `montageplanControllerUpdateFixedDate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  montageplanControllerUpdateFixedDate(
    params: {
      body: AxUpdateFixedDateDto
    },
    context?: HttpContext
  ): Observable<AxSuccessDto> {
    return this.montageplanControllerUpdateFixedDate$Response(params, context).pipe(
      map((r: StrictHttpResponse<AxSuccessDto>): AxSuccessDto => r.body)
    );
  }

  /** Path part for operation `montageplanControllerGetLogsForBlock()` */
  static readonly MontageplanControllerGetLogsForBlockPath = '/v2/projects/montageplan/blocks/logs/{blockType}/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `montageplanControllerGetLogsForBlock()` instead.
   *
   * This method doesn't expect any request body.
   */
  montageplanControllerGetLogsForBlock$Response(
    params: {
      blockType: string;
      id: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<AxMontageplanLog>>> {
    const rb = new RequestBuilder(this.rootUrl, AxMontageplanV2Service.MontageplanControllerGetLogsForBlockPath, 'get');
    if (params) {
      rb.path('blockType', params.blockType, {});
      rb.path('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AxMontageplanLog>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `montageplanControllerGetLogsForBlock$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  montageplanControllerGetLogsForBlock(
    params: {
      blockType: string;
      id: number;
    },
    context?: HttpContext
  ): Observable<Array<AxMontageplanLog>> {
    return this.montageplanControllerGetLogsForBlock$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<AxMontageplanLog>>): Array<AxMontageplanLog> => r.body)
    );
  }

  /** Path part for operation `montageplanControllerGetNewEnddateForBlock()` */
  static readonly MontageplanControllerGetNewEnddateForBlockPath = '/v2/projects/montageplan/blocks/newEnddate/{blockType}/{id}/{newStartDate}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `montageplanControllerGetNewEnddateForBlock()` instead.
   *
   * This method doesn't expect any request body.
   */
  montageplanControllerGetNewEnddateForBlock$Response(
    params: {
      blockType: string;
      id: number;
      newStartDate: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AxMontageplanBlockDateDto>> {
    const rb = new RequestBuilder(this.rootUrl, AxMontageplanV2Service.MontageplanControllerGetNewEnddateForBlockPath, 'get');
    if (params) {
      rb.path('blockType', params.blockType, {});
      rb.path('id', params.id, {});
      rb.path('newStartDate', params.newStartDate, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AxMontageplanBlockDateDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `montageplanControllerGetNewEnddateForBlock$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  montageplanControllerGetNewEnddateForBlock(
    params: {
      blockType: string;
      id: number;
      newStartDate: string;
    },
    context?: HttpContext
  ): Observable<AxMontageplanBlockDateDto> {
    return this.montageplanControllerGetNewEnddateForBlock$Response(params, context).pipe(
      map((r: StrictHttpResponse<AxMontageplanBlockDateDto>): AxMontageplanBlockDateDto => r.body)
    );
  }

  /** Path part for operation `montageplanControllerUpdateMontageplanBlockDates()` */
  static readonly MontageplanControllerUpdateMontageplanBlockDatesPath = '/v2/projects/montageplan/blocks/updateDates';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `montageplanControllerUpdateMontageplanBlockDates()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  montageplanControllerUpdateMontageplanBlockDates$Response(
    params: {
      body: AxUpdateMontageplanBlockDatesDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AxSuccessDto>> {
    const rb = new RequestBuilder(this.rootUrl, AxMontageplanV2Service.MontageplanControllerUpdateMontageplanBlockDatesPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AxSuccessDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `montageplanControllerUpdateMontageplanBlockDates$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  montageplanControllerUpdateMontageplanBlockDates(
    params: {
      body: AxUpdateMontageplanBlockDatesDto
    },
    context?: HttpContext
  ): Observable<AxSuccessDto> {
    return this.montageplanControllerUpdateMontageplanBlockDates$Response(params, context).pipe(
      map((r: StrictHttpResponse<AxSuccessDto>): AxSuccessDto => r.body)
    );
  }

  /** Path part for operation `montageplanControllerGetMontageplanUsersByTeamIdAndDaterange()` */
  static readonly MontageplanControllerGetMontageplanUsersByTeamIdAndDaterangePath = '/v2/projects/montageplan/users/byTeamsAndDaterange/{fromDate}/{toDate}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `montageplanControllerGetMontageplanUsersByTeamIdAndDaterange()` instead.
   *
   * This method doesn't expect any request body.
   */
  montageplanControllerGetMontageplanUsersByTeamIdAndDaterange$Response(
    params: {
      fromDate: string;
      toDate: string;
      teamIds: Array<string>;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AxMontageplanUsersDto>> {
    const rb = new RequestBuilder(this.rootUrl, AxMontageplanV2Service.MontageplanControllerGetMontageplanUsersByTeamIdAndDaterangePath, 'get');
    if (params) {
      rb.path('fromDate', params.fromDate, {});
      rb.path('toDate', params.toDate, {});
      rb.query('teamIds', params.teamIds, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AxMontageplanUsersDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `montageplanControllerGetMontageplanUsersByTeamIdAndDaterange$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  montageplanControllerGetMontageplanUsersByTeamIdAndDaterange(
    params: {
      fromDate: string;
      toDate: string;
      teamIds: Array<string>;
    },
    context?: HttpContext
  ): Observable<AxMontageplanUsersDto> {
    return this.montageplanControllerGetMontageplanUsersByTeamIdAndDaterange$Response(params, context).pipe(
      map((r: StrictHttpResponse<AxMontageplanUsersDto>): AxMontageplanUsersDto => r.body)
    );
  }

  /** Path part for operation `montageplanControllerGetUserAvailabilityForWorkdate()` */
  static readonly MontageplanControllerGetUserAvailabilityForWorkdatePath = '/v2/projects/montageplan/users/availability/{fromDate}/{toDate}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `montageplanControllerGetUserAvailabilityForWorkdate()` instead.
   *
   * This method doesn't expect any request body.
   */
  montageplanControllerGetUserAvailabilityForWorkdate$Response(
    params: {
      fromDate: string;
      toDate: string;
      inMyTeam?: boolean;
      businessdivisionId?: number;
      notBusinessdivisionId?: number;
      userId?: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<AxMontageplanUserAvailabilityDto>>> {
    const rb = new RequestBuilder(this.rootUrl, AxMontageplanV2Service.MontageplanControllerGetUserAvailabilityForWorkdatePath, 'get');
    if (params) {
      rb.path('fromDate', params.fromDate, {});
      rb.path('toDate', params.toDate, {});
      rb.query('inMyTeam', params.inMyTeam, {});
      rb.query('businessdivisionId', params.businessdivisionId, {});
      rb.query('notBusinessdivisionId', params.notBusinessdivisionId, {});
      rb.query('userId', params.userId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AxMontageplanUserAvailabilityDto>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `montageplanControllerGetUserAvailabilityForWorkdate$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  montageplanControllerGetUserAvailabilityForWorkdate(
    params: {
      fromDate: string;
      toDate: string;
      inMyTeam?: boolean;
      businessdivisionId?: number;
      notBusinessdivisionId?: number;
      userId?: number;
    },
    context?: HttpContext
  ): Observable<Array<AxMontageplanUserAvailabilityDto>> {
    return this.montageplanControllerGetUserAvailabilityForWorkdate$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<AxMontageplanUserAvailabilityDto>>): Array<AxMontageplanUserAvailabilityDto> => r.body)
    );
  }

  /** Path part for operation `montageplanControllerAssignUserToTeam()` */
  static readonly MontageplanControllerAssignUserToTeamPath = '/v2/projects/montageplan/users/assignToTeam/{userId}/{teamId}/{dateFrom}/{dateTo}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `montageplanControllerAssignUserToTeam()` instead.
   *
   * This method doesn't expect any request body.
   */
  montageplanControllerAssignUserToTeam$Response(
    params: {
      userId: number;
      teamId: number;
      dateFrom: string;
      dateTo: string;
      isTeamlead: boolean;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AxSuccessDto>> {
    const rb = new RequestBuilder(this.rootUrl, AxMontageplanV2Service.MontageplanControllerAssignUserToTeamPath, 'post');
    if (params) {
      rb.path('userId', params.userId, {});
      rb.path('teamId', params.teamId, {});
      rb.path('dateFrom', params.dateFrom, {});
      rb.path('dateTo', params.dateTo, {});
      rb.query('isTeamlead', params.isTeamlead, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AxSuccessDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `montageplanControllerAssignUserToTeam$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  montageplanControllerAssignUserToTeam(
    params: {
      userId: number;
      teamId: number;
      dateFrom: string;
      dateTo: string;
      isTeamlead: boolean;
    },
    context?: HttpContext
  ): Observable<AxSuccessDto> {
    return this.montageplanControllerAssignUserToTeam$Response(params, context).pipe(
      map((r: StrictHttpResponse<AxSuccessDto>): AxSuccessDto => r.body)
    );
  }

  /** Path part for operation `montageplanControllerCalculateStatusForProject()` */
  static readonly MontageplanControllerCalculateStatusForProjectPath = '/v2/projects/montageplan/projects/calculateStatus/{projectId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `montageplanControllerCalculateStatusForProject()` instead.
   *
   * This method doesn't expect any request body.
   */
  montageplanControllerCalculateStatusForProject$Response(
    params: {
      projectId: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AxSuccessDto>> {
    const rb = new RequestBuilder(this.rootUrl, AxMontageplanV2Service.MontageplanControllerCalculateStatusForProjectPath, 'post');
    if (params) {
      rb.path('projectId', params.projectId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AxSuccessDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `montageplanControllerCalculateStatusForProject$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  montageplanControllerCalculateStatusForProject(
    params: {
      projectId: number;
    },
    context?: HttpContext
  ): Observable<AxSuccessDto> {
    return this.montageplanControllerCalculateStatusForProject$Response(params, context).pipe(
      map((r: StrictHttpResponse<AxSuccessDto>): AxSuccessDto => r.body)
    );
  }

  /** Path part for operation `montageplanControllerListenToSyncStatus()` */
  static readonly MontageplanControllerListenToSyncStatusPath = '/v2/projects/montageplan/projects/syncStatus';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `montageplanControllerListenToSyncStatus()` instead.
   *
   * This method doesn't expect any request body.
   */
  montageplanControllerListenToSyncStatus$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AxMontageplanSyncStatusDtoEvent>> {
    const rb = new RequestBuilder(this.rootUrl, AxMontageplanV2Service.MontageplanControllerListenToSyncStatusPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/event-stream', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AxMontageplanSyncStatusDtoEvent>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `montageplanControllerListenToSyncStatus$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  montageplanControllerListenToSyncStatus(
    params?: {
    },
    context?: HttpContext
  ): Observable<AxMontageplanSyncStatusDtoEvent> {
    return this.montageplanControllerListenToSyncStatus$Response(params, context).pipe(
      map((r: StrictHttpResponse<AxMontageplanSyncStatusDtoEvent>): AxMontageplanSyncStatusDtoEvent => r.body)
    );
  }

  /** Path part for operation `montageplanControllerLockTeam()` */
  static readonly MontageplanControllerLockTeamPath = '/v2/projects/montageplan/teams/lock/{teamId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `montageplanControllerLockTeam()` instead.
   *
   * This method doesn't expect any request body.
   */
  montageplanControllerLockTeam$Response(
    params: {
      teamId: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, AxMontageplanV2Service.MontageplanControllerLockTeamPath, 'post');
    if (params) {
      rb.path('teamId', params.teamId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `montageplanControllerLockTeam$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  montageplanControllerLockTeam(
    params: {
      teamId: number;
    },
    context?: HttpContext
  ): Observable<void> {
    return this.montageplanControllerLockTeam$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `montageplanControllerUnlockTeam()` */
  static readonly MontageplanControllerUnlockTeamPath = '/v2/projects/montageplan/teams/unlock/{teamId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `montageplanControllerUnlockTeam()` instead.
   *
   * This method doesn't expect any request body.
   */
  montageplanControllerUnlockTeam$Response(
    params: {
      teamId: number;
      force?: boolean;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, AxMontageplanV2Service.MontageplanControllerUnlockTeamPath, 'post');
    if (params) {
      rb.path('teamId', params.teamId, {});
      rb.query('force', params.force, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `montageplanControllerUnlockTeam$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  montageplanControllerUnlockTeam(
    params: {
      teamId: number;
      force?: boolean;
    },
    context?: HttpContext
  ): Observable<void> {
    return this.montageplanControllerUnlockTeam$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `montageplanControllerListenToLockStatus()` */
  static readonly MontageplanControllerListenToLockStatusPath = '/v2/projects/montageplan/teams/lockStatus';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `montageplanControllerListenToLockStatus()` instead.
   *
   * This method doesn't expect any request body.
   */
  montageplanControllerListenToLockStatus$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AxMontageplanLockStatusDtoEvent>> {
    const rb = new RequestBuilder(this.rootUrl, AxMontageplanV2Service.MontageplanControllerListenToLockStatusPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/event-stream', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AxMontageplanLockStatusDtoEvent>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `montageplanControllerListenToLockStatus$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  montageplanControllerListenToLockStatus(
    params?: {
    },
    context?: HttpContext
  ): Observable<AxMontageplanLockStatusDtoEvent> {
    return this.montageplanControllerListenToLockStatus$Response(params, context).pipe(
      map((r: StrictHttpResponse<AxMontageplanLockStatusDtoEvent>): AxMontageplanLockStatusDtoEvent => r.body)
    );
  }

}
