import { Component, Input } from '@angular/core';
import { AxContact, AxProject } from 'src/app/_generated/axova-rest-api';

@Component({
  selector: 'ax-project-intro',
  templateUrl: './ax-project-intro.component.html',
  styleUrls: ['./ax-project-intro.component.scss'],
})
export class AxProjectIntroComponent {
  @Input() project: AxProject;
  @Input() contact: AxContact;
  @Input() isInternalView: boolean = false;

  constructor() {
  }
}
