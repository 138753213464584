import { Component, NgZone, OnDestroy, OnInit } from '@angular/core';
import { Keyboard } from '@capacitor/keyboard';

@Component({
  selector: 'ax-sunny',
  templateUrl: './ax-sunny.component.html',
  styleUrls: ['./ax-sunny.component.scss'],
})
export class AxSunnyComponent implements OnInit, OnDestroy {
  public isVisible: boolean;

  constructor(
    private ngZone: NgZone,
  ) {
    this.isVisible = true;
  }

  ngOnInit() {
    Keyboard.addListener('keyboardWillShow', () => {
      this.ngZone.run(() => {
        this.isVisible = false;
      });
    });
    Keyboard.addListener('keyboardDidHide', () => {
      this.ngZone.run(() => {
        this.isVisible = true;
      });
    });
  }

  ngOnDestroy() {
    Keyboard.removeAllListeners().then();
  }
}
