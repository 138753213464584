import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import {
  AxCalculationitem,
  AxContact,
  AxElectricalMeasurement,
  AxElectricalMeasurementsService,
  AxItemsForProjectDto,
  AxMyService,
  AxProject,
  AxProjectrevenue,
  AxProjectrevenuesService,
  AxProjectsService,
  AxTask,
} from 'src/app/_generated/axova-rest-api';
import { ModalController } from '@ionic/angular';
import { CreateElectricalMeasurementDetailPage } from '../../pages/global/create-electrical-measurement/create-electrical-measurement-detail/create-electrical-measurement-detail.page';
import { LoggerService } from '../../ax-commons-logger/logger/logger.service';
import { Observable } from 'rxjs';
import { AppState } from '../../ngxs/app/app.state';
import { Store } from '@ngxs/store';
import { RolesEnum, RolesEnumGroups } from '../../ngxs/app/app.state.model';

@Component({
  selector: 'ax-project',
  templateUrl: './ax-project.component.html',
  styleUrls: ['./ax-project.component.scss'],
})
export class AxProjectComponent implements OnInit {
  @Input() project: AxProject;
  @Input() items: AxItemsForProjectDto;
  @Input() contact: AxContact;
  @Input() isInternalView: boolean = false;
  @Output() openItemDetailsForCalculationitem: EventEmitter<AxCalculationitem> = new EventEmitter<AxCalculationitem>();
  public appointments: AxTask[];
  public calculationitems: AxCalculationitem[] = [];
  public projectrevenues: AxProjectrevenue[];
  public electricalMeasurements: AxElectricalMeasurement[];
  public accordionStates: any = {};
  public roles: Observable<string[]> = this.store.select(AppState.roles);
  public isDSProject = false;
  public isAbStufeKundenberater = false;

  constructor(
    private axProjectsService: AxProjectsService,
    private axProjectrevenuesService: AxProjectrevenuesService,
    private axElectricalMeasurementsService: AxElectricalMeasurementsService,
    private axMyService: AxMyService,
    private modalController: ModalController,
    private router: Router,
    private store: Store,
  ) {
  }

  ngOnInit() {
    if (this.isInternalView) {
      this.axProjectsService.projectsControllerGetAppointmentsForProject({ id: this.project.id })
        .subscribe(appointments => this.appointments = appointments);
      this.axProjectsService.projectsControllerGetCalculationitemsForProject({ id: this.project.id })
        .subscribe(calculationitems => this.calculationitems = calculationitems);
      this.axProjectrevenuesService.projectrevenuesControllerFindByProjectId({ projectId: this.project.id })
        .subscribe(projectrevenues => this.projectrevenues = projectrevenues);
      this.axElectricalMeasurementsService.electricalMeasurementsControllerFindAllForProject({ projectId: this.project.id })
        .subscribe(electricalMeasurements => this.electricalMeasurements = electricalMeasurements);

      try {
        this.isDSProject = this.project.container.containertypeId === 2;
      } catch (nothing) {
      }

      this.roles.subscribe(roles => {
        const isAbStufeKundenberater = () =>
          roles.some((role: RolesEnum) =>
            RolesEnumGroups.ab_stufe_kundenberater.includes(role),
          );
        this.isAbStufeKundenberater = isAbStufeKundenberater();
      });
    } else {
      this.axMyService.myProjectsControllerGetProjectrevenuesForProject({ id: this.project.id })
        .subscribe(projectrevenues => this.projectrevenues = projectrevenues);
    }
  }

  goToPhotosUpload() {
    this.router.navigateByUrl(`/global/upload-photos-to-project/${this.project.id}`).then();
  }

  async openElectricalMeasurementDetail(electricalMeasurement: AxElectricalMeasurement) {
    const modal = await this.modalController.create({
      component: CreateElectricalMeasurementDetailPage,
      componentProps: {
        electricalMeasurement,
        readonly: true,
      },
    });
    modal.onDidDismiss().then((overlayEventDetail) => {
      if (overlayEventDetail.data) {
        electricalMeasurement = overlayEventDetail.data;
      }
      LoggerService.LOG(this, 'electricalMeasurement.active', electricalMeasurement.active);
      if (!electricalMeasurement.active) {
        const index = this.electricalMeasurements.findIndex(em => em.id === electricalMeasurement.id);
        LoggerService.LOG(this, 'index', index);
        if (index > -1) {
          this.electricalMeasurements.splice(index, 1);
        }
      }
    });
    await modal.present();
  }
}
