import { Pipe, PipeTransform } from '@angular/core';
import { getFormattedNumber } from './functions/get-formatted-number';

@Pipe({
  name: 'formatNumber',
})
export class FormatNumberPipe implements PipeTransform {
  transform(value: number | undefined | null): string {
    return getFormattedNumber(value);
  }
}
