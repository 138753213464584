import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { GroupByEventRegistrationOptionPipe } from 'src/app/pipes/group-by-event-registration-option.pipe';
import { LineBreaksPipe } from 'src/app/pipes/line-breaks.pipe';
import { NotNullFilterPipe } from 'src/app/pipes/not-null-fillter.pipe';
import { NullFilterPipe } from 'src/app/pipes/null-fillter.pipe';
import { OrderByPipe } from 'src/app/pipes/order-by.pipe';
import { TrimWhitespacesPipe } from 'src/app/pipes/trim-whitespaces.pipe';
import { WherePipe } from './where.pipe';
import { FormatNumberPipe } from './format-number.pipe';
import { PrependServerToFileSrcPipe } from './prepend-server-to-file-src.pipe';
import { GetStocklocationBreadcrumbPipe } from './get-stocklocation-breadcrumb.pipe';


@NgModule({
  declarations: [
    FormatNumberPipe,
    GroupByEventRegistrationOptionPipe,
    LineBreaksPipe,
    NotNullFilterPipe,
    NullFilterPipe,
    OrderByPipe,
    TrimWhitespacesPipe,
    WherePipe,
    PrependServerToFileSrcPipe,
    GetStocklocationBreadcrumbPipe,
  ],
  imports: [
    CommonModule,
  ],
  exports: [
    FormatNumberPipe,
    GroupByEventRegistrationOptionPipe,
    LineBreaksPipe,
    NotNullFilterPipe,
    NullFilterPipe,
    OrderByPipe,
    TrimWhitespacesPipe,
    WherePipe,
    PrependServerToFileSrcPipe,
    GetStocklocationBreadcrumbPipe,
  ],
})
export class PipesModule {
}
