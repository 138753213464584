import { Component, Input, OnChanges } from '@angular/core';
import * as moment from 'moment';
import { AxProject } from 'src/app/_generated/axova-rest-api';

@Component({
  selector: 'ax-appointment-project-overview',
  templateUrl: './ax-appointment-project-overview.component.html',
  styleUrls: ['./ax-appointment-project-overview.component.scss'],
})
export class AxAppointmentProjectOverviewComponent implements OnChanges {
  @Input() project: AxProject;
  public showFixedInstallationDates: boolean;
  public showInstallationDates: boolean;
  public probableStartDate: Date;
  public probableEndDate: Date;

  constructor() {
  }

  public ngOnChanges() {
    this.showFixedInstallationDates = this.project.installationStartFixed || this.project.communicationLevel === 1;
    this.probableStartDate = moment(this.project.installationStart).subtract(14, 'days').toDate();
    this.probableEndDate = moment(this.project.installationStart).add(14, 'days').toDate();
    this.showInstallationDates = moment(this.project.installationEnd).isAfter(moment().startOf('day'));
  }
}
