import { Pipe, PipeTransform } from '@angular/core';
import { AxAbsence } from 'src/app/_generated/axova-rest-api';

@Pipe({name: 'getIconForTimerecordType'})
export class AxApiGetIconForTimerecordType implements PipeTransform {
  transform(absence: AxAbsence) {
    switch (absence.timerecordtypeId) {
      case 3:
        return 'assets/svg-icons/military.svg';
      case 6:
        return 'assets/svg-icons/health.svg';
      case 7:
        return 'assets/svg-icons/accident.svg';
      default:
        return 'assets/svg-icons/holidays.svg';
    }
  }
}
