/* tslint:disable */
/* eslint-disable */
export enum AxSearchIndexKeyEnum {
  Searchterm = 'searchterm',
  Contact = 'contact',
  Project = 'project',
  Item = 'item',
  User = 'user',
  Equipment = 'equipment',
  Stocklocation = 'stocklocation'
}
