import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { AxCommonsApiModule } from 'src/app/ax-commons-api/ax-commons-api.module';
import { AxComponentsModule } from 'src/app/components/ax-components.module';
import { ProjectSelectorModalPage } from './project-selector-modal.page';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    AxComponentsModule,
    AxCommonsApiModule,
  ],
  declarations: [
    ProjectSelectorModalPage,
  ],
  exports: [],
})
export class ProjectSelectorModalModule {
}
