import { Component, Input, OnInit } from '@angular/core';
import { AxTimerecorddetail, AxTimerecorddetailsByDateDto } from 'src/app/_generated/axova-rest-api';
import * as  moment from 'moment';
import { CalendarWeek } from '../../pages/employees-tabs/arbeitszeit/arbeitszeit.page';

@Component({
  selector: 'ax-timerecorddetails-list',
  templateUrl: './ax-timerecorddetails-list.component.html',
  styleUrls: ['./ax-timerecorddetails-list.component.scss'],
})
export class AxTimerecorddetailsListComponent implements OnInit {
  @Input() timerecorddetailsByDateDto: AxTimerecorddetailsByDateDto;
  @Input() selectedCalendarWeek: CalendarWeek | undefined;
  public isPopoverLockedOpen: AxTimerecorddetail | undefined;
  public isPopoverSentToAgentOpen: AxTimerecorddetail | undefined;

  constructor() {
  }

  public ngOnInit() {
  }

  protected readonly moment = moment;
}
