
import { FormGroup, FormControl, FormArray, Validators } from '@angular/forms';
import { requiredBoolean } from './required-boolean.validator';

export function getUpdateEquipmentintervalDtoFormGroup() {
return new FormGroup({
    name: new FormControl<string | undefined>('', [Validators.required]),
    description: new FormControl<string | undefined>(undefined),
    equipmentId: new FormControl<number | undefined>(undefined, [Validators.required]),
    equipmentlogtypeId: new FormControl<number | undefined>(undefined, [Validators.required]),
    intervalInDays: new FormControl<number | undefined>(undefined, [Validators.required]),
    validFrom: new FormControl<string | undefined>(undefined, [Validators.required]),
    validTo: new FormControl<string | undefined>(undefined),
    defaultAssigneeId: new FormControl<number | undefined>(undefined),
    active: new FormControl<boolean | undefined>(true, [requiredBoolean()]),
})
;
}
      