import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AxComment } from 'src/app/_generated/axova-rest-api';

@Component({
  selector: 'ax-comment',
  templateUrl: './ax-comment.component.html',
  styleUrls: ['./ax-comment.component.scss'],
})
export class AxCommentComponent implements OnInit {
  @Input() comment: AxComment;
  @Input() allowComments: boolean = false;
  @Output() sendAnswer: EventEmitter<Partial<AxComment>> = new EventEmitter<Partial<AxComment>>();
  public newComment = '';
  public showAnswerButton = false;

  constructor() {
  }

  ngOnInit() {
  }

  send() {
    this.sendAnswer.emit({
      comment: this.newComment,
      parentId: this.comment.id,
    });
    this.newComment = '';
  }
}
