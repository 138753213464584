import { AxContainer } from 'src/app/_generated/axova-rest-api/models/ax-container';
import { AxProject } from 'src/app/_generated/axova-rest-api/models/ax-project';
import { AxTask } from 'src/app/_generated/axova-rest-api/models/ax-task';

export class UserCustomerStateSetContainers {
  static readonly type = '[user-customer] Set containers';

  constructor(public containers: AxContainer[]) {
  }
}

export class UserCustomerStateSetProjects {
  static readonly type = '[user-customer] Set projects';

  constructor(public projects: AxProject[]) {
  }
}

export class UserCustomerStateSetSelectedProject {
  static readonly type = '[user-customer] Set selected project';

  constructor(public project: AxProject) {
  }
}

export class UserCustomerStateSetAppointments {
  static readonly type = '[user-customer] Set appointsments';

  constructor(public appointments: AxTask[]) {
  }
}

export class UserCustomerStateSetTasks {
  static readonly type = '[user-customer] Set tasks';

  constructor(public tasks: AxTask[]) {
  }
}
