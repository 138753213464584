import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { GalleryModule } from 'ng-gallery';
import { AxApiGetUploadPipe } from 'src/app/ax-commons-api/ax-api-get-upload.pipe';
import { AxCommonsApiModule } from 'src/app/ax-commons-api/ax-commons-api.module';
import { AxComponentsModule } from 'src/app/components/ax-components.module';
import { NewsDetailPageRoutingModule } from 'src/app/pages/global/news-detail/news-detail-routing.module';
import { NewsDetailPage } from 'src/app/pages/global/news-detail/news-detail.page';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    NewsDetailPageRoutingModule,
    AxCommonsApiModule,
    AxComponentsModule,
    GalleryModule.withConfig({
      counter: false,
      thumb: false,
      loadingStrategy: 'preload',
    }),
  ],
  declarations: [NewsDetailPage],
  providers: [
    AxApiGetUploadPipe,
  ],
})
export class NewsDetailPageModule {
}
