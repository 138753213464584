/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { AxNews } from '../models/ax-news';
import { AxPaginatedDocumented } from '../models/ax-paginated-documented';

@Injectable({ providedIn: 'root' })
export class AxWebsiteArticlesAndBannersV2Service extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `websiteArticlesAndBannersControllerFindArticlesPaginated()` */
  static readonly WebsiteArticlesAndBannersControllerFindArticlesPaginatedPath = '/v2/website/articles-and-banners/articles';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `websiteArticlesAndBannersControllerFindArticlesPaginated()` instead.
   *
   * This method doesn't expect any request body.
   */
  websiteArticlesAndBannersControllerFindArticlesPaginated$Response(
    params?: {

    /**
     * Page number to retrieve.If you provide invalid value the default page number will applied
     *         <p>
     *              <b>Example: </b> 1
     *           </p>
     *         <p>
     *              <b>Default Value: </b> 1
     *           </p>
     */
      page?: number;

    /**
     * Number of records per page.
     *       <p>
     *              <b>Example: </b> 20
     *           </p>
     *       <p>
     *              <b>Default Value: </b> 6
     *           </p>
     *       <p>
     *              <b>Max Value: </b> 100
     *           </p>
     *
     *       If provided value is greater than max value, max value will be applied.
     */
      limit?: number;

    /**
     * Parameter to sort by.
     *       <p>To sort by multiple fields, just provide query param multiple types. The order in url defines an order of sorting</p>
     *       <p>
     *              <b>Format: </b> fieldName:DIRECTION
     *           </p>
     *       <p>
     *              <b>Example: </b> sortBy=id:DESC&sortBy=createdAt:ASC
     *           </p>
     *       <p>
     *              <b>Default Value: </b> showFrom:DESC,created:DESC
     *           </p>
     *       <h4>Available Fields</h4><ul><li>title</li></ul>
     */
      sortBy?: Array<'title:ASC' | 'title:DESC'>;

    /**
     * Search term to filter result values
     *         <p>
     *              <b>Example: </b> John
     *           </p>
     *         <p>
     *              <b>Default Value: </b> No default value
     *           </p>
     */
      search?: string;

    /**
     * List of fields to search by term to filter result values
     *         <p>
     *              <b>Example: </b> title,text
     *           </p>
     *         <p>
     *              <b>Default Value: </b> By default all fields mentioned below will be used to search by term
     *           </p>
     *         <h4>Available Fields</h4><ul><li>title</li>
     * <li>text</li></ul>
     */
      searchBy?: Array<string>;
      tags?: Array<any>;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AxPaginatedDocumented & {
'data'?: Array<AxNews>;
'meta'?: {
'select'?: Array<string>;
'filter'?: {
};
};
}>> {
    const rb = new RequestBuilder(this.rootUrl, AxWebsiteArticlesAndBannersV2Service.WebsiteArticlesAndBannersControllerFindArticlesPaginatedPath, 'get');
    if (params) {
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.query('sortBy', params.sortBy, {});
      rb.query('search', params.search, {});
      rb.query('searchBy', params.searchBy, {});
      rb.query('tags', params.tags, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AxPaginatedDocumented & {
        'data'?: Array<AxNews>;
        'meta'?: {
        'select'?: Array<string>;
        'filter'?: {
        };
        };
        }>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `websiteArticlesAndBannersControllerFindArticlesPaginated$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  websiteArticlesAndBannersControllerFindArticlesPaginated(
    params?: {

    /**
     * Page number to retrieve.If you provide invalid value the default page number will applied
     *         <p>
     *              <b>Example: </b> 1
     *           </p>
     *         <p>
     *              <b>Default Value: </b> 1
     *           </p>
     */
      page?: number;

    /**
     * Number of records per page.
     *       <p>
     *              <b>Example: </b> 20
     *           </p>
     *       <p>
     *              <b>Default Value: </b> 6
     *           </p>
     *       <p>
     *              <b>Max Value: </b> 100
     *           </p>
     *
     *       If provided value is greater than max value, max value will be applied.
     */
      limit?: number;

    /**
     * Parameter to sort by.
     *       <p>To sort by multiple fields, just provide query param multiple types. The order in url defines an order of sorting</p>
     *       <p>
     *              <b>Format: </b> fieldName:DIRECTION
     *           </p>
     *       <p>
     *              <b>Example: </b> sortBy=id:DESC&sortBy=createdAt:ASC
     *           </p>
     *       <p>
     *              <b>Default Value: </b> showFrom:DESC,created:DESC
     *           </p>
     *       <h4>Available Fields</h4><ul><li>title</li></ul>
     */
      sortBy?: Array<'title:ASC' | 'title:DESC'>;

    /**
     * Search term to filter result values
     *         <p>
     *              <b>Example: </b> John
     *           </p>
     *         <p>
     *              <b>Default Value: </b> No default value
     *           </p>
     */
      search?: string;

    /**
     * List of fields to search by term to filter result values
     *         <p>
     *              <b>Example: </b> title,text
     *           </p>
     *         <p>
     *              <b>Default Value: </b> By default all fields mentioned below will be used to search by term
     *           </p>
     *         <h4>Available Fields</h4><ul><li>title</li>
     * <li>text</li></ul>
     */
      searchBy?: Array<string>;
      tags?: Array<any>;
    },
    context?: HttpContext
  ): Observable<AxPaginatedDocumented & {
'data'?: Array<AxNews>;
'meta'?: {
'select'?: Array<string>;
'filter'?: {
};
};
}> {
    return this.websiteArticlesAndBannersControllerFindArticlesPaginated$Response(params, context).pipe(
      map((r: StrictHttpResponse<AxPaginatedDocumented & {
'data'?: Array<AxNews>;
'meta'?: {
'select'?: Array<string>;
'filter'?: {
};
};
}>): AxPaginatedDocumented & {
'data'?: Array<AxNews>;
'meta'?: {
'select'?: Array<string>;
'filter'?: {
};
};
} => r.body)
    );
  }

  /** Path part for operation `websiteArticlesAndBannersControllerFindOneById()` */
  static readonly WebsiteArticlesAndBannersControllerFindOneByIdPath = '/v2/website/articles-and-banners/article/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `websiteArticlesAndBannersControllerFindOneById()` instead.
   *
   * This method doesn't expect any request body.
   */
  websiteArticlesAndBannersControllerFindOneById$Response(
    params: {
      id: number;
      accessKey?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AxNews>> {
    const rb = new RequestBuilder(this.rootUrl, AxWebsiteArticlesAndBannersV2Service.WebsiteArticlesAndBannersControllerFindOneByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('accessKey', params.accessKey, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AxNews>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `websiteArticlesAndBannersControllerFindOneById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  websiteArticlesAndBannersControllerFindOneById(
    params: {
      id: number;
      accessKey?: string;
    },
    context?: HttpContext
  ): Observable<AxNews> {
    return this.websiteArticlesAndBannersControllerFindOneById$Response(params, context).pipe(
      map((r: StrictHttpResponse<AxNews>): AxNews => r.body)
    );
  }

  /** Path part for operation `websiteArticlesAndBannersControllerFindBannersPaginated()` */
  static readonly WebsiteArticlesAndBannersControllerFindBannersPaginatedPath = '/v2/website/articles-and-banners/banners';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `websiteArticlesAndBannersControllerFindBannersPaginated()` instead.
   *
   * This method doesn't expect any request body.
   */
  websiteArticlesAndBannersControllerFindBannersPaginated$Response(
    params?: {

    /**
     * Page number to retrieve.If you provide invalid value the default page number will applied
     *         <p>
     *              <b>Example: </b> 1
     *           </p>
     *         <p>
     *              <b>Default Value: </b> 1
     *           </p>
     */
      page?: number;

    /**
     * Number of records per page.
     *       <p>
     *              <b>Example: </b> 20
     *           </p>
     *       <p>
     *              <b>Default Value: </b> 6
     *           </p>
     *       <p>
     *              <b>Max Value: </b> 100
     *           </p>
     *
     *       If provided value is greater than max value, max value will be applied.
     */
      limit?: number;

    /**
     * Parameter to sort by.
     *       <p>To sort by multiple fields, just provide query param multiple types. The order in url defines an order of sorting</p>
     *       <p>
     *              <b>Format: </b> fieldName:DIRECTION
     *           </p>
     *       <p>
     *              <b>Example: </b> sortBy=id:DESC&sortBy=createdAt:ASC
     *           </p>
     *       <p>
     *              <b>Default Value: </b> showFrom:DESC,created:DESC
     *           </p>
     *       <h4>Available Fields</h4><ul><li>title</li></ul>
     */
      sortBy?: Array<'title:ASC' | 'title:DESC'>;

    /**
     * Search term to filter result values
     *         <p>
     *              <b>Example: </b> John
     *           </p>
     *         <p>
     *              <b>Default Value: </b> No default value
     *           </p>
     */
      search?: string;

    /**
     * List of fields to search by term to filter result values
     *         <p>
     *              <b>Example: </b> title,text
     *           </p>
     *         <p>
     *              <b>Default Value: </b> By default all fields mentioned below will be used to search by term
     *           </p>
     *         <h4>Available Fields</h4><ul><li>title</li>
     * <li>text</li></ul>
     */
      searchBy?: Array<string>;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AxPaginatedDocumented & {
'data'?: Array<AxNews>;
'meta'?: {
'select'?: Array<string>;
'filter'?: {
};
};
}>> {
    const rb = new RequestBuilder(this.rootUrl, AxWebsiteArticlesAndBannersV2Service.WebsiteArticlesAndBannersControllerFindBannersPaginatedPath, 'get');
    if (params) {
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.query('sortBy', params.sortBy, {});
      rb.query('search', params.search, {});
      rb.query('searchBy', params.searchBy, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AxPaginatedDocumented & {
        'data'?: Array<AxNews>;
        'meta'?: {
        'select'?: Array<string>;
        'filter'?: {
        };
        };
        }>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `websiteArticlesAndBannersControllerFindBannersPaginated$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  websiteArticlesAndBannersControllerFindBannersPaginated(
    params?: {

    /**
     * Page number to retrieve.If you provide invalid value the default page number will applied
     *         <p>
     *              <b>Example: </b> 1
     *           </p>
     *         <p>
     *              <b>Default Value: </b> 1
     *           </p>
     */
      page?: number;

    /**
     * Number of records per page.
     *       <p>
     *              <b>Example: </b> 20
     *           </p>
     *       <p>
     *              <b>Default Value: </b> 6
     *           </p>
     *       <p>
     *              <b>Max Value: </b> 100
     *           </p>
     *
     *       If provided value is greater than max value, max value will be applied.
     */
      limit?: number;

    /**
     * Parameter to sort by.
     *       <p>To sort by multiple fields, just provide query param multiple types. The order in url defines an order of sorting</p>
     *       <p>
     *              <b>Format: </b> fieldName:DIRECTION
     *           </p>
     *       <p>
     *              <b>Example: </b> sortBy=id:DESC&sortBy=createdAt:ASC
     *           </p>
     *       <p>
     *              <b>Default Value: </b> showFrom:DESC,created:DESC
     *           </p>
     *       <h4>Available Fields</h4><ul><li>title</li></ul>
     */
      sortBy?: Array<'title:ASC' | 'title:DESC'>;

    /**
     * Search term to filter result values
     *         <p>
     *              <b>Example: </b> John
     *           </p>
     *         <p>
     *              <b>Default Value: </b> No default value
     *           </p>
     */
      search?: string;

    /**
     * List of fields to search by term to filter result values
     *         <p>
     *              <b>Example: </b> title,text
     *           </p>
     *         <p>
     *              <b>Default Value: </b> By default all fields mentioned below will be used to search by term
     *           </p>
     *         <h4>Available Fields</h4><ul><li>title</li>
     * <li>text</li></ul>
     */
      searchBy?: Array<string>;
    },
    context?: HttpContext
  ): Observable<AxPaginatedDocumented & {
'data'?: Array<AxNews>;
'meta'?: {
'select'?: Array<string>;
'filter'?: {
};
};
}> {
    return this.websiteArticlesAndBannersControllerFindBannersPaginated$Response(params, context).pipe(
      map((r: StrictHttpResponse<AxPaginatedDocumented & {
'data'?: Array<AxNews>;
'meta'?: {
'select'?: Array<string>;
'filter'?: {
};
};
}>): AxPaginatedDocumented & {
'data'?: Array<AxNews>;
'meta'?: {
'select'?: Array<string>;
'filter'?: {
};
};
} => r.body)
    );
  }

}
