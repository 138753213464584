/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { AxDocumenttype } from '../models/ax-documenttype';
import { AxDropdownOptionDto } from '../models/ax-dropdown-option-dto';

@Injectable({ providedIn: 'root' })
export class AxDocumenttypesV2Service extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `documenttypesControllerGetAllDropdownOptions()` */
  static readonly DocumenttypesControllerGetAllDropdownOptionsPath = '/v2/documenttypes/dropdown-options';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `documenttypesControllerGetAllDropdownOptions()` instead.
   *
   * This method doesn't expect any request body.
   */
  documenttypesControllerGetAllDropdownOptions$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<AxDropdownOptionDto>>> {
    const rb = new RequestBuilder(this.rootUrl, AxDocumenttypesV2Service.DocumenttypesControllerGetAllDropdownOptionsPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AxDropdownOptionDto>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `documenttypesControllerGetAllDropdownOptions$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  documenttypesControllerGetAllDropdownOptions(
    params?: {
    },
    context?: HttpContext
  ): Observable<Array<AxDropdownOptionDto>> {
    return this.documenttypesControllerGetAllDropdownOptions$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<AxDropdownOptionDto>>): Array<AxDropdownOptionDto> => r.body)
    );
  }

  /** Path part for operation `documenttypesControllerGetAllDropdownOptionsByContainertypeId()` */
  static readonly DocumenttypesControllerGetAllDropdownOptionsByContainertypeIdPath = '/v2/documenttypes/dropdown-options/{containertypeId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `documenttypesControllerGetAllDropdownOptionsByContainertypeId()` instead.
   *
   * This method doesn't expect any request body.
   */
  documenttypesControllerGetAllDropdownOptionsByContainertypeId$Response(
    params: {
      containertypeId: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<AxDropdownOptionDto>>> {
    const rb = new RequestBuilder(this.rootUrl, AxDocumenttypesV2Service.DocumenttypesControllerGetAllDropdownOptionsByContainertypeIdPath, 'get');
    if (params) {
      rb.path('containertypeId', params.containertypeId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AxDropdownOptionDto>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `documenttypesControllerGetAllDropdownOptionsByContainertypeId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  documenttypesControllerGetAllDropdownOptionsByContainertypeId(
    params: {
      containertypeId: number;
    },
    context?: HttpContext
  ): Observable<Array<AxDropdownOptionDto>> {
    return this.documenttypesControllerGetAllDropdownOptionsByContainertypeId$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<AxDropdownOptionDto>>): Array<AxDropdownOptionDto> => r.body)
    );
  }

  /** Path part for operation `documenttypesControllerFindAll()` */
  static readonly DocumenttypesControllerFindAllPath = '/v2/documenttypes';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `documenttypesControllerFindAll()` instead.
   *
   * This method doesn't expect any request body.
   */
  documenttypesControllerFindAll$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<AxDocumenttype>>> {
    const rb = new RequestBuilder(this.rootUrl, AxDocumenttypesV2Service.DocumenttypesControllerFindAllPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AxDocumenttype>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `documenttypesControllerFindAll$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  documenttypesControllerFindAll(
    params?: {
    },
    context?: HttpContext
  ): Observable<Array<AxDocumenttype>> {
    return this.documenttypesControllerFindAll$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<AxDocumenttype>>): Array<AxDocumenttype> => r.body)
    );
  }

  /** Path part for operation `documenttypesControllerFindById()` */
  static readonly DocumenttypesControllerFindByIdPath = '/v2/documenttypes/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `documenttypesControllerFindById()` instead.
   *
   * This method doesn't expect any request body.
   */
  documenttypesControllerFindById$Response(
    params: {
      id: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AxDocumenttype>> {
    const rb = new RequestBuilder(this.rootUrl, AxDocumenttypesV2Service.DocumenttypesControllerFindByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AxDocumenttype>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `documenttypesControllerFindById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  documenttypesControllerFindById(
    params: {
      id: number;
    },
    context?: HttpContext
  ): Observable<AxDocumenttype> {
    return this.documenttypesControllerFindById$Response(params, context).pipe(
      map((r: StrictHttpResponse<AxDocumenttype>): AxDocumenttype => r.body)
    );
  }

}
