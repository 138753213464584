import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AxCalculationitem, AxItemsForProjectDto } from 'src/app/_generated/axova-rest-api';

@Component({
  selector: 'ax-project-items-for-employees',
  templateUrl: './ax-project-items-for-employees.component.html',
  styleUrls: ['./ax-project-items-for-employees.component.scss'],
})
export class AxProjectItemsForEmployeesComponent implements OnInit {
  @Input() calculationitems: AxCalculationitem[];
  @Output() openItemDetailsForCalculationitem: EventEmitter<AxCalculationitem> = new EventEmitter<AxCalculationitem>();
  public accordionStates: any = {};

  constructor() {
  }

  ngOnInit() {
  }
}
