import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { catchError, concatMap, map, retryWhen, share, switchMap, tap } from 'rxjs/operators';
import { AxAuthService } from 'src/app/_generated/axova-rest-api/services/ax-auth.service';
import { AxTokenService } from 'src/app/ax-commons-api/ax-api-auth/ax-token.service';
import { AppState } from 'src/app/ngxs/app/app.state';
import { AxUiService } from 'src/app/services/ax-ui.service';

@Injectable()
export class AxApiTokenInterceptor implements HttpInterceptor {
  private retryRequest = Symbol('reload');
  private refreshToken = this.axTokenService.refreshToken$.asObservable()
    .pipe(
      concatMap(refreshToken => this.axAuthService
        .authControllerRefreshToken({
          body: {
            refreshToken,
            appDeviceId: this.store.selectSnapshot(AppState.appDeviceId),
          },
        })
        .pipe(
          map(response => {
            try {
              return JSON.parse(response as any);
            } catch (noNewTokensException) {
              return response;
            }
          }),
          tap(newTokenResponse => {
            if ((newTokenResponse as any).access_token && (newTokenResponse as any).refresh_token) {
              this.axTokenService.setAccessToken((newTokenResponse as any).access_token).then();
              this.axTokenService.setRefreshToken((newTokenResponse as any).refresh_token).then();
              throw this.retryRequest;
            }
          }),
          share(),
        ) as Observable<any>),
    );

  constructor(
    private axTokenService: AxTokenService,
    private axAuthService: AxAuthService,
    private axUiService: AxUiService,
    private router: Router,
    private store: Store,
  ) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const request$ = new Observable<HttpRequest<any>>(observer => {
      observer.next(this.addToken(request));
      observer.complete();
    });

    // @ts-ignore
    return request$.pipe(
      switchMap(req => next.handle(req)),
      catchError((error: Error) => {
        if (error instanceof HttpErrorResponse) {
          // Catch refresh token error, which does a logout.
          if (error.url.indexOf(AxAuthService.AuthControllerRefreshTokenPath) > -1) {
            this.axTokenService.logout().then();
            this.router.navigateByUrl('/').then();
            this.axUiService.showHint('Aufgrund zu langer Inaktivität wurden Sie automatisch abgemeldet.');
            this.axUiService.clearToastErrors();
            throw error;
          }
          switch (error.status) {
            case 401:
              return this.refreshToken;
            default:
              if (error?.error?.message && error?.status !== 403 && error?.status !== 401) {
                this.axUiService.showError(`${error?.error?.message}`, `Fehler ${error.status}`).then();
              }
              this.axUiService.hideLoading().then();
              throw error;
          }
        } else {
          throw error;
        }
      }),
      retryWhen(err$ =>
        err$.pipe(
          tap(err => {
            if (err === this.retryRequest) {
              return;
            }
            throw err;
          }),
        ),
      ),
    );
  }

  /**
   * Adds the bearer token to the http request.
   *
   * @param req
   * @private
   */
  private addToken(req: HttpRequest<any>): HttpRequest<any> {
    return req.clone({
      setHeaders: {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        Authorization: 'Bearer ' + this.axTokenService.accessToken$.getValue(),
      },
    });
  }
}
