
import { FormGroup, FormControl, FormArray, Validators } from '@angular/forms';


export function getCreateWorkflowtaskDtoFormGroup() {
return new FormGroup({
    workflowId: new FormControl<number | undefined>(undefined, [Validators.required]),
    workflowtasktypeId: new FormControl<number | undefined>(undefined, [Validators.required]),
    userId: new FormControl<number | undefined>(undefined),
    standardtaskId: new FormControl<number | undefined>(undefined),
    messagingTemplateId: new FormControl<number | undefined>(undefined),
    receiverId: new FormControl<number | undefined>(undefined),
    taskname: new FormControl<string | undefined>(undefined),
    subject: new FormControl<string | undefined>(undefined),
    notes: new FormControl<string | undefined>(undefined),
    taskdue: new FormControl<number | undefined>(undefined),
    taskstart: new FormControl<number | undefined>(undefined),
    taskend: new FormControl<number | undefined>(undefined),
    sortableRank: new FormControl<number | undefined>(undefined),
    responsibleUsertype: new FormControl<any>(undefined),
})
;
}
      