import { LoggerService } from 'src/app/ax-commons-logger/logger/logger.service';

export class AxovaQrCode {
  public isValid: boolean;
  public type: 'project' | 'equipment';
  public data: {
    id: number;
  };

  constructor(qrcodeData: string) {
    try {
      const json = JSON.parse(qrcodeData);
      if (json.type) {
        this.type = json.type;
        this.isValid = true;
      }
      if (json.data && json.data.id) {
        this.data = {
          id: json.data.id,
        };
      }
    } catch (qrCodeOrJsonException) {
      LoggerService.ERROR(this, 'qrCodeOrJsonException', qrCodeOrJsonException);
      this.isValid = false;
    }
  }
}
