import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ModalController } from '@ionic/angular';
import { LoggerService } from 'src/app/ax-commons-logger/logger/logger.service';
import { MailService } from 'src/app/services/mail.service';

@Component({
  selector: 'app-info-modal',
  templateUrl: './info-modal.page.html',
  styleUrls: ['./info-modal.page.scss'],
})
export class InfoModalPage implements OnInit {
  @Input() infoModalData: {
    title?: string;
    message?: string;
    subtitle?: string;
    button?: string;
    contactId?: string;
    showMailButton?: boolean;
    showPhoneButton?: boolean;
  };
  @Output() buttonClicked: EventEmitter<void> = new EventEmitter<void>();

  constructor(
    private modalController: ModalController,
    private mailService: MailService,
    private title: Title,
  ) {
  }

  ngOnInit() {
  }

  ionViewDidEnter() {
    this.title.setTitle(`Info-Modal nach App-Update`);
  }

  public call() {
    window.open(`tel:0800400222`, '_blank');
  }

  /**
   * Create new support mail with technical details.
   */
  public sendSupportMail() {
    const supportEmail = 'it@axova.ch';
    const body = `
    Guten Tag, ich möchte gerne die folgende E-Mail Adresse und/oder Mobiltelefonnummer für die Axova-App hinterlegen:<br><br>
    ...<br><br>
    Vielen Dank und freundliche Grüsse<br><br><hr><br>
    <small>Kontakt ID: ${this.infoModalData.contactId}</small>
    `;
    this.mailService.createMail(supportEmail, 'E-Mail Adresse oder Mobiltelefonnummer hinterlegen (Axova-App)', body)
      .catch(() => {
        window.open(`mailto:${supportEmail}?subject=E-Mail Adresse oder Mobiltelefonnummer hinterlegen (Axova-App)`, '_blank');
      });
  }

  public close() {
    try {
      this.modalController.getTop().then(instance => instance.dismiss());
    } catch (noModalToCloseException) {
      LoggerService.LOG(this, 'noModalToCloseException', noModalToCloseException);
    }
  }
}
