import { Component, Input, OnInit } from '@angular/core';
import { AxDocument, AxProjectsService } from 'src/app/_generated/axova-rest-api';
import { DocumentsService } from '../../services/documents.service';
import { RolesEnum, RolesEnumGroups } from '../../ngxs/app/app.state.model';
import { AppState } from '../../ngxs/app/app.state';
import { Store } from '@ngxs/store';

@Component({
  selector: 'ax-project-documents-for-employees',
  templateUrl: './ax-project-documents-for-employees.component.html',
  styleUrls: ['./ax-project-documents-for-employees.component.scss'],
})
export class AxProjectDocumentsForEmployeesComponent implements OnInit {
  @Input() projectId: number;
  public accordionStates: any = {};
  public documents: AxDocument[] = [];
  public documentBestellung: AxDocument | undefined;
  public isAbStufeProjektleiter = false;
  private roles$ = this.store.select(AppState.roles);

  constructor(
    private readonly axProjectsService: AxProjectsService,
    private readonly documentsService: DocumentsService,
    private readonly store: Store,
  ) {
  }

  ngOnInit() {
    this.roles$.subscribe(roles => {
      const isAbStufeProjektleiter = () =>
        roles.some((role: RolesEnum) =>
          [...RolesEnumGroups.ab_stufe_abteilungsleiter, RolesEnum.projektleiter].includes(role),
        );
      this.isAbStufeProjektleiter = isAbStufeProjektleiter();
      this.axProjectsService.projectsControllerGetDocumentsForProject({ id: this.projectId }).subscribe({
        next: documents => {
          const activeDocuments = documents.filter((document) => document.active === true);
          this.documentBestellung = activeDocuments.find((document) => document.documenttypeId === 13 || document.documenttypeId === 30 || document.documenttypeId === 60);
          this.documents = activeDocuments.filter(document => [85, 84, 46, 91, 51, 45, 82, 52, 86, 87, 88, 92, 93].indexOf(document.documenttypeId) > -1);
        },
      });
    });
  }

  public async openPdf(document: AxDocument) {
    await this.documentsService.openPdf(document);
  }
}
