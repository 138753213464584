import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'where',
  pure: false,
})
export class WherePipe implements PipeTransform {
  transform(array: Array<any>, property: string, value: any, not = false): Array<any> {
    if (not) {
      return array.filter(item => item[property] !== value);
    }
    return array.filter(item => item[property] === value);
  }
}
