import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { ModalController, NavController } from '@ionic/angular';
import { Store } from '@ngxs/store';
import { AxHelpersService, AxMyService, AxUser } from 'src/app/_generated/axova-rest-api';
import { LoggerService } from 'src/app/ax-commons-logger/logger/logger.service';
import { AxUiService } from 'src/app/services/ax-ui.service';

@Component({
  selector: 'app-update-user-data-form',
  templateUrl: './update-user-data-form.page.html',
  styleUrls: ['./update-user-data-form.page.scss'],
})
export class UpdateUserDataFormPage implements OnInit {
  @Input() user: AxUser;
  @Input() isModal = false;
  public formData: Partial<AxUser> & { message: string } = {
    street: '',
    city: '',
    emergencycontact: '',
    birthdate: undefined,
    emailprivate: '',
    message: '',
  };
  @ViewChild('updateUserDataForm', {read: NgForm}) updateUserDataForm!: NgForm;

  constructor(
    private axUiService: AxUiService,
    private store: Store,
    private axHelpersService: AxHelpersService,
    private axMyService: AxMyService,
    private modalController: ModalController,
    private router: Router,
    private navController: NavController,
    private title: Title,
  ) {
  }

  ngOnInit() {
    this.formData = {
      ...this.user,
      message: '',
    };
  }

  ionViewDidEnter() {
    this.title.setTitle(`Mitarbeiter-Daten aktualisieren`);
  }

  public close() {
    try {
      this.modalController.dismiss().then();
    } catch (closeException) {
      this.navController.pop();
    }
  }

  public submit(hasChanges: boolean) {
    LoggerService.LOG(this, 'formData', this.formData);
    this.axMyService.myProfileControllerUpdate({
      body: {
        hasChanges,
        message: this.formData.message,
        user: (this.formData as any),
      },
    }).subscribe({
      next: value => {
        this.axUiService.showSuccess('Vielen Dank für deine Mitarbeit! Daten erfolgreich gesendet.').then();
        this.close();
      },
      error: err => {
        this.axUiService.showError('Deine Nachricht konnte leider nicht versendet werden. Bitte versuche es später nochmal.').then();
      },
    });
  }
}
