import { Component, Input, OnInit } from '@angular/core';
import { AxProjectrevenue } from 'src/app/_generated/axova-rest-api';

@Component({
  selector: 'ax-project-projectrevenues',
  templateUrl: './ax-project-projectrevenues.component.html',
  styleUrls: ['./ax-project-projectrevenues.component.scss'],
})
export class AxProjectProjectrevenuesComponent implements OnInit {
  @Input() projectrevenues: AxProjectrevenue[];
  @Input() isInternalView: boolean = false;
  public accordionStates: any = {};

  constructor() {
  }

  ngOnInit() {
  }
}
