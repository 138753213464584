import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import * as moment from 'moment';
import { AxElectricalMeasurement, AxProject } from 'src/app/_generated/axova-rest-api';
import { AxMyService } from 'src/app/_generated/axova-rest-api/services/ax-my.service';
import { AxTokenService } from 'src/app/ax-commons-api/ax-api-auth/ax-token.service';
import {
  CreateElectricalMeasurementAddMeasurement,
  CreateElectricalMeasurementClear,
  CreateElectricalMeasurementDeleteMeasurementAtIndex,
  CreateElectricalMeasurementSetBase,
  CreateElectricalMeasurementSetProject,
  CreateElectricalMeasurementUpdateMeasurementAtIndex,
} from 'src/app/ngxs/create-electrical-measurement/create-electrical-measurement.actions';
import {
  CreateElectricalMeasurementStateModel,
} from 'src/app/ngxs/create-electrical-measurement/create-electrical-measurement.state.model';

const STATE_NAME = 'axova__createElectricalMeasurement';
const DEFAULT_STATE: CreateElectricalMeasurementStateModel = {
  baseMeasurement: {
    electricalMeasurementTypeId: undefined,
    electricalMeasurementDeviceId: undefined,
    created: moment().toISOString(),
  },
  measurements: [],
  project: undefined,
};

@State<CreateElectricalMeasurementStateModel>({
  name: STATE_NAME,
  defaults: DEFAULT_STATE,
})
@Injectable()
export class CreateElectricalMeasurementState {
  constructor(
    private store: Store,
    private axTokenService: AxTokenService,
    private axMyService: AxMyService,
  ) {
  }

  @Selector()
  static baseMeasurement(state: CreateElectricalMeasurementStateModel): Partial<AxElectricalMeasurement> {
    return state.baseMeasurement;
  }

  @Selector()
  static project(state: CreateElectricalMeasurementStateModel): AxProject {
    return state.project;
  }

  @Selector()
  static measurements(state: CreateElectricalMeasurementStateModel): Partial<AxElectricalMeasurement>[] {
    return state.measurements;
  }

  @Selector()
  static aggregatedMeasurements(state: CreateElectricalMeasurementStateModel): Partial<AxElectricalMeasurement>[] {
    const baseMeasurement = state.baseMeasurement;
    return state.measurements.map(measurement => {
      return {
        ...baseMeasurement,
        ...measurement,
        projectId: state.project.id,
      };
    });
  }

  @Action(CreateElectricalMeasurementSetProject)
  setProject(ctx: StateContext<CreateElectricalMeasurementStateModel>, action: CreateElectricalMeasurementSetProject) {
    ctx.patchState({project: action.project});
  }

  @Action(CreateElectricalMeasurementSetBase)
  setBase(ctx: StateContext<CreateElectricalMeasurementStateModel>, action: CreateElectricalMeasurementSetBase) {
    ctx.patchState({baseMeasurement: action.electricalMeasurement});
  }

  @Action(CreateElectricalMeasurementAddMeasurement)
  addMeasurement(ctx: StateContext<CreateElectricalMeasurementStateModel>, action: CreateElectricalMeasurementAddMeasurement) {
    ctx.patchState({measurements: ctx.getState().measurements.concat(action.electricalMeasurement)});
  }

  @Action(CreateElectricalMeasurementUpdateMeasurementAtIndex)
  updateMeasurementAtIndex(ctx: StateContext<CreateElectricalMeasurementStateModel>, action: CreateElectricalMeasurementUpdateMeasurementAtIndex) {
    const measurements = JSON.parse(JSON.stringify(ctx.getState().measurements));
    measurements[action.indexOfMeasurement] = action.electricalMeasurement;
    ctx.patchState({measurements});
  }

  @Action(CreateElectricalMeasurementDeleteMeasurementAtIndex)
  deleteMeasurementAtIndex(ctx: StateContext<CreateElectricalMeasurementStateModel>, action: CreateElectricalMeasurementDeleteMeasurementAtIndex) {
    const measurements = JSON.parse(JSON.stringify(ctx.getState().measurements)) as AxElectricalMeasurement[];
    measurements.splice(action.indexOfMeasurement, 1);
    ctx.patchState({measurements});
  }

  @Action(CreateElectricalMeasurementClear)
  clear(ctx: StateContext<CreateElectricalMeasurementStateModel>, action: CreateElectricalMeasurementClear) {
    ctx.setState(DEFAULT_STATE);
  }
}
