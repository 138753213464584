import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AxEvent } from 'src/app/_generated/axova-rest-api';

@Component({
  selector: 'ax-event-item',
  templateUrl: './ax-event-item.component.html',
  styleUrls: ['./ax-event-item.component.scss'],
})
export class AxEventItemComponent implements OnInit {
  @Input() eventItem: AxEvent;
  @Output() open: EventEmitter<AxEvent> = new EventEmitter<AxEvent>();

  constructor() {
  }

  ngOnInit() {
  }
}
