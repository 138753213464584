import { Component, Input, OnInit } from '@angular/core';
import { AxMontageplanDayDto, AxMontageplanUsersTeamPartial, AxMontageplanV2Service, AxTimerecordtypeNameEnum } from 'src/app/_generated/axova-rest-api';
import * as  moment from 'moment';
import { CalendarWeek } from '../../pages/global/wochenuebersicht/wochenuebersicht.page';
import { LoggerService } from '../../ax-commons-logger/logger/logger.service';
import { AxUiService } from '../../services/ax-ui.service';

@Component({
  selector: 'ax-tagesuebersicht-card',
  templateUrl: './ax-tagesuebersicht-card.component.html',
  styleUrls: ['./ax-tagesuebersicht-card.component.scss'],
})
export class AxTagesuebersichtCardComponent implements OnInit {
  @Input() montageplanDayDto: AxMontageplanDayDto;
  @Input() selectedCalendarWeek: CalendarWeek | undefined;
  public montageplanUsersTeamPartial: AxMontageplanUsersTeamPartial[] = [];
  public isEntireTeamAbsent = false;
  protected axTimerecordtypeNameEnum = AxTimerecordtypeNameEnum;
  protected readonly moment = moment;

  constructor(
    private readonly axMontageplanV2Service: AxMontageplanV2Service,
    private readonly axUiService: AxUiService,
  ) {
  }

  public async ngOnInit() {
    await this.loadTeamData();
  }


  private async loadTeamData() {
    try {
      const teamId = this.montageplanDayDto.teams[0].teamId;
      const montageplanUsersDto = await this.axMontageplanV2Service.montageplanControllerGetMontageplanUsersByTeamIdAndDaterange({
        fromDate: this.montageplanDayDto.date,
        toDate: this.montageplanDayDto.date,
        teamIds: [`${teamId}`],
      }).toPromise();
      this.montageplanUsersTeamPartial = montageplanUsersDto.teams[teamId];
      this.isEntireTeamAbsent = this.getIsEntireTeamAbsent(this.montageplanUsersTeamPartial, this.montageplanDayDto.date);
    } catch (dataNotLoadedException) {
      LoggerService.ERROR(this, 'dataNotLoadedException', dataNotLoadedException);
      if (dataNotLoadedException.status === 403 || dataNotLoadedException.status === '403') {
        await this.axUiService.showError('Kein Zugriff auf den Teams.');
      }
    }
  }

  private getIsEntireTeamAbsent(montageplanUsersTeamPartials: AxMontageplanUsersTeamPartial[], date: string) {
    let isAbsent = true;
    montageplanUsersTeamPartials.forEach(usersTeamPartial => {
      if (usersTeamPartial.timerecords[date].timerecordtypeName === AxTimerecordtypeNameEnum.Arbeit) {
        isAbsent = false;
      }
    });
    return isAbsent;
  }
}
