import { Injectable } from '@angular/core';
import { Action, NgxsAfterBootstrap, Selector, State, StateContext, Store } from '@ngxs/store';
import { AxContainer } from 'src/app/_generated/axova-rest-api/models/ax-container';
import { AxProject } from 'src/app/_generated/axova-rest-api/models/ax-project';
import { AxMyService } from 'src/app/_generated/axova-rest-api/services/ax-my.service';
import { AxTokenService } from 'src/app/ax-commons-api/ax-api-auth/ax-token.service';
import { LoggerService } from 'src/app/ax-commons-logger/logger/logger.service';
import { AppState } from 'src/app/ngxs/app/app.state';
import {
  UserCustomerStateSetAppointments,
  UserCustomerStateSetContainers,
  UserCustomerStateSetProjects,
  UserCustomerStateSetSelectedProject,
  UserCustomerStateSetTasks,
} from 'src/app/ngxs/user-customer/user-customer.actions';
import { UserCustomerStateModel } from 'src/app/ngxs/user-customer/user-customer.state.model';

const STATE_NAME = 'axova__userCustomersState';
const DEFAULT_STATE: UserCustomerStateModel = {
  containers: [],
  projects: [],
  selectedProject: null,
  appointments: [],
  tasks: [],
};

@State<UserCustomerStateModel>({
  name: STATE_NAME,
  defaults: DEFAULT_STATE,
})
@Injectable()
export class UserCustomerState implements NgxsAfterBootstrap {
  constructor(
    private store: Store,
    private axTokenService: AxTokenService,
    private axMyService: AxMyService,
  ) {
  }

  @Selector()
  static containers(state: UserCustomerStateModel): AxContainer[] {
    return state.containers;
  }

  @Selector()
  static projects(state: UserCustomerStateModel): AxProject[] {
    return state.projects;
  }

  @Selector()
  static selectedProject(state: UserCustomerStateModel): AxProject {
    return state.selectedProject;
  }

  @Action(UserCustomerStateSetTasks)
  setTasks(ctx: StateContext<UserCustomerStateModel>, action: UserCustomerStateSetTasks) {
    ctx.patchState({tasks: action.tasks});
  }

  @Action(UserCustomerStateSetAppointments)
  setAppointments(ctx: StateContext<UserCustomerStateModel>, action: UserCustomerStateSetAppointments) {
    ctx.patchState({appointments: action.appointments});
  }

  @Action(UserCustomerStateSetContainers)
  setContainers(ctx: StateContext<UserCustomerStateModel>, action: UserCustomerStateSetContainers) {
    ctx.patchState({containers: action.containers});
  }

  @Action(UserCustomerStateSetProjects)
  setProjects(ctx: StateContext<UserCustomerStateModel>, action: UserCustomerStateSetProjects) {
    ctx.patchState({projects: action.projects});
  }

  @Action(UserCustomerStateSetSelectedProject)
  setSelectedProject(ctx: StateContext<UserCustomerStateModel>, action: UserCustomerStateSetSelectedProject) {
    LoggerService.LOG(this, 'UserCustomerStateSetSelectedProject', 'selectedProject');
    ctx.patchState({selectedProject: action.project});
  }

  ngxsAfterBootstrap(ctx: StateContext<UserCustomerStateModel>) {
    this.store.select(AppState.profile).subscribe({
      next: profile => {
        if (profile) {
          if (profile.isContactEntity) {
            this.axMyService.myProjectsControllerGetProjectsByContainer()
              .subscribe({
                next: containers => {
                  this.store.dispatch(new UserCustomerStateSetContainers(containers));
                  if (containers && containers.length) {
                    let projects = [];
                    for (const container of containers) {
                      if (container.projects && container.projects.length) {
                        const newProjects = container.projects.map((project) => {
                          return {
                            ...project,
                            container,
                          };
                        });
                        projects = projects.concat(newProjects);
                      }
                    }
                    this.store.dispatch(new UserCustomerStateSetProjects(projects));
                    const firstProject: AxProject = projects[0];
                    this.store.dispatch(new UserCustomerStateSetSelectedProject(firstProject));
                  }
                },
              });
          }
        }
      },
    });
  }
}
