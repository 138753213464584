import { DATE_PIPE_DEFAULT_TIMEZONE } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import '@angular/common/locales/global/de-CH';
import { APP_INITIALIZER, DEFAULT_CURRENCY_CODE, LOCALE_ID, NgModule } from '@angular/core';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { getFirestore, provideFirestore } from '@angular/fire/firestore';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouteReuseStrategy } from '@angular/router';
import { BarcodeScanner } from '@awesome-cordova-plugins/barcode-scanner/ngx';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { IonicStorageModule } from '@ionic/storage-angular';
import { NgxsStoragePluginModule, StorageOption } from '@ngxs/storage-plugin';
import { NgxsModule } from '@ngxs/store';
import * as moment from 'moment';
import { NgxsResetPluginModule } from 'ngxs-reset-plugin';
import { ApiModule } from 'src/app/_generated/axova-rest-api/api.module';
import { AppInitializer } from 'src/app/app.initializer';
import { AxApiTokenInterceptor } from 'src/app/ax-commons-api/ax-api-token.interceptor';
import { AxCommonsApiModule } from 'src/app/ax-commons-api/ax-commons-api.module';
import { AppState } from 'src/app/ngxs/app/app.state';
import { CreateElectricalMeasurementState } from 'src/app/ngxs/create-electrical-measurement/create-electrical-measurement.state';
import { UserCustomerState } from 'src/app/ngxs/user-customer/user-customer.state';
import { CreateElectricalMeasurementPageModule } from 'src/app/pages/global/create-electrical-measurement/create-electrical-measurement.module';
import { CreateLeadFormPageModule } from 'src/app/pages/global/create-lead-form/create-lead-form.module';
import { EventDetailPageModule } from 'src/app/pages/global/event-detail/event-detail.module';
import { InfoModalPageModule } from 'src/app/pages/global/info-modal/info-modal.module';
import { NewsDetailPageModule } from 'src/app/pages/global/news-detail/news-detail.module';
import { PdfModalPageModule } from 'src/app/pages/global/pdf-modal/pdf-modal.module';
import { UpdateUserDataFormPageModule } from 'src/app/pages/global/update-user-data-form/update-user-data-form.module';
import { AxUiService } from 'src/app/services/ax-ui.service';
import { PermissionsService } from 'src/app/services/permissions.service';
import { QrcodeService } from 'src/app/services/qrcode.service';
import { environment } from 'src/environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PhotoViewerService } from './services/photo-viewer.service';
import { EventsPageModule } from './pages/global/events/events.module';
import { EventsAdminDetailPageModule } from './pages/global/events-admin-detail/events-admin-detail.module';
import { ProjectSelectorModalModule } from './pages/global/project-selector-modal/project-selector-modal.module';

export const initializeAxovaApp = (appInitializer: AppInitializer) => (): Promise<any> => appInitializer.init();
const timezoneFactory = () => {
  const isSommerzeit = moment().isDST();
  return isSommerzeit ? 'CEST' : 'CET';
};

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    IonicModule.forRoot({
      backButtonText: 'Zurück',
      mode: 'ios',
      backButtonDefaultHref: '/',
    }),
    AppRoutingModule,
    AxCommonsApiModule,
    ApiModule.forRoot({ rootUrl: environment.apiConfiguration.baseUrl }),
    IonicStorageModule.forRoot(),
    NgxsModule.forRoot([
      AppState,
      UserCustomerState,
      CreateElectricalMeasurementState,
    ], {
      developmentMode: !environment.production,
    }),
    NgxsStoragePluginModule.forRoot({
      storage: StorageOption.LocalStorage,
    }),
    provideFirebaseApp(() => initializeApp(environment.firebase.configuration)),
    provideFirestore(() => getFirestore()),
    NgxsResetPluginModule.forRoot(),
    NewsDetailPageModule,
    EventDetailPageModule,
    InfoModalPageModule,
    PdfModalPageModule,
    UpdateUserDataFormPageModule,
    CreateLeadFormPageModule,
    CreateElectricalMeasurementPageModule,
    EventsPageModule,
    EventsAdminDetailPageModule,
    ProjectSelectorModalModule,
  ],
  providers: [
    {
      provide: RouteReuseStrategy,
      useClass: IonicRouteStrategy,
    },
    {
      provide: LOCALE_ID,
      useValue: 'de-CH',
    },
    {
      provide: DATE_PIPE_DEFAULT_TIMEZONE,
      useFactory: timezoneFactory,
    },
    {
      provide: DEFAULT_CURRENCY_CODE,
      useValue: 'CHF',
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AxApiTokenInterceptor,
      multi: true,
    },
    AppInitializer,
    {
      provide: APP_INITIALIZER,
      useFactory: initializeAxovaApp,
      deps: [AppInitializer],
      multi: true,
    },
    AxUiService,
    BarcodeScanner,
    QrcodeService,
    PermissionsService,
    PhotoViewerService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
}
