import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import * as moment from 'moment';
import { AxEvent, AxEventsService, AxMyService, AxUploadsService } from 'src/app/_generated/axova-rest-api';
import { AxUiService } from 'src/app/services/ax-ui.service';
import { ScannerService } from 'src/app/services/scanner.service';

@Component({
  selector: 'app-events',
  templateUrl: './events.page.html',
  styleUrls: ['./events.page.scss'],
})
export class EventsPage implements OnInit {
  public eventsFuture: AxEvent[] = [];
  public eventsPast: AxEvent[] = [];
  public segment: 'future' | 'past' = 'future';

  constructor(
    private router: Router,
    private title: Title,
    private store: Store,
    private scannerService: ScannerService,
    private axUploadsService: AxUploadsService,
    private axUiService: AxUiService,
    private axEventsService: AxEventsService,
    private axMyService: AxMyService,
  ) {
  }

  async ngOnInit() {
    await this.loadData();
  }

  ionViewDidEnter() {
    this.title.setTitle(`Veranstaltungen`);
  }

  /**
   * Initial data load. Can be repeated, e.g. via ion-refresher.
   *
   * @param refresherEvent
   */
  public async loadData(refresherEvent?) {
    const allEvents = await this.axMyService.myEventsControllerFindAll({ onlyFutureEvents: false }).toPromise();
    this.eventsFuture = allEvents.filter(event => moment(event.endAt).isAfter(moment()));
    this.eventsPast = allEvents.filter(event => moment(event.endAt).isBefore(moment()));
    this.onLoadDataComplete(refresherEvent);
  }

  public openEventDetail(eventItem: AxEvent) {
    this.router.navigate([`/global/event-detail/${eventItem.id}`]).then();
  }

  /**
   * After Loading completed.
   *
   * @param refresherEvent
   * @private
   */
  private onLoadDataComplete(refresherEvent): void {
    if (refresherEvent) {
      refresherEvent.target.complete();
    }
  }
}
