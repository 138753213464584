import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { AxEvent, AxEventInvitation, AxEventInvitationsService, AxEventInvitationStatusEnum, AxEventsService } from 'src/app/_generated/axova-rest-api';
import { LoggerService } from 'src/app/ax-commons-logger/logger/logger.service';

@Component({
  selector: 'app-events-admin-detail',
  templateUrl: './events-admin-detail.page.html',
  styleUrls: ['./events-admin-detail.page.scss'],
})
export class EventsAdminDetailPage implements OnInit {
  public event: AxEvent;
  public eventInvitationsAccepted: AxEventInvitation[] = [];
  public eventInvitationsDeclined: AxEventInvitation[] = [];
  public eventInvitationsNotAnswered: AxEventInvitation[] = [];
  public segment: 'teilnehmer' | 'optionen' = 'teilnehmer';
  public accordionStates: any = {};

  constructor(
    private activatedRoute: ActivatedRoute,
    private title: Title,
    private axEventsService: AxEventsService,
    private axEventInvitationsService: AxEventInvitationsService,
  ) {
  }

  async ngOnInit() {
    this.activatedRoute.params.subscribe(async data => {
      await this.loadData(data.id);
    });
  }

  ionViewDidEnter() {
    this.title.setTitle(`Veranstaltungs-Detail`);
  }

  /**
   * Initial data load. Can be repeated, e.g. via ion-refresher.
   *
   * @param eventId
   * @param refresherEvent
   */
  public async loadData(eventId: number, refresherEvent?) {
    this.event = await this.axEventsService.eventsControllerFindById({ id: eventId }).toPromise();
    this.eventInvitationsAccepted = await this.axEventInvitationsService.eventInvitationsControllerFindByEventId({ eventId, invitationStatus: AxEventInvitationStatusEnum.Accepted }).toPromise();
    this.eventInvitationsDeclined = await this.axEventInvitationsService.eventInvitationsControllerFindByEventId({ eventId, invitationStatus: AxEventInvitationStatusEnum.Declined }).toPromise();
    this.eventInvitationsNotAnswered = await this.axEventInvitationsService.eventInvitationsControllerFindByEventId({ eventId, invitationStatus: AxEventInvitationStatusEnum.Unanswered }).toPromise();
    this.onLoadDataComplete(refresherEvent);
  }

  public async checkInToggle(event: { eventInvitation: AxEventInvitation; checked: boolean }) {
    LoggerService.LOG(this, 'event', event);
    await this.axEventInvitationsService.eventInvitationsControllerUpdate({
      id: event.eventInvitation.id, body: {
        checkInAt: event.checked ? new Date().toISOString() : null,
      },
    }).toPromise().then(async () => {
      this.eventInvitationsAccepted = await this.axEventInvitationsService.eventInvitationsControllerFindByEventId({
        eventId: this.event.id,
        invitationStatus: AxEventInvitationStatusEnum.Accepted,
      }).toPromise();
    });
  }

  /**
   * After Loading completed.
   *
   * @param refresherEvent
   * @private
   */
  private onLoadDataComplete(refresherEvent): void {
    if (refresherEvent) {
      refresherEvent.target.complete();
    }
  }
}
