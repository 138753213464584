/* tslint:disable */
/* eslint-disable */
export enum AxMessagingTemplateNameEnum {
  ObBestaetigung = 'ob_bestaetigung',
  ObErinnerung = 'ob_erinnerung',
  ObStornierung = 'ob_stornierung',
  ObTerminassistent = 'ob_terminassistent',
  Speicherinformation = 'speicherinformation',
  SpeicherinformationSolaredge = 'speicherinformation_solaredge',
  Terminbestaetigung = 'terminbestaetigung',
  TerminbestaetigungMitVerantwortlichem = 'terminbestaetigung_mit_verantwortlichem',
  Zahlungserinnerung = 'zahlungserinnerung',
  MitarbeiterWillkommenstext = 'mitarbeiter_willkommenstext'
}
