import { Injectable } from '@angular/core';
import { Preferences } from '@capacitor/preferences';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DarkmodeService {
  public isDarkModeOn: BehaviorSubject<boolean>;

  constructor() {
  }

  /**
   * Initialize dark mode after app start based on the device settings,
   * which can be retrieved best via media query.
   */
  public async initializeDarkMode() {
    const isDarkModeOn = await Preferences.get({key: 'isDarkModeOn'});
    if (!isDarkModeOn || isDarkModeOn?.value === null) {
      this.saveDarkModeSettingInStorage(false);
    }
    this.isDarkModeOn = new BehaviorSubject<boolean>(isDarkModeOn.value === 'true');
    if (this.isDarkModeOn.getValue()) {
      document.body.classList.add('dark');
    } else {
      document.body.classList.remove('dark');
    }
  }

  /**
   * Toggle dark mode manually.
   */
  public toggleDarkMode(): void {
    if (this.isDarkModeOn.getValue()) {
      this.disableDarkMode();
    } else {
      this.enableDarkMode();
    }
  }

  private async saveDarkModeSettingInStorage(isDarkModeOn: boolean) {
    return Preferences.set({
      key: 'isDarkModeOn',
      value: isDarkModeOn + '',
    });
  }

  /**
   * Helper method to set CSS class on body.
   */
  private enableDarkMode(): void {
    if (!document.body.classList.contains('dark')) {
      document.body.classList.add('dark');
    }
    this.isDarkModeOn.next(true);
    this.saveDarkModeSettingInStorage(true);
  }

  /**
   * Helper method to remove CSS class on body.
   */
  private disableDarkMode(): void {
    if (document.body.classList.contains('dark')) {
      document.body.classList.remove('dark');
    }
    this.isDarkModeOn.next(false);
    this.saveDarkModeSettingInStorage(false);
  }
}
