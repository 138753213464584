/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { AxAddRoletypeDto } from '../models/ax-add-roletype-dto';
import { AxCreateUserDto } from '../models/ax-create-user-dto';
import { AxDropdownOptionDto } from '../models/ax-dropdown-option-dto';
import { AxPaginatedDocumented } from '../models/ax-paginated-documented';
import { AxRemoveRoletypeDto } from '../models/ax-remove-roletype-dto';
import { AxRoletype } from '../models/ax-roletype';
import { AxSuccessDto } from '../models/ax-success-dto';
import { AxTag } from '../models/ax-tag';
import { AxUpdateUserDto } from '../models/ax-update-user-dto';
import { AxUser } from '../models/ax-user';
import { AxUserStatsDto } from '../models/ax-user-stats-dto';
import { AxUserV2 } from '../models/ax-user-v-2';
import { AxUsersGroupedForUserDto } from '../models/ax-users-grouped-for-user-dto';

@Injectable({ providedIn: 'root' })
export class AxUsersV2Service extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `usersControllerGetAllDropdownOptions()` */
  static readonly UsersControllerGetAllDropdownOptionsPath = '/v2/users/dropdown-options';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `usersControllerGetAllDropdownOptions()` instead.
   *
   * This method doesn't expect any request body.
   */
  usersControllerGetAllDropdownOptions$Response(
    params: {
      roles: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<AxDropdownOptionDto>>> {
    const rb = new RequestBuilder(this.rootUrl, AxUsersV2Service.UsersControllerGetAllDropdownOptionsPath, 'get');
    if (params) {
      rb.query('roles', params.roles, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AxDropdownOptionDto>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `usersControllerGetAllDropdownOptions$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  usersControllerGetAllDropdownOptions(
    params: {
      roles: string;
    },
    context?: HttpContext
  ): Observable<Array<AxDropdownOptionDto>> {
    return this.usersControllerGetAllDropdownOptions$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<AxDropdownOptionDto>>): Array<AxDropdownOptionDto> => r.body)
    );
  }

  /** Path part for operation `usersControllerGetAll()` */
  static readonly UsersControllerGetAllPath = '/v2/users/getAll';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `usersControllerGetAll()` instead.
   *
   * This method doesn't expect any request body.
   */
  usersControllerGetAll$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<AxUserV2>>> {
    const rb = new RequestBuilder(this.rootUrl, AxUsersV2Service.UsersControllerGetAllPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AxUserV2>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `usersControllerGetAll$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  usersControllerGetAll(
    params?: {
    },
    context?: HttpContext
  ): Observable<Array<AxUserV2>> {
    return this.usersControllerGetAll$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<AxUserV2>>): Array<AxUserV2> => r.body)
    );
  }

  /** Path part for operation `usersControllerGetBirthdays()` */
  static readonly UsersControllerGetBirthdaysPath = '/v2/users/birthdays';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `usersControllerGetBirthdays()` instead.
   *
   * This method doesn't expect any request body.
   */
  usersControllerGetBirthdays$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<AxUserV2>>> {
    const rb = new RequestBuilder(this.rootUrl, AxUsersV2Service.UsersControllerGetBirthdaysPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AxUserV2>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `usersControllerGetBirthdays$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  usersControllerGetBirthdays(
    params?: {
    },
    context?: HttpContext
  ): Observable<Array<AxUserV2>> {
    return this.usersControllerGetBirthdays$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<AxUserV2>>): Array<AxUserV2> => r.body)
    );
  }

  /** Path part for operation `usersControllerGetUserStatistics()` */
  static readonly UsersControllerGetUserStatisticsPath = '/v2/users/userStatistics';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `usersControllerGetUserStatistics()` instead.
   *
   * This method doesn't expect any request body.
   */
  usersControllerGetUserStatistics$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AxUserStatsDto>> {
    const rb = new RequestBuilder(this.rootUrl, AxUsersV2Service.UsersControllerGetUserStatisticsPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AxUserStatsDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `usersControllerGetUserStatistics$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  usersControllerGetUserStatistics(
    params?: {
    },
    context?: HttpContext
  ): Observable<AxUserStatsDto> {
    return this.usersControllerGetUserStatistics$Response(params, context).pipe(
      map((r: StrictHttpResponse<AxUserStatsDto>): AxUserStatsDto => r.body)
    );
  }

  /** Path part for operation `usersControllerFindAllPaginated()` */
  static readonly UsersControllerFindAllPaginatedPath = '/v2/users';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `usersControllerFindAllPaginated()` instead.
   *
   * This method doesn't expect any request body.
   */
  usersControllerFindAllPaginated$Response(
    params?: {
      onlyTeamMembers?: boolean;

    /**
     * Page number to retrieve.If you provide invalid value the default page number will applied
     *         <p>
     *              <b>Example: </b> 1
     *           </p>
     *         <p>
     *              <b>Default Value: </b> 1
     *           </p>
     */
      page?: number;

    /**
     * Number of records per page.
     *       <p>
     *              <b>Example: </b> 20
     *           </p>
     *       <p>
     *              <b>Default Value: </b> 25
     *           </p>
     *       <p>
     *              <b>Max Value: </b> 100
     *           </p>
     *
     *       If provided value is greater than max value, max value will be applied.
     */
      limit?: number;

    /**
     * Filter by businessunitId query param.
     *           <p>
     *              <b>Format: </b> filter.businessunitId={$not}:OPERATION:VALUE
     *           </p>
     *           <p>
     *              <b>Example: </b> filter.businessunitId=$not:$like:John Doe&filter.businessunitId=like:John
     *           </p>
     *           <h4>Available Operations</h4><ul><li>$eq</li></ul>
     */
      'filter.businessunitId'?: Array<string>;

    /**
     * Filter by businessdivisionId query param.
     *           <p>
     *              <b>Format: </b> filter.businessdivisionId={$not}:OPERATION:VALUE
     *           </p>
     *           <p>
     *              <b>Example: </b> filter.businessdivisionId=$not:$like:John Doe&filter.businessdivisionId=like:John
     *           </p>
     *           <h4>Available Operations</h4><ul><li>$eq</li></ul>
     */
      'filter.businessdivisionId'?: Array<string>;

    /**
     * Filter by active query param.
     *           <p>
     *              <b>Format: </b> filter.active={$not}:OPERATION:VALUE
     *           </p>
     *           <p>
     *              <b>Example: </b> filter.active=$not:$like:John Doe&filter.active=like:John
     *           </p>
     *           <h4>Available Operations</h4><ul><li>$eq</li></ul>
     */
      'filter.active'?: Array<string>;

    /**
     * Filter by supervisorId query param.
     *           <p>
     *              <b>Format: </b> filter.supervisorId={$not}:OPERATION:VALUE
     *           </p>
     *           <p>
     *              <b>Example: </b> filter.supervisorId=$not:$like:John Doe&filter.supervisorId=like:John
     *           </p>
     *           <h4>Available Operations</h4><ul><li>$eq</li></ul>
     */
      'filter.supervisorId'?: Array<string>;

    /**
     * Filter by id query param.
     *           <p>
     *              <b>Format: </b> filter.id={$not}:OPERATION:VALUE
     *           </p>
     *           <p>
     *              <b>Example: </b> filter.id=$not:$like:John Doe&filter.id=like:John
     *           </p>
     *           <h4>Available Operations</h4><ul><li>$eq</li></ul>
     */
      'filter.id'?: Array<string>;

    /**
     * Filter by useremployments.type query param.
     *           <p>
     *              <b>Format: </b> filter.useremployments.type={$not}:OPERATION:VALUE
     *           </p>
     *           <p>
     *              <b>Example: </b> filter.useremployments.type=$not:$like:John Doe&filter.useremployments.type=like:John
     *           </p>
     *           <h4>Available Operations</h4><ul><li>$eq</li></ul>
     */
      'filter.useremployments.type'?: Array<string>;

    /**
     * Filter by useremployments.validFrom query param.
     *           <p>
     *              <b>Format: </b> filter.useremployments.validFrom={$not}:OPERATION:VALUE
     *           </p>
     *           <p>
     *              <b>Example: </b> filter.useremployments.validFrom=$not:$like:John Doe&filter.useremployments.validFrom=like:John
     *           </p>
     *           <h4>Available Operations</h4><ul><li>$lte</li>
     * <li>$gte</li>
     * <li>$btw</li></ul>
     */
      'filter.useremployments.validFrom'?: Array<string>;

    /**
     * Filter by useremployments.validTo query param.
     *           <p>
     *              <b>Format: </b> filter.useremployments.validTo={$not}:OPERATION:VALUE
     *           </p>
     *           <p>
     *              <b>Example: </b> filter.useremployments.validTo=$not:$like:John Doe&filter.useremployments.validTo=like:John
     *           </p>
     *           <h4>Available Operations</h4><ul><li>$lte</li>
     * <li>$gte</li>
     * <li>$btw</li></ul>
     */
      'filter.useremployments.validTo'?: Array<string>;

    /**
     * Parameter to sort by.
     *       <p>To sort by multiple fields, just provide query param multiple types. The order in url defines an order of sorting</p>
     *       <p>
     *              <b>Format: </b> fieldName:DIRECTION
     *           </p>
     *       <p>
     *              <b>Example: </b> sortBy=id:DESC&sortBy=createdAt:ASC
     *           </p>
     *       <p>
     *              <b>Default Value: </b> lastName:ASC
     *           </p>
     *       <h4>Available Fields</h4><ul><li>id</li>
     * <li>lastName</li>
     * <li>birthdate</li>
     * <li>entrydate</li>
     * <li>useremployments.salaryGross</li></ul>
     */
      sortBy?: Array<'id:ASC' | 'id:DESC' | 'lastName:ASC' | 'lastName:DESC' | 'birthdate:ASC' | 'birthdate:DESC' | 'entrydate:ASC' | 'entrydate:DESC' | 'useremployments.salaryGross:ASC' | 'useremployments.salaryGross:DESC'>;

    /**
     * Search term to filter result values
     *         <p>
     *              <b>Example: </b> John
     *           </p>
     *         <p>
     *              <b>Default Value: </b> No default value
     *           </p>
     */
      search?: string;

    /**
     * List of fields to search by term to filter result values
     *         <p>
     *              <b>Example: </b> searchableFullname
     *           </p>
     *         <p>
     *              <b>Default Value: </b> By default all fields mentioned below will be used to search by term
     *           </p>
     *         <h4>Available Fields</h4><ul><li>searchableFullname</li></ul>
     */
      searchBy?: Array<string>;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AxPaginatedDocumented & {
'data'?: Array<AxUser>;
'meta'?: {
'select'?: Array<string>;
'filter'?: {
'businessunitId'?: (string | Array<string>);
'businessdivisionId'?: (string | Array<string>);
'active'?: (string | Array<string>);
'supervisorId'?: (string | Array<string>);
'id'?: (string | Array<string>);
'useremployments.type'?: (string | Array<string>);
'useremployments.validFrom'?: (string | Array<string>);
'useremployments.validTo'?: (string | Array<string>);
};
};
}>> {
    const rb = new RequestBuilder(this.rootUrl, AxUsersV2Service.UsersControllerFindAllPaginatedPath, 'get');
    if (params) {
      rb.query('onlyTeamMembers', params.onlyTeamMembers, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.query('filter.businessunitId', params['filter.businessunitId'], {});
      rb.query('filter.businessdivisionId', params['filter.businessdivisionId'], {});
      rb.query('filter.active', params['filter.active'], {});
      rb.query('filter.supervisorId', params['filter.supervisorId'], {});
      rb.query('filter.id', params['filter.id'], {});
      rb.query('filter.useremployments.type', params['filter.useremployments.type'], {});
      rb.query('filter.useremployments.validFrom', params['filter.useremployments.validFrom'], {});
      rb.query('filter.useremployments.validTo', params['filter.useremployments.validTo'], {});
      rb.query('sortBy', params.sortBy, {});
      rb.query('search', params.search, {});
      rb.query('searchBy', params.searchBy, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AxPaginatedDocumented & {
        'data'?: Array<AxUser>;
        'meta'?: {
        'select'?: Array<string>;
        'filter'?: {
        'businessunitId'?: (string | Array<string>);
        'businessdivisionId'?: (string | Array<string>);
        'active'?: (string | Array<string>);
        'supervisorId'?: (string | Array<string>);
        'id'?: (string | Array<string>);
        'useremployments.type'?: (string | Array<string>);
        'useremployments.validFrom'?: (string | Array<string>);
        'useremployments.validTo'?: (string | Array<string>);
        };
        };
        }>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `usersControllerFindAllPaginated$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  usersControllerFindAllPaginated(
    params?: {
      onlyTeamMembers?: boolean;

    /**
     * Page number to retrieve.If you provide invalid value the default page number will applied
     *         <p>
     *              <b>Example: </b> 1
     *           </p>
     *         <p>
     *              <b>Default Value: </b> 1
     *           </p>
     */
      page?: number;

    /**
     * Number of records per page.
     *       <p>
     *              <b>Example: </b> 20
     *           </p>
     *       <p>
     *              <b>Default Value: </b> 25
     *           </p>
     *       <p>
     *              <b>Max Value: </b> 100
     *           </p>
     *
     *       If provided value is greater than max value, max value will be applied.
     */
      limit?: number;

    /**
     * Filter by businessunitId query param.
     *           <p>
     *              <b>Format: </b> filter.businessunitId={$not}:OPERATION:VALUE
     *           </p>
     *           <p>
     *              <b>Example: </b> filter.businessunitId=$not:$like:John Doe&filter.businessunitId=like:John
     *           </p>
     *           <h4>Available Operations</h4><ul><li>$eq</li></ul>
     */
      'filter.businessunitId'?: Array<string>;

    /**
     * Filter by businessdivisionId query param.
     *           <p>
     *              <b>Format: </b> filter.businessdivisionId={$not}:OPERATION:VALUE
     *           </p>
     *           <p>
     *              <b>Example: </b> filter.businessdivisionId=$not:$like:John Doe&filter.businessdivisionId=like:John
     *           </p>
     *           <h4>Available Operations</h4><ul><li>$eq</li></ul>
     */
      'filter.businessdivisionId'?: Array<string>;

    /**
     * Filter by active query param.
     *           <p>
     *              <b>Format: </b> filter.active={$not}:OPERATION:VALUE
     *           </p>
     *           <p>
     *              <b>Example: </b> filter.active=$not:$like:John Doe&filter.active=like:John
     *           </p>
     *           <h4>Available Operations</h4><ul><li>$eq</li></ul>
     */
      'filter.active'?: Array<string>;

    /**
     * Filter by supervisorId query param.
     *           <p>
     *              <b>Format: </b> filter.supervisorId={$not}:OPERATION:VALUE
     *           </p>
     *           <p>
     *              <b>Example: </b> filter.supervisorId=$not:$like:John Doe&filter.supervisorId=like:John
     *           </p>
     *           <h4>Available Operations</h4><ul><li>$eq</li></ul>
     */
      'filter.supervisorId'?: Array<string>;

    /**
     * Filter by id query param.
     *           <p>
     *              <b>Format: </b> filter.id={$not}:OPERATION:VALUE
     *           </p>
     *           <p>
     *              <b>Example: </b> filter.id=$not:$like:John Doe&filter.id=like:John
     *           </p>
     *           <h4>Available Operations</h4><ul><li>$eq</li></ul>
     */
      'filter.id'?: Array<string>;

    /**
     * Filter by useremployments.type query param.
     *           <p>
     *              <b>Format: </b> filter.useremployments.type={$not}:OPERATION:VALUE
     *           </p>
     *           <p>
     *              <b>Example: </b> filter.useremployments.type=$not:$like:John Doe&filter.useremployments.type=like:John
     *           </p>
     *           <h4>Available Operations</h4><ul><li>$eq</li></ul>
     */
      'filter.useremployments.type'?: Array<string>;

    /**
     * Filter by useremployments.validFrom query param.
     *           <p>
     *              <b>Format: </b> filter.useremployments.validFrom={$not}:OPERATION:VALUE
     *           </p>
     *           <p>
     *              <b>Example: </b> filter.useremployments.validFrom=$not:$like:John Doe&filter.useremployments.validFrom=like:John
     *           </p>
     *           <h4>Available Operations</h4><ul><li>$lte</li>
     * <li>$gte</li>
     * <li>$btw</li></ul>
     */
      'filter.useremployments.validFrom'?: Array<string>;

    /**
     * Filter by useremployments.validTo query param.
     *           <p>
     *              <b>Format: </b> filter.useremployments.validTo={$not}:OPERATION:VALUE
     *           </p>
     *           <p>
     *              <b>Example: </b> filter.useremployments.validTo=$not:$like:John Doe&filter.useremployments.validTo=like:John
     *           </p>
     *           <h4>Available Operations</h4><ul><li>$lte</li>
     * <li>$gte</li>
     * <li>$btw</li></ul>
     */
      'filter.useremployments.validTo'?: Array<string>;

    /**
     * Parameter to sort by.
     *       <p>To sort by multiple fields, just provide query param multiple types. The order in url defines an order of sorting</p>
     *       <p>
     *              <b>Format: </b> fieldName:DIRECTION
     *           </p>
     *       <p>
     *              <b>Example: </b> sortBy=id:DESC&sortBy=createdAt:ASC
     *           </p>
     *       <p>
     *              <b>Default Value: </b> lastName:ASC
     *           </p>
     *       <h4>Available Fields</h4><ul><li>id</li>
     * <li>lastName</li>
     * <li>birthdate</li>
     * <li>entrydate</li>
     * <li>useremployments.salaryGross</li></ul>
     */
      sortBy?: Array<'id:ASC' | 'id:DESC' | 'lastName:ASC' | 'lastName:DESC' | 'birthdate:ASC' | 'birthdate:DESC' | 'entrydate:ASC' | 'entrydate:DESC' | 'useremployments.salaryGross:ASC' | 'useremployments.salaryGross:DESC'>;

    /**
     * Search term to filter result values
     *         <p>
     *              <b>Example: </b> John
     *           </p>
     *         <p>
     *              <b>Default Value: </b> No default value
     *           </p>
     */
      search?: string;

    /**
     * List of fields to search by term to filter result values
     *         <p>
     *              <b>Example: </b> searchableFullname
     *           </p>
     *         <p>
     *              <b>Default Value: </b> By default all fields mentioned below will be used to search by term
     *           </p>
     *         <h4>Available Fields</h4><ul><li>searchableFullname</li></ul>
     */
      searchBy?: Array<string>;
    },
    context?: HttpContext
  ): Observable<AxPaginatedDocumented & {
'data'?: Array<AxUser>;
'meta'?: {
'select'?: Array<string>;
'filter'?: {
'businessunitId'?: (string | Array<string>);
'businessdivisionId'?: (string | Array<string>);
'active'?: (string | Array<string>);
'supervisorId'?: (string | Array<string>);
'id'?: (string | Array<string>);
'useremployments.type'?: (string | Array<string>);
'useremployments.validFrom'?: (string | Array<string>);
'useremployments.validTo'?: (string | Array<string>);
};
};
}> {
    return this.usersControllerFindAllPaginated$Response(params, context).pipe(
      map((r: StrictHttpResponse<AxPaginatedDocumented & {
'data'?: Array<AxUser>;
'meta'?: {
'select'?: Array<string>;
'filter'?: {
'businessunitId'?: (string | Array<string>);
'businessdivisionId'?: (string | Array<string>);
'active'?: (string | Array<string>);
'supervisorId'?: (string | Array<string>);
'id'?: (string | Array<string>);
'useremployments.type'?: (string | Array<string>);
'useremployments.validFrom'?: (string | Array<string>);
'useremployments.validTo'?: (string | Array<string>);
};
};
}>): AxPaginatedDocumented & {
'data'?: Array<AxUser>;
'meta'?: {
'select'?: Array<string>;
'filter'?: {
'businessunitId'?: (string | Array<string>);
'businessdivisionId'?: (string | Array<string>);
'active'?: (string | Array<string>);
'supervisorId'?: (string | Array<string>);
'id'?: (string | Array<string>);
'useremployments.type'?: (string | Array<string>);
'useremployments.validFrom'?: (string | Array<string>);
'useremployments.validTo'?: (string | Array<string>);
};
};
} => r.body)
    );
  }

  /** Path part for operation `usersControllerCreate()` */
  static readonly UsersControllerCreatePath = '/v2/users';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `usersControllerCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  usersControllerCreate$Response(
    params: {
      body: AxCreateUserDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AxUserV2>> {
    const rb = new RequestBuilder(this.rootUrl, AxUsersV2Service.UsersControllerCreatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AxUserV2>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `usersControllerCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  usersControllerCreate(
    params: {
      body: AxCreateUserDto
    },
    context?: HttpContext
  ): Observable<AxUserV2> {
    return this.usersControllerCreate$Response(params, context).pipe(
      map((r: StrictHttpResponse<AxUserV2>): AxUserV2 => r.body)
    );
  }

  /** Path part for operation `usersControllerFindOneById()` */
  static readonly UsersControllerFindOneByIdPath = '/v2/users/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `usersControllerFindOneById()` instead.
   *
   * This method doesn't expect any request body.
   */
  usersControllerFindOneById$Response(
    params: {
      id: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AxUserV2>> {
    const rb = new RequestBuilder(this.rootUrl, AxUsersV2Service.UsersControllerFindOneByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AxUserV2>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `usersControllerFindOneById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  usersControllerFindOneById(
    params: {
      id: number;
    },
    context?: HttpContext
  ): Observable<AxUserV2> {
    return this.usersControllerFindOneById$Response(params, context).pipe(
      map((r: StrictHttpResponse<AxUserV2>): AxUserV2 => r.body)
    );
  }

  /** Path part for operation `usersControllerUpdate()` */
  static readonly UsersControllerUpdatePath = '/v2/users/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `usersControllerUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  usersControllerUpdate$Response(
    params: {
      id: number;
      body: AxUpdateUserDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, AxUsersV2Service.UsersControllerUpdatePath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `usersControllerUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  usersControllerUpdate(
    params: {
      id: number;
      body: AxUpdateUserDto
    },
    context?: HttpContext
  ): Observable<void> {
    return this.usersControllerUpdate$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `usersControllerFindTeamForUserId()` */
  static readonly UsersControllerFindTeamForUserIdPath = '/v2/users/{id}/team';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `usersControllerFindTeamForUserId()` instead.
   *
   * This method doesn't expect any request body.
   */
  usersControllerFindTeamForUserId$Response(
    params: {
      id: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<AxUserV2>>> {
    const rb = new RequestBuilder(this.rootUrl, AxUsersV2Service.UsersControllerFindTeamForUserIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AxUserV2>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `usersControllerFindTeamForUserId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  usersControllerFindTeamForUserId(
    params: {
      id: number;
    },
    context?: HttpContext
  ): Observable<Array<AxUserV2>> {
    return this.usersControllerFindTeamForUserId$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<AxUserV2>>): Array<AxUserV2> => r.body)
    );
  }

  /** Path part for operation `usersControllerGetAllGroupedForUser()` */
  static readonly UsersControllerGetAllGroupedForUserPath = '/v2/users/{id}/getAllGroupedForUser';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `usersControllerGetAllGroupedForUser()` instead.
   *
   * This method doesn't expect any request body.
   */
  usersControllerGetAllGroupedForUser$Response(
    params: {
      id: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AxUsersGroupedForUserDto>> {
    const rb = new RequestBuilder(this.rootUrl, AxUsersV2Service.UsersControllerGetAllGroupedForUserPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AxUsersGroupedForUserDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `usersControllerGetAllGroupedForUser$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  usersControllerGetAllGroupedForUser(
    params: {
      id: number;
    },
    context?: HttpContext
  ): Observable<AxUsersGroupedForUserDto> {
    return this.usersControllerGetAllGroupedForUser$Response(params, context).pipe(
      map((r: StrictHttpResponse<AxUsersGroupedForUserDto>): AxUsersGroupedForUserDto => r.body)
    );
  }

  /** Path part for operation `usersControllerFindRoletypesById()` */
  static readonly UsersControllerFindRoletypesByIdPath = '/v2/users/{id}/roletypes';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `usersControllerFindRoletypesById()` instead.
   *
   * This method doesn't expect any request body.
   */
  usersControllerFindRoletypesById$Response(
    params: {
      id: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<AxRoletype>>> {
    const rb = new RequestBuilder(this.rootUrl, AxUsersV2Service.UsersControllerFindRoletypesByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AxRoletype>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `usersControllerFindRoletypesById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  usersControllerFindRoletypesById(
    params: {
      id: number;
    },
    context?: HttpContext
  ): Observable<Array<AxRoletype>> {
    return this.usersControllerFindRoletypesById$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<AxRoletype>>): Array<AxRoletype> => r.body)
    );
  }

  /** Path part for operation `usersControllerAddRoletypeToUser()` */
  static readonly UsersControllerAddRoletypeToUserPath = '/v2/users/{id}/roletypes';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `usersControllerAddRoletypeToUser()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  usersControllerAddRoletypeToUser$Response(
    params: {
      id: number;
      body: AxAddRoletypeDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AxSuccessDto>> {
    const rb = new RequestBuilder(this.rootUrl, AxUsersV2Service.UsersControllerAddRoletypeToUserPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AxSuccessDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `usersControllerAddRoletypeToUser$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  usersControllerAddRoletypeToUser(
    params: {
      id: number;
      body: AxAddRoletypeDto
    },
    context?: HttpContext
  ): Observable<AxSuccessDto> {
    return this.usersControllerAddRoletypeToUser$Response(params, context).pipe(
      map((r: StrictHttpResponse<AxSuccessDto>): AxSuccessDto => r.body)
    );
  }

  /** Path part for operation `usersControllerRemoveRoletypeFromUser()` */
  static readonly UsersControllerRemoveRoletypeFromUserPath = '/v2/users/{id}/roletypes';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `usersControllerRemoveRoletypeFromUser()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  usersControllerRemoveRoletypeFromUser$Response(
    params: {
      id: number;
      body: AxRemoveRoletypeDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AxSuccessDto>> {
    const rb = new RequestBuilder(this.rootUrl, AxUsersV2Service.UsersControllerRemoveRoletypeFromUserPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AxSuccessDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `usersControllerRemoveRoletypeFromUser$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  usersControllerRemoveRoletypeFromUser(
    params: {
      id: number;
      body: AxRemoveRoletypeDto
    },
    context?: HttpContext
  ): Observable<AxSuccessDto> {
    return this.usersControllerRemoveRoletypeFromUser$Response(params, context).pipe(
      map((r: StrictHttpResponse<AxSuccessDto>): AxSuccessDto => r.body)
    );
  }

  /** Path part for operation `usersControllerFindTagsById()` */
  static readonly UsersControllerFindTagsByIdPath = '/v2/users/{id}/tags';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `usersControllerFindTagsById()` instead.
   *
   * This method doesn't expect any request body.
   */
  usersControllerFindTagsById$Response(
    params: {
      id: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<AxTag>>> {
    const rb = new RequestBuilder(this.rootUrl, AxUsersV2Service.UsersControllerFindTagsByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AxTag>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `usersControllerFindTagsById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  usersControllerFindTagsById(
    params: {
      id: number;
    },
    context?: HttpContext
  ): Observable<Array<AxTag>> {
    return this.usersControllerFindTagsById$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<AxTag>>): Array<AxTag> => r.body)
    );
  }

  /** Path part for operation `usersControllerAddTagToUser()` */
  static readonly UsersControllerAddTagToUserPath = '/v2/users/{id}/tags/{tagId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `usersControllerAddTagToUser()` instead.
   *
   * This method doesn't expect any request body.
   */
  usersControllerAddTagToUser$Response(
    params: {
      id: number;
      tagId: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AxSuccessDto>> {
    const rb = new RequestBuilder(this.rootUrl, AxUsersV2Service.UsersControllerAddTagToUserPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('tagId', params.tagId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AxSuccessDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `usersControllerAddTagToUser$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  usersControllerAddTagToUser(
    params: {
      id: number;
      tagId: number;
    },
    context?: HttpContext
  ): Observable<AxSuccessDto> {
    return this.usersControllerAddTagToUser$Response(params, context).pipe(
      map((r: StrictHttpResponse<AxSuccessDto>): AxSuccessDto => r.body)
    );
  }

  /** Path part for operation `usersControllerRemoveTagFromUser()` */
  static readonly UsersControllerRemoveTagFromUserPath = '/v2/users/{id}/tags/{tagId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `usersControllerRemoveTagFromUser()` instead.
   *
   * This method doesn't expect any request body.
   */
  usersControllerRemoveTagFromUser$Response(
    params: {
      id: number;
      tagId: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AxSuccessDto>> {
    const rb = new RequestBuilder(this.rootUrl, AxUsersV2Service.UsersControllerRemoveTagFromUserPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('tagId', params.tagId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AxSuccessDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `usersControllerRemoveTagFromUser$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  usersControllerRemoveTagFromUser(
    params: {
      id: number;
      tagId: number;
    },
    context?: HttpContext
  ): Observable<AxSuccessDto> {
    return this.usersControllerRemoveTagFromUser$Response(params, context).pipe(
      map((r: StrictHttpResponse<AxSuccessDto>): AxSuccessDto => r.body)
    );
  }

}
