
import { FormGroup, FormControl, FormArray, Validators } from '@angular/forms';


export function getCreateInteractionTaskDtoFormGroup() {
return new FormGroup({
    interactiontypeId: new FormControl<number | undefined>(3, [Validators.required]),
    containerId: new FormControl<number | undefined>(undefined),
    assigneeId: new FormControl<number | undefined>(undefined, [Validators.required]),
    subject: new FormControl<string | undefined>('', [Validators.required]),
    notes: new FormControl<string | undefined>(undefined),
    duetime: new FormControl<string | undefined>(undefined, [Validators.required]),
    relationType: new FormControl<string | undefined>('', [Validators.required]),
    relationTypeId: new FormControl<number | undefined>(undefined, [Validators.required]),
})
;
}
      