import { Pipe, PipeTransform } from '@angular/core';
import { AxEquipmenttype } from 'src/app/_generated/axova-rest-api';

@Pipe({ name: 'getIconForEquipmenttype' })
export class AxApiGetIconForEquipmenttypePipe implements PipeTransform {
  transform(equipmenttype: AxEquipmenttype) {
    switch (equipmenttype.id) {
      case 1:
        return 'assets/svg-icons/car.svg';
      case 2:
        return 'assets/svg-icons/tools.svg';
      case 3:
        return 'assets/svg-icons/key.svg';
      case 4:
        return 'assets/svg-icons/accessory.svg';
      case 5:
        return 'assets/svg-icons/trailer.svg';
      case 6:
        return 'assets/svg-icons/vignette.svg';
      case 7:
        return 'assets/svg-icons/card.svg';
      default:
        return 'assets/svg-icons/accessory.svg';
    }
  }
}
