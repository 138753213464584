
import { FormGroup, FormControl, FormArray, Validators } from '@angular/forms';


export function getAuthenticatorFormGroup() {
return new FormGroup({
    id: new FormControl<number | undefined>(undefined, [Validators.required]),
    name: new FormControl<string | undefined>(undefined),
    phoneCompany: new FormControl<string | undefined>(undefined),
    mailCompany: new FormControl<string | undefined>(undefined),
    contactPerson: new FormControl<string | undefined>(undefined),
    active: new FormControl<boolean | undefined>(undefined),
    created: new FormControl<string | undefined>(undefined),
    modified: new FormControl<string | undefined>(undefined),
    createdBy: new FormControl<number | undefined>(undefined),
    modifiedBy: new FormControl<number | undefined>(undefined),
})
;
}
      