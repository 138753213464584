import { AxEquipmentlog } from '../_generated/axova-rest-api';
import * as moment from 'moment';

export const getEquipmentlogStatusTextFunction = (equipmentlog: AxEquipmentlog): string => {
  const statusTextForTasks =
    equipmentlog.statusId === 15
      ? ' zu erledigen'
      : equipmentlog.statusId === 16
        ? ' erledigt'
        : '';
  const date = equipmentlog.created ? moment(equipmentlog.created).format('DD.MM.YYYY HH:mm') : '';
  const createdBy = `${equipmentlog.createdByUser.firstName} ${equipmentlog.createdByUser.lastName}`;
  const dateText = statusTextForTasks ? '' : ` am ${date}`;

  return `${equipmentlog.equipmentlogtype.name}${statusTextForTasks}${dateText} durch ${createdBy}`;
};
