import { Injectable } from '@angular/core';
import { LoadingController, ModalController, ToastController } from '@ionic/angular';
import { LoggerService } from 'src/app/ax-commons-logger/logger/logger.service';
import { InfoModalPage } from 'src/app/pages/global/info-modal/info-modal.page';

@Injectable({
  providedIn: 'root',
})
export class AxUiService {
  private loadingOverlay: HTMLIonLoadingElement;
  private modal: HTMLIonModalElement;
  private toastErrors = [];

  constructor(
    private toastController: ToastController,
    private loadingController: LoadingController,
    private modalController: ModalController,
  ) {
  }

  /**
   * Show an error message as toast.
   *
   * @param errorMessage
   * @param errorTitle
   */
  public async showError(errorMessage: string, errorTitle?: string) {
    LoggerService.ERROR(this, 'showError', errorMessage);
    const toast = await this.toastController.create({
      header: errorMessage,
      message: errorTitle || 'Fehler',
      duration: 5000,
      color: 'danger',
      icon: 'warning-outline',
      position: 'top',
      buttons: [
        {
          text: 'OK',
          role: 'cancel',
        },
      ],
    });
    this.toastErrors.push(toast);
    toast.present().then();
  }

  /**
   * Show a message as toast.
   *
   * @param message
   * @param title
   */
  public async showHint(message: string, title?: string) {
    LoggerService.LOG(this, 'showMessage():', message);
    const toast = await this.toastController.create({
      header: message,
      message: title || null,
      duration: 5000,
      color: 'warning',
      icon: 'information-circle-outline',
      position: 'top',
      buttons: [
        {
          text: 'OK',
          role: 'cancel',
        },
      ],
    });
    toast.present().then();
  }

  /**
   * Show a success message as toast.
   *
   * @param message
   */
  public async showSuccess(message: string) {
    LoggerService.LOG(this, 'showSuccess():', message);
    const toast = await this.toastController.create({
      message,
      duration: 5000,
      color: 'success',
      icon: 'checkmark-circle-outline',
      position: 'top',
      buttons: [
        {
          text: 'OK',
          role: 'cancel',
        },
      ],
    });
    toast.present().then();
  }

  /**
   * Show a message as info modal.
   *
   * @param title
   * @param subtitle
   * @param message
   * @param button
   */
  public async showInfoModal(title: string, subtitle: string, message: string, button: string) {
    LoggerService.LOG(this, 'showModal():', message);
    this.modal = await this.modalController.create({
      component: InfoModalPage,
      componentProps: {
        infoModalData: {
          title,
          subtitle,
          message,
          button,
        },
      },
    });
    this.modal.present().then();
  }

  /**
   * Closes the currently opened info modal.
   */
  public async closeInfoModal() {
    try {
      this.modal.dismiss().then();
    } catch (noModalDismissableException) {
      LoggerService.LOG(this, 'noModalDismissableException', noModalDismissableException);
    }
  }

  /**
   * Show the global loading spinner.
   */
  public async showLoading(message: string = '') {
    if (message) {
      this.loadingOverlay = await this.loadingController.create({
        message: message ? message : null,
      });
    } else {
      this.loadingOverlay = await this.loadingController.create();
    }
    return this.loadingOverlay.present();
  }

  public async setMessage(message: string) {
    this.loadingOverlay.message = message;
  }

  /**
   * Hide the global loading spinner.
   */
  public async hideLoading() {
    if (this.loadingOverlay) {
      return this.loadingOverlay.dismiss();
    }
    return Promise.resolve();
  }

  /**
   * Clear all existing toast errors.
   */
  public clearToastErrors() {
    for (let i = 0; i < this.toastErrors.length; i++) {
      const toast = this.toastErrors[i];
      try {
        toast.dismiss();
        delete this.toastErrors[i];
      } catch (toastDismissException) {
        LoggerService.ERROR(this, 'toastDismissException', toastDismissException);
      }
    }
  }
}
