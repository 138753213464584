import { AxElectricalMeasurement, AxProject } from 'src/app/_generated/axova-rest-api';

export class CreateElectricalMeasurementSetProject {
  static readonly type = '[create-electrical-measurement] Set project';

  constructor(public project: AxProject) {
  }
}

export class CreateElectricalMeasurementSetBase {
  static readonly type = '[create-electrical-measurement] Set base';

  constructor(public electricalMeasurement: Partial<AxElectricalMeasurement>) {
  }
}

export class CreateElectricalMeasurementAddMeasurement {
  static readonly type = '[create-electrical-measurement] Add new measurement';

  constructor(public electricalMeasurement: Partial<AxElectricalMeasurement>) {
  }
}

export class CreateElectricalMeasurementUpdateMeasurementAtIndex {
  static readonly type = '[create-electrical-measurement] Update measurement at index';

  constructor(public electricalMeasurement: Partial<AxElectricalMeasurement>, public indexOfMeasurement: number) {
  }
}

export class CreateElectricalMeasurementDeleteMeasurementAtIndex {
  static readonly type = '[create-electrical-measurement] Delete measurement at index';

  constructor(public indexOfMeasurement: number) {
  }
}

export class CreateElectricalMeasurementClear {
  static readonly type = '[create-electrical-measurement] Clear';
}
