import { Component, Input, OnInit } from '@angular/core';
import { AxProject } from 'src/app/_generated/axova-rest-api';

@Component({
  selector: 'ax-project-contact-persons',
  templateUrl: './ax-project-contact-persons.component.html',
  styleUrls: ['./ax-project-contact-persons.component.scss'],
})
export class AxProjectContactPersonsComponent implements OnInit {
  @Input() project: AxProject;
  @Input() isInternalView: boolean = false;
  public accordionState: any = false;
  constructor() {
  }

  ngOnInit() {
  }
}
