import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { AppState } from 'src/app/ngxs/app/app.state';
import { RolesEnum } from 'src/app/ngxs/app/app.state.model';

@Injectable({
  providedIn: 'root',
})
export class AxIsEmployeeGuard implements CanActivate {
  constructor(
    private store: Store,
    private router: Router,
  ) {
  }

  /**
   * Checks if the user is logged in or not.
   *
   * @param next
   * @param state
   */
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    const profile = this.store.selectSnapshot(AppState.profile);
    if (profile.roles.includes(RolesEnum.kunde) && profile.isContactEntity) {
      this.router.navigate(['/customers-tabs/home']).then();
      return false;
    } else if (!profile.isUserEntity || profile.roles.includes(RolesEnum.kunde)) {
      this.router.navigate(['/']).then();
      return false;
    }
    return true;
  }
}
