/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { AxCreateTaskDto } from '../models/ax-create-task-dto';
import { AxPaginatedDocumented } from '../models/ax-paginated-documented';
import { AxTask } from '../models/ax-task';
import { AxUpdateTaskDto } from '../models/ax-update-task-dto';
import { AxUpdateTaskSortableRankDto } from '../models/ax-update-task-sortable-rank-dto';

@Injectable({ providedIn: 'root' })
export class AxTasksV2Service extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `tasksControllerCreate()` */
  static readonly TasksControllerCreatePath = '/v2/tasks';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `tasksControllerCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  tasksControllerCreate$Response(
    params: {
      body: AxCreateTaskDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AxTask>> {
    const rb = new RequestBuilder(this.rootUrl, AxTasksV2Service.TasksControllerCreatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AxTask>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `tasksControllerCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  tasksControllerCreate(
    params: {
      body: AxCreateTaskDto
    },
    context?: HttpContext
  ): Observable<AxTask> {
    return this.tasksControllerCreate$Response(params, context).pipe(
      map((r: StrictHttpResponse<AxTask>): AxTask => r.body)
    );
  }

  /** Path part for operation `tasksControllerDelete()` */
  static readonly TasksControllerDeletePath = '/v2/tasks/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `tasksControllerDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  tasksControllerDelete$Response(
    params: {
      id: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AxTask>> {
    const rb = new RequestBuilder(this.rootUrl, AxTasksV2Service.TasksControllerDeletePath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AxTask>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `tasksControllerDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  tasksControllerDelete(
    params: {
      id: number;
    },
    context?: HttpContext
  ): Observable<AxTask> {
    return this.tasksControllerDelete$Response(params, context).pipe(
      map((r: StrictHttpResponse<AxTask>): AxTask => r.body)
    );
  }

  /** Path part for operation `tasksControllerUpdate()` */
  static readonly TasksControllerUpdatePath = '/v2/tasks/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `tasksControllerUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  tasksControllerUpdate$Response(
    params: {
      id: number;
      body: AxUpdateTaskDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AxTask>> {
    const rb = new RequestBuilder(this.rootUrl, AxTasksV2Service.TasksControllerUpdatePath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AxTask>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `tasksControllerUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  tasksControllerUpdate(
    params: {
      id: number;
      body: AxUpdateTaskDto
    },
    context?: HttpContext
  ): Observable<AxTask> {
    return this.tasksControllerUpdate$Response(params, context).pipe(
      map((r: StrictHttpResponse<AxTask>): AxTask => r.body)
    );
  }

  /** Path part for operation `tasksControllerUpdateSortableRankForTaskGroup()` */
  static readonly TasksControllerUpdateSortableRankForTaskGroupPath = '/v2/tasks/tasks/sortableRanks';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `tasksControllerUpdateSortableRankForTaskGroup()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  tasksControllerUpdateSortableRankForTaskGroup$Response(
    params: {
      body: Array<AxUpdateTaskSortableRankDto>
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<boolean>> {
    const rb = new RequestBuilder(this.rootUrl, AxTasksV2Service.TasksControllerUpdateSortableRankForTaskGroupPath, 'patch');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `tasksControllerUpdateSortableRankForTaskGroup$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  tasksControllerUpdateSortableRankForTaskGroup(
    params: {
      body: Array<AxUpdateTaskSortableRankDto>
    },
    context?: HttpContext
  ): Observable<boolean> {
    return this.tasksControllerUpdateSortableRankForTaskGroup$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `tasksControllerFindAllAppointmentTasksForProjectIdPaginated()` */
  static readonly TasksControllerFindAllAppointmentTasksForProjectIdPaginatedPath = '/v2/tasks/appointments/forProjectId/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `tasksControllerFindAllAppointmentTasksForProjectIdPaginated()` instead.
   *
   * This method doesn't expect any request body.
   */
  tasksControllerFindAllAppointmentTasksForProjectIdPaginated$Response(
    params: {
      id: number;

    /**
     * Page number to retrieve.If you provide invalid value the default page number will applied
     *         <p>
     *              <b>Example: </b> 1
     *           </p>
     *         <p>
     *              <b>Default Value: </b> 1
     *           </p>
     */
      page?: number;

    /**
     * Number of records per page.
     *       <p>
     *              <b>Example: </b> 20
     *           </p>
     *       <p>
     *              <b>Default Value: </b> 100
     *           </p>
     *       <p>
     *              <b>Max Value: </b> 100
     *           </p>
     *
     *       If provided value is greater than max value, max value will be applied.
     */
      limit?: number;

    /**
     * Parameter to sort by.
     *       <p>To sort by multiple fields, just provide query param multiple types. The order in url defines an order of sorting</p>
     *       <p>
     *              <b>Format: </b> fieldName:DIRECTION
     *           </p>
     *       <p>
     *              <b>Example: </b> sortBy=id:DESC&sortBy=createdAt:ASC
     *           </p>
     *       <p>
     *              <b>Default Value: </b> mandatory:DESC,sortableRank:DESC,start:ASC
     *           </p>
     *       <h4>Available Fields</h4><ul><li>id</li>
     * <li>start</li>
     * <li>end</li>
     * <li>status</li>
     * <li>team.name</li></ul>
     */
      sortBy?: Array<'id:ASC' | 'id:DESC' | 'start:ASC' | 'start:DESC' | 'end:ASC' | 'end:DESC' | 'status:ASC' | 'status:DESC' | 'team.name:ASC' | 'team.name:DESC'>;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AxPaginatedDocumented & {
'data'?: Array<AxTask>;
'meta'?: {
'select'?: Array<string>;
'filter'?: {
};
};
}>> {
    const rb = new RequestBuilder(this.rootUrl, AxTasksV2Service.TasksControllerFindAllAppointmentTasksForProjectIdPaginatedPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.query('sortBy', params.sortBy, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AxPaginatedDocumented & {
        'data'?: Array<AxTask>;
        'meta'?: {
        'select'?: Array<string>;
        'filter'?: {
        };
        };
        }>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `tasksControllerFindAllAppointmentTasksForProjectIdPaginated$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  tasksControllerFindAllAppointmentTasksForProjectIdPaginated(
    params: {
      id: number;

    /**
     * Page number to retrieve.If you provide invalid value the default page number will applied
     *         <p>
     *              <b>Example: </b> 1
     *           </p>
     *         <p>
     *              <b>Default Value: </b> 1
     *           </p>
     */
      page?: number;

    /**
     * Number of records per page.
     *       <p>
     *              <b>Example: </b> 20
     *           </p>
     *       <p>
     *              <b>Default Value: </b> 100
     *           </p>
     *       <p>
     *              <b>Max Value: </b> 100
     *           </p>
     *
     *       If provided value is greater than max value, max value will be applied.
     */
      limit?: number;

    /**
     * Parameter to sort by.
     *       <p>To sort by multiple fields, just provide query param multiple types. The order in url defines an order of sorting</p>
     *       <p>
     *              <b>Format: </b> fieldName:DIRECTION
     *           </p>
     *       <p>
     *              <b>Example: </b> sortBy=id:DESC&sortBy=createdAt:ASC
     *           </p>
     *       <p>
     *              <b>Default Value: </b> mandatory:DESC,sortableRank:DESC,start:ASC
     *           </p>
     *       <h4>Available Fields</h4><ul><li>id</li>
     * <li>start</li>
     * <li>end</li>
     * <li>status</li>
     * <li>team.name</li></ul>
     */
      sortBy?: Array<'id:ASC' | 'id:DESC' | 'start:ASC' | 'start:DESC' | 'end:ASC' | 'end:DESC' | 'status:ASC' | 'status:DESC' | 'team.name:ASC' | 'team.name:DESC'>;
    },
    context?: HttpContext
  ): Observable<AxPaginatedDocumented & {
'data'?: Array<AxTask>;
'meta'?: {
'select'?: Array<string>;
'filter'?: {
};
};
}> {
    return this.tasksControllerFindAllAppointmentTasksForProjectIdPaginated$Response(params, context).pipe(
      map((r: StrictHttpResponse<AxPaginatedDocumented & {
'data'?: Array<AxTask>;
'meta'?: {
'select'?: Array<string>;
'filter'?: {
};
};
}>): AxPaginatedDocumented & {
'data'?: Array<AxTask>;
'meta'?: {
'select'?: Array<string>;
'filter'?: {
};
};
} => r.body)
    );
  }

  /** Path part for operation `tasksControllerFindAllAufgabenTasksForProjectIdPaginated()` */
  static readonly TasksControllerFindAllAufgabenTasksForProjectIdPaginatedPath = '/v2/tasks/tasks/forProjectId/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `tasksControllerFindAllAufgabenTasksForProjectIdPaginated()` instead.
   *
   * This method doesn't expect any request body.
   */
  tasksControllerFindAllAufgabenTasksForProjectIdPaginated$Response(
    params: {
      id: number;

    /**
     * Page number to retrieve.If you provide invalid value the default page number will applied
     *         <p>
     *              <b>Example: </b> 1
     *           </p>
     *         <p>
     *              <b>Default Value: </b> 1
     *           </p>
     */
      page?: number;

    /**
     * Number of records per page.
     *       <p>
     *              <b>Example: </b> 20
     *           </p>
     *       <p>
     *              <b>Default Value: </b> 100
     *           </p>
     *       <p>
     *              <b>Max Value: </b> 100
     *           </p>
     *
     *       If provided value is greater than max value, max value will be applied.
     */
      limit?: number;

    /**
     * Filter by userId query param.
     *           <p>
     *              <b>Format: </b> filter.userId={$not}:OPERATION:VALUE
     *           </p>
     *           <p>
     *              <b>Example: </b> filter.userId=$not:$like:John Doe&filter.userId=like:John
     *           </p>
     *           <h4>Available Operations</h4><ul><li>$eq</li></ul>
     */
      'filter.userId'?: Array<string>;

    /**
     * Filter by end query param.
     *           <p>
     *              <b>Format: </b> filter.end={$not}:OPERATION:VALUE
     *           </p>
     *           <p>
     *              <b>Example: </b> filter.end=$not:$like:John Doe&filter.end=like:John
     *           </p>
     *           <h4>Available Operations</h4><ul><li>$null</li>
     * <li>$not</li></ul>
     */
      'filter.end'?: Array<string>;

    /**
     * Filter by mandatory query param.
     *           <p>
     *              <b>Format: </b> filter.mandatory={$not}:OPERATION:VALUE
     *           </p>
     *           <p>
     *              <b>Example: </b> filter.mandatory=$not:$like:John Doe&filter.mandatory=like:John
     *           </p>
     *           <h4>Available Operations</h4><ul><li>$eq</li></ul>
     */
      'filter.mandatory'?: Array<string>;

    /**
     * Parameter to sort by.
     *       <p>To sort by multiple fields, just provide query param multiple types. The order in url defines an order of sorting</p>
     *       <p>
     *              <b>Format: </b> fieldName:DIRECTION
     *           </p>
     *       <p>
     *              <b>Example: </b> sortBy=id:DESC&sortBy=createdAt:ASC
     *           </p>
     *       <p>
     *              <b>Default Value: </b> mandatory:DESC,sortableRank:ASC
     *           </p>
     *       <h4>Available Fields</h4><ul><li>user.lastName</li>
     * <li>due</li>
     * <li>start</li>
     * <li>end</li></ul>
     */
      sortBy?: Array<'user.lastName:ASC' | 'user.lastName:DESC' | 'due:ASC' | 'due:DESC' | 'start:ASC' | 'start:DESC' | 'end:ASC' | 'end:DESC'>;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AxPaginatedDocumented & {
'data'?: Array<AxTask>;
'meta'?: {
'select'?: Array<string>;
'filter'?: {
'userId'?: (string | Array<string>);
'end'?: (string | Array<string>);
'mandatory'?: (string | Array<string>);
};
};
}>> {
    const rb = new RequestBuilder(this.rootUrl, AxTasksV2Service.TasksControllerFindAllAufgabenTasksForProjectIdPaginatedPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.query('filter.userId', params['filter.userId'], {});
      rb.query('filter.end', params['filter.end'], {});
      rb.query('filter.mandatory', params['filter.mandatory'], {});
      rb.query('sortBy', params.sortBy, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AxPaginatedDocumented & {
        'data'?: Array<AxTask>;
        'meta'?: {
        'select'?: Array<string>;
        'filter'?: {
        'userId'?: (string | Array<string>);
        'end'?: (string | Array<string>);
        'mandatory'?: (string | Array<string>);
        };
        };
        }>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `tasksControllerFindAllAufgabenTasksForProjectIdPaginated$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  tasksControllerFindAllAufgabenTasksForProjectIdPaginated(
    params: {
      id: number;

    /**
     * Page number to retrieve.If you provide invalid value the default page number will applied
     *         <p>
     *              <b>Example: </b> 1
     *           </p>
     *         <p>
     *              <b>Default Value: </b> 1
     *           </p>
     */
      page?: number;

    /**
     * Number of records per page.
     *       <p>
     *              <b>Example: </b> 20
     *           </p>
     *       <p>
     *              <b>Default Value: </b> 100
     *           </p>
     *       <p>
     *              <b>Max Value: </b> 100
     *           </p>
     *
     *       If provided value is greater than max value, max value will be applied.
     */
      limit?: number;

    /**
     * Filter by userId query param.
     *           <p>
     *              <b>Format: </b> filter.userId={$not}:OPERATION:VALUE
     *           </p>
     *           <p>
     *              <b>Example: </b> filter.userId=$not:$like:John Doe&filter.userId=like:John
     *           </p>
     *           <h4>Available Operations</h4><ul><li>$eq</li></ul>
     */
      'filter.userId'?: Array<string>;

    /**
     * Filter by end query param.
     *           <p>
     *              <b>Format: </b> filter.end={$not}:OPERATION:VALUE
     *           </p>
     *           <p>
     *              <b>Example: </b> filter.end=$not:$like:John Doe&filter.end=like:John
     *           </p>
     *           <h4>Available Operations</h4><ul><li>$null</li>
     * <li>$not</li></ul>
     */
      'filter.end'?: Array<string>;

    /**
     * Filter by mandatory query param.
     *           <p>
     *              <b>Format: </b> filter.mandatory={$not}:OPERATION:VALUE
     *           </p>
     *           <p>
     *              <b>Example: </b> filter.mandatory=$not:$like:John Doe&filter.mandatory=like:John
     *           </p>
     *           <h4>Available Operations</h4><ul><li>$eq</li></ul>
     */
      'filter.mandatory'?: Array<string>;

    /**
     * Parameter to sort by.
     *       <p>To sort by multiple fields, just provide query param multiple types. The order in url defines an order of sorting</p>
     *       <p>
     *              <b>Format: </b> fieldName:DIRECTION
     *           </p>
     *       <p>
     *              <b>Example: </b> sortBy=id:DESC&sortBy=createdAt:ASC
     *           </p>
     *       <p>
     *              <b>Default Value: </b> mandatory:DESC,sortableRank:ASC
     *           </p>
     *       <h4>Available Fields</h4><ul><li>user.lastName</li>
     * <li>due</li>
     * <li>start</li>
     * <li>end</li></ul>
     */
      sortBy?: Array<'user.lastName:ASC' | 'user.lastName:DESC' | 'due:ASC' | 'due:DESC' | 'start:ASC' | 'start:DESC' | 'end:ASC' | 'end:DESC'>;
    },
    context?: HttpContext
  ): Observable<AxPaginatedDocumented & {
'data'?: Array<AxTask>;
'meta'?: {
'select'?: Array<string>;
'filter'?: {
'userId'?: (string | Array<string>);
'end'?: (string | Array<string>);
'mandatory'?: (string | Array<string>);
};
};
}> {
    return this.tasksControllerFindAllAufgabenTasksForProjectIdPaginated$Response(params, context).pipe(
      map((r: StrictHttpResponse<AxPaginatedDocumented & {
'data'?: Array<AxTask>;
'meta'?: {
'select'?: Array<string>;
'filter'?: {
'userId'?: (string | Array<string>);
'end'?: (string | Array<string>);
'mandatory'?: (string | Array<string>);
};
};
}>): AxPaginatedDocumented & {
'data'?: Array<AxTask>;
'meta'?: {
'select'?: Array<string>;
'filter'?: {
'userId'?: (string | Array<string>);
'end'?: (string | Array<string>);
'mandatory'?: (string | Array<string>);
};
};
} => r.body)
    );
  }

}
