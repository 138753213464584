import { GLOBALS } from './global.environment';

export const environment = GLOBALS;

// Overwrite settings for this environment.
environment.name = 'test';
environment.apiConfiguration = {
  baseUrl: 'https://test-api.axova.cloud',
};
environment.axovaOffice.baseUrl = 'test-office.axova.cloud';
environment.isLoggingEnabled.ngxs = true;
environment.isLoggingEnabled.logger = true;
environment.production = false;
