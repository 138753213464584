import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { UpdateUserDataFormPage } from 'src/app/pages/global/update-user-data-form/update-user-data-form.page';

const routes: Routes = [
  {
    path: '',
    component: UpdateUserDataFormPage,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class UpdateUserDataFormPageRoutingModule {
}
