import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { Browser } from '@capacitor/browser';
import { NavController } from '@ionic/angular';
import * as moment from 'moment/moment';
import {
  AxCreateEventRegistrationSelectedOptionDto,
  AxDocument,
  AxEvent,
  AxEventInvitation,
  AxEventInvitationsService,
  AxEventRegistrationOptionGroup,
  AxMyService,
  AxNews,
} from 'src/app/_generated/axova-rest-api';
import { LoggerService } from 'src/app/ax-commons-logger/logger/logger.service';
import { AxUiService } from 'src/app/services/ax-ui.service';
import { RolesEnum, RolesEnumGroups } from '../../../ngxs/app/app.state.model';
import { Observable } from 'rxjs';
import { AppState } from '../../../ngxs/app/app.state';
import { Store } from '@ngxs/store';
import { PhotoViewerService } from '../../../services/photo-viewer.service';
import { DocumentsService } from '../../../services/documents.service';

@Component({
  selector: 'app-event-detail',
  templateUrl: './event-detail.page.html',
  styleUrls: ['./event-detail.page.scss'],
})
export class EventDetailPage implements OnInit {
  public eventItem: AxEvent;
  public eventInvitation: AxEventInvitation;
  public publicEventInvitations: AxEventInvitation[] = [];
  public participate = null;
  public hasInvitationExpired = false;
  public eventRegistrationSelectedOptions: AxCreateEventRegistrationSelectedOptionDto[] = [];
  public accordionStates: any = {};
  public news: AxNews | undefined;
  public isAbStufeAbteilungsleiter = false;
  public roles: Observable<string[]> = this.store.select(AppState.roles);

  constructor(
    private axMyService: AxMyService,
    private axEventInvitationsService: AxEventInvitationsService,
    private activatedRoute: ActivatedRoute,
    private title: Title,
    private axUiService: AxUiService,
    private navController: NavController,
    private router: Router,
    private store: Store,
    private photoViewerService: PhotoViewerService,
    private documentsService: DocumentsService,
  ) {
  }

  ngOnInit() {
    this.activatedRoute.params.subscribe(data => {
      this.load(data.id);
    });

    this.roles.subscribe(roles => {
      const isAbStufeAbteilungsleiter = () =>
        roles.some((role: RolesEnum) =>
          RolesEnumGroups.ab_stufe_abteilungsleiter.includes(role),
        );
      this.isAbStufeAbteilungsleiter = isAbStufeAbteilungsleiter();
    });
  }

  public load(eventId: number) {
    this.axMyService.myEventsControllerFindById({ id: eventId })
      .subscribe(eventItem => {
        try {
          eventItem.description = this.removeTargetAttributes(eventItem.description);
        } catch (noDescription) {
        }
        this.eventItem = eventItem;
        this.setRegistrationExpiration();
        this.loadPublicInvitations(eventItem);
        this.loadNews(eventItem);
        this.loadInvitation();
      });
  }

  /**
   * Open google maps link, which will automatically open the App if installed.
   */
  public openGoogleMaps(): void {
    let addressString = `${this.eventItem.address ? this.eventItem.address : this.eventItem.location}`;
    addressString = encodeURI(addressString);
    window.open(`https://www.google.com/maps/search/?api=1&query=${addressString}`, '_blank');
  }

  public openLink(link: string): void {
    Browser.open({ url: link });
  }

  public openUserDetails(userId: number): void {
    this.navController.navigateForward(`/global/user/${userId}`).then();
  }

  public getSelectedValueForEventRegistrationOptionGroup(axEventRegistrationOptionGroup: AxEventRegistrationOptionGroup) {
    const selectedOption = this.eventRegistrationSelectedOptions.find(eventRegistrationSelectedOption => eventRegistrationSelectedOption.eventRegistrationOptionGroupId === axEventRegistrationOptionGroup.id);
    if (selectedOption) {
      return selectedOption.eventRegistrationOptionId;
    }
    return null;
  }

  public setOption(group: AxEventRegistrationOptionGroup, option: any) {
    const indexByOptionGroup = this.eventRegistrationSelectedOptions.findIndex(selectedOption => selectedOption.eventRegistrationOptionGroupId === group.id);
    if (indexByOptionGroup > -1) {
      this.eventRegistrationSelectedOptions.splice(indexByOptionGroup, 1);
    }
    this.eventRegistrationSelectedOptions.push({
      eventInvitationId: this.eventInvitation.id,
      eventRegistrationOptionId: option.detail.value,
      eventRegistrationOptionGroupId: group.id,
    });
    LoggerService.LOG(this, 'this.eventRegistrationSelectedOptions', this.eventRegistrationSelectedOptions);
  }

  public submitRegistration() {
    if (this.participate) {
      this.axMyService.myEventsControllerAccept({
        invitationId: this.eventInvitation.id,
        body: {
          comment: this.eventInvitation.comment,
          eventRegistrationSelectedOptions: this.eventRegistrationSelectedOptions,
        },
      }).subscribe({
        next: async () => {
          this.axUiService.showSuccess('Vielen Dank für die Anmeldung.').then();
          await this.load(this.eventItem.id);
        },
      });
    } else {
      this.axMyService.myEventsControllerDecline({
        invitationId: this.eventInvitation.id,
        body: {
          comment: this.eventInvitation.comment,
        },
      }).subscribe({
        next: async () => {
          this.axUiService.showSuccess('Die Abmeldung wurde erfolgreich übermittelt.').then();
          await this.load(this.eventItem.id);
        },
      });
    }
  }

  public openNewsDetail(newsItem: AxNews) {
    this.router.navigate([`/global/news-detail/${newsItem.id}`]).then();
  }

  public async openDocument(document: AxDocument) {
    if (document.name && document.name.indexOf('.pdf') > -1) {
      await this.documentsService.openPdf(document);
    } else {
      await this.photoViewerService.openAxovaDocumentImage(document);
    }
  }

  ionViewDidEnter() {
    this.title.setTitle('Veranstaltung Details');
  }

  private loadInvitation() {
    this.axMyService.myEventsControllerFindInvitationByEventId({ id: this.eventItem.id })
      .subscribe({
        next: eventInvitation => {
          if (eventInvitation) {
            this.eventInvitation = eventInvitation;
            if (this.eventInvitation.acceptedAt) {
              this.participate = true;
            } else if (this.eventInvitation.declinedAt) {
              this.participate = false;
            }
            if (this.eventInvitation.eventRegistrationSelectedOptions) {
              this.eventRegistrationSelectedOptions = this.eventInvitation.eventRegistrationSelectedOptions.map(selectedOption => {
                return {
                  eventInvitationId: selectedOption.eventInvitationId,
                  eventRegistrationOptionGroupId: selectedOption.eventRegistrationOptionGroupId,
                  eventRegistrationOptionId: selectedOption.eventRegistrationOptionId,
                };
              });
            }
          }
        },
      });
  }

  private loadNews(event: AxEvent) {
    if (event.newsId) {
      this.axMyService.myNewsControllerFindOneNews({ id: event.newsId })
        .subscribe({
          next: news => {
            this.news = news;
          },
        });
    }
  }

  private loadPublicInvitations(event: AxEvent) {
    if (event.showEventInvitations) {
      this.axEventInvitationsService.eventInvitationsControllerFindPublicInvitationsByEventId({ eventId: event.id })
        .subscribe({
          next: eventInvitations => {
            this.publicEventInvitations = eventInvitations;
          },
        });
    }
  }

  private setRegistrationExpiration() {
    if (this.eventItem.registrationPossibleUntil) {
      this.hasInvitationExpired = moment(this.eventItem.registrationPossibleUntil).isBefore(moment());
    }
  }

  private removeTargetAttributes(html: string): string {
    const doc = new DOMParser().parseFromString(html, 'text/html');
    const links = doc.querySelectorAll('[target]');
    links.forEach(link => {
      const href = link.getAttribute('href');
      if (href.indexOf('http') === -1) {
        link.removeAttribute('target');
      }
    });
    return new XMLSerializer().serializeToString(doc);
  }
}
