import { Component, ElementRef, Input, OnInit, Renderer2, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { Browser } from '@capacitor/browser';
import { Store } from '@ngxs/store';
import { ImageItem } from 'ng-gallery';
import { AxComment, AxCommentsService, AxCreateCommentDto, AxMyService } from 'src/app/_generated/axova-rest-api';
import { AxNews } from 'src/app/_generated/axova-rest-api/models/ax-news';
import { AxApiGetUploadPipe } from 'src/app/ax-commons-api/ax-api-get-upload.pipe';
import { LoggerService } from 'src/app/ax-commons-logger/logger/logger.service';
import { AppState } from 'src/app/ngxs/app/app.state';
import { AxUiService } from 'src/app/services/ax-ui.service';
import { PhotoViewerService } from '../../../services/photo-viewer.service';

@Component({
  selector: 'app-news-detail',
  templateUrl: './news-detail.page.html',
  styleUrls: ['./news-detail.page.scss'],
})
export class NewsDetailPage implements OnInit {
  @Input() newsItem: AxNews;
  @ViewChild('contentContainer', { static: false }) contentContainer: ElementRef;
  public newComment = '';
  public createNewCommentLabel = 'Neuen Kommentar schreiben';
  public recipientsString = '';
  public images = [];
  public isEmployee = false;
  private mutationObserver: MutationObserver;

  constructor(
    private photoViewerService: PhotoViewerService,
    private renderer: Renderer2,
    private axMyService: AxMyService,
    private activatedRoute: ActivatedRoute,
    private title: Title,
    private axUiService: AxUiService,
    private store: Store,
    private axCommentsService: AxCommentsService,
    private axApiGetUploadPipe: AxApiGetUploadPipe,
  ) {
  }

  ngAfterViewInit() {
    this.initializeMutationObserver();
    this.store.select(AppState.profile).subscribe(profile => this.isEmployee = profile.isUserEntity);
  }

  ngOnDestroy() {
    if (this.mutationObserver) {
      this.mutationObserver.disconnect();
    }
  }

  ngOnInit() {
    if (!this.newsItem) {
      this.activatedRoute.params.subscribe(data => {
        this.load(data.id);
      });
    }
  }

  public load(newsId: number) {
    this.axMyService.myNewsControllerFindOneNews({ id: newsId })
      .subscribe(newsItem => {
        newsItem.text = this.removeTargetAttributes(newsItem.text);
        this.newsItem = newsItem;
        this.setCreateNewCommentsLabel();
        this.setRecipientsLabel();
        this.setImageGallery();
      });
  }

  private initializeMutationObserver() {
    this.mutationObserver = new MutationObserver(() => {
      this.bindClickEvents();
    });

    this.mutationObserver.observe(this.contentContainer.nativeElement, {
      childList: true,
      subtree: true,
    });
  }

  private bindClickEvents() {
    const figures = this.contentContainer.nativeElement.querySelectorAll('figure');
    figures.forEach((figure: HTMLElement) => {
      const img = figure.querySelector('img');
      const figcaption = figure.querySelector('figcaption');

      if (img) {
        const imageUrl = img.getAttribute('src');
        const captionText = figcaption ? figcaption.textContent : '';

        this.renderer.listen(figure, 'click', () => {
          console.log('click', imageUrl, captionText);
          this.photoViewerService.openImage(imageUrl, captionText).catch();
        });
      }
    });
  }

  public openLink(link: string): void {
    Browser.open({ url: link });
  }

  ionViewDidEnter() {
    this.title.setTitle('News Details');
  }

  public sendComment(answer?: Partial<AxComment>) {
    console.log('answer', answer);
    const body: AxCreateCommentDto = {
      comment: this.newComment,
      newsId: this.newsItem.id,
    };
    if (answer) {
      body.comment = answer.comment;
      body.parentId = answer.parentId;
    }
    this.axCommentsService.commentsControllerCreate({
      body,
    }).subscribe({
      next: () => {
        this.axUiService.showSuccess('Kommentar wurde gespeichert!');
        this.load(this.newsItem.id);
        this.newComment = '';
      },
      error: error => {
        this.axUiService.showError('Kommentar konnte nicht gespeichert werden.');
      },
    });
  }

  private setCreateNewCommentsLabel() {
    try {
      if (this.store.selectSnapshot(AppState.profile).meta.title.id === 2) {
        this.createNewCommentLabel = 'Schreibe als Erste einen Kommentar';
      } else {
        this.createNewCommentLabel = 'Schreibe als Erster einen Kommentar';
      }
    } catch (noCommentsException) {
      LoggerService.LOG(this, 'noCommentsException', noCommentsException);
    }
  }

  private setRecipientsLabel() {
    try {
      this.recipientsString = this.newsItem?.audiences.map(audience => audience.name).join(', ');
    } catch (noAudiencesException) {
      LoggerService.LOG(this, 'noAudiencesException', noAudiencesException);
    }
  }

  private setImageGallery() {
    const images = [];
    for (const upload of this.newsItem?.uploads) {
      images.push(
        new ImageItem(
          {
            src: this.axApiGetUploadPipe.transform(upload.id),
            alt: 'Bilder wird geladen',
          },
        ),
      );
    }
    this.images = images;
  }

  private removeTargetAttributes(html: string): string {
    const doc = new DOMParser().parseFromString(html, 'text/html');
    const links = doc.querySelectorAll('[target]');
    links.forEach(link => {
      const href = link.getAttribute('href');
      if (href.indexOf('http') === -1) {
        link.removeAttribute('target');
      }
    });
    return new XMLSerializer().serializeToString(doc);
  }
}
