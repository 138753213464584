import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import {
  CreateElectricalMeasurementDetailPage,
} from 'src/app/pages/global/create-electrical-measurement/create-electrical-measurement-detail/create-electrical-measurement-detail.page';
import {
  CreateElectricalMeasurementOverviewPage,
} from 'src/app/pages/global/create-electrical-measurement/create-electrical-measurement-overview/create-electrical-measurement-overview.page';
import {
  CreateElectricalMeasurementRoutingModule,
} from 'src/app/pages/global/create-electrical-measurement/create-electrical-measurement-routing.module';
import {
  CreateElectricalMeasurementStartPage,
} from 'src/app/pages/global/create-electrical-measurement/create-electrical-measurement-start/create-electrical-measurement-start.page';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    CreateElectricalMeasurementRoutingModule,
  ],
  declarations: [
    CreateElectricalMeasurementStartPage,
    CreateElectricalMeasurementOverviewPage,
    CreateElectricalMeasurementDetailPage,
  ],
})
export class CreateElectricalMeasurementPageModule {
}
