import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

export interface IAxSegment {
  key: string;
  text: string;
  active?: boolean;
}

@Component({
  selector: 'ax-segments',
  templateUrl: './ax-segments.component.html',
  styleUrls: ['./ax-segments.component.scss'],
})
export class AxSegmentsComponent implements OnInit {
  @Input() segments: IAxSegment[] = [];
  @Output() segmentActivated: EventEmitter<IAxSegment>;
  public activeSegment: IAxSegment;

  constructor() {
    this.segmentActivated = new EventEmitter<IAxSegment>();
  }

  ngOnInit() {
    try {
      this.activeSegment = this.segments.filter(segment => segment.active)[0];
    } catch (noActiveSegmentExceptoin) {
      this.activeSegment = this.segments[0];
    }
  }

  setSegment(segment: IAxSegment): void {
    this.activeSegment = segment;
    this.segmentActivated.emit(segment);
  }
}
