import { Injectable } from '@angular/core';
import { Contacts } from '@capacitor-community/contacts';
import { Camera } from '@capacitor/camera';
import { Capacitor } from '@capacitor/core';
import { ToastController } from '@ionic/angular';
import { Filesystem } from '@capacitor/filesystem';

@Injectable({
  providedIn: 'root',
})
export class PermissionsService {
  constructor(
    private toastController: ToastController,
  ) {
  }

  /**
   * Returns true if camera permissions are granted, otherwise false (and show message to user).
   */
  public async checkCameraPermissions(type: 'camera' | 'photos'): Promise<boolean> {
    if (!Capacitor.isNativePlatform()) {
      return true;
    }
    let permissions = await Camera.checkPermissions();
    if (permissions[type] !== 'granted' && permissions[type] !== 'limited') {
      permissions = await Camera.requestPermissions({ permissions: [type] });
      if (permissions[type] === 'granted' || permissions[type] === 'limited') {
        return true;
      } else {
        this.showPermissionError();
        return false;
      }
    } else {
      return true;
    }
  }

  public async checkContactsPermissions() {
    const permissions = await Contacts.checkPermissions();
    if (permissions.contacts && permissions.contacts === 'granted') {
      return true;
    } else {
      const result = await Contacts.requestPermissions();
      if (result.contacts && result.contacts === 'granted') {
        return true;
      }
    }
    return false;
  }

  public async checkStoragePermissions() {
    if (!Capacitor.isNativePlatform()) {
      return true;
    }
    const permissions = await Filesystem.checkPermissions();
    if (permissions.publicStorage && permissions.publicStorage === 'granted') {
      return true;
    } else {
      const result = await Filesystem.requestPermissions();
      if (result.publicStorage && result.publicStorage === 'granted') {
        return true;
      }
    }
    return false;
  }

  private showPermissionError() {
    this.toastController.create({
      message: 'Für diese Funktion müssen Sie der Axova-App die entsprechenden Berechtigungen erteilen.',
    }).then();
  }
}
