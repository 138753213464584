/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { AxCreateWorkflowtaskDto } from '../models/ax-create-workflowtask-dto';
import { AxUpdateWorkflowtaskDto } from '../models/ax-update-workflowtask-dto';
import { AxUpdateWorkflowtaskSortableRankDto } from '../models/ax-update-workflowtask-sortable-rank-dto';
import { AxWorkflowtask } from '../models/ax-workflowtask';

@Injectable({ providedIn: 'root' })
export class AxWorkflowtasksV2Service extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `workflowtasksControllerFindAllByWorkflowId()` */
  static readonly WorkflowtasksControllerFindAllByWorkflowIdPath = '/v2/workflowtasks/{workflowId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `workflowtasksControllerFindAllByWorkflowId()` instead.
   *
   * This method doesn't expect any request body.
   */
  workflowtasksControllerFindAllByWorkflowId$Response(
    params: {
      workflowId: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<AxWorkflowtask>>> {
    const rb = new RequestBuilder(this.rootUrl, AxWorkflowtasksV2Service.WorkflowtasksControllerFindAllByWorkflowIdPath, 'get');
    if (params) {
      rb.path('workflowId', params.workflowId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AxWorkflowtask>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `workflowtasksControllerFindAllByWorkflowId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  workflowtasksControllerFindAllByWorkflowId(
    params: {
      workflowId: number;
    },
    context?: HttpContext
  ): Observable<Array<AxWorkflowtask>> {
    return this.workflowtasksControllerFindAllByWorkflowId$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<AxWorkflowtask>>): Array<AxWorkflowtask> => r.body)
    );
  }

  /** Path part for operation `workflowtasksControllerCreate()` */
  static readonly WorkflowtasksControllerCreatePath = '/v2/workflowtasks';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `workflowtasksControllerCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  workflowtasksControllerCreate$Response(
    params: {
      body: AxCreateWorkflowtaskDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AxWorkflowtask>> {
    const rb = new RequestBuilder(this.rootUrl, AxWorkflowtasksV2Service.WorkflowtasksControllerCreatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AxWorkflowtask>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `workflowtasksControllerCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  workflowtasksControllerCreate(
    params: {
      body: AxCreateWorkflowtaskDto
    },
    context?: HttpContext
  ): Observable<AxWorkflowtask> {
    return this.workflowtasksControllerCreate$Response(params, context).pipe(
      map((r: StrictHttpResponse<AxWorkflowtask>): AxWorkflowtask => r.body)
    );
  }

  /** Path part for operation `workflowtasksControllerDelete()` */
  static readonly WorkflowtasksControllerDeletePath = '/v2/workflowtasks/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `workflowtasksControllerDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  workflowtasksControllerDelete$Response(
    params: {
      id: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, AxWorkflowtasksV2Service.WorkflowtasksControllerDeletePath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `workflowtasksControllerDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  workflowtasksControllerDelete(
    params: {
      id: number;
    },
    context?: HttpContext
  ): Observable<void> {
    return this.workflowtasksControllerDelete$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `workflowtasksControllerUpdate()` */
  static readonly WorkflowtasksControllerUpdatePath = '/v2/workflowtasks/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `workflowtasksControllerUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  workflowtasksControllerUpdate$Response(
    params: {
      id: number;
      body: AxUpdateWorkflowtaskDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AxWorkflowtask>> {
    const rb = new RequestBuilder(this.rootUrl, AxWorkflowtasksV2Service.WorkflowtasksControllerUpdatePath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AxWorkflowtask>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `workflowtasksControllerUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  workflowtasksControllerUpdate(
    params: {
      id: number;
      body: AxUpdateWorkflowtaskDto
    },
    context?: HttpContext
  ): Observable<AxWorkflowtask> {
    return this.workflowtasksControllerUpdate$Response(params, context).pipe(
      map((r: StrictHttpResponse<AxWorkflowtask>): AxWorkflowtask => r.body)
    );
  }

  /** Path part for operation `workflowtasksControllerUpdateSortableRankForTaskGroup()` */
  static readonly WorkflowtasksControllerUpdateSortableRankForTaskGroupPath = '/v2/workflowtasks/workflowtasks/sortableRanks';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `workflowtasksControllerUpdateSortableRankForTaskGroup()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  workflowtasksControllerUpdateSortableRankForTaskGroup$Response(
    params: {
      body: Array<AxUpdateWorkflowtaskSortableRankDto>
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<boolean>> {
    const rb = new RequestBuilder(this.rootUrl, AxWorkflowtasksV2Service.WorkflowtasksControllerUpdateSortableRankForTaskGroupPath, 'patch');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `workflowtasksControllerUpdateSortableRankForTaskGroup$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  workflowtasksControllerUpdateSortableRankForTaskGroup(
    params: {
      body: Array<AxUpdateWorkflowtaskSortableRankDto>
    },
    context?: HttpContext
  ): Observable<boolean> {
    return this.workflowtasksControllerUpdateSortableRankForTaskGroup$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

}
