import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { AxComponentsModule } from 'src/app/components/ax-components.module';
import {
  UpdateUserDataFormPageRoutingModule,
} from 'src/app/pages/global/update-user-data-form/update-user-data-form-routing.module';
import { UpdateUserDataFormPage } from 'src/app/pages/global/update-user-data-form/update-user-data-form.page';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    AxComponentsModule,
    UpdateUserDataFormPageRoutingModule,
  ],
  declarations: [UpdateUserDataFormPage],
})
export class UpdateUserDataFormPageModule {
}
