import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { AxComponentsModule } from 'src/app/components/ax-components.module';
import { PipesModule } from 'src/app/pipes/pipes.module';
import { EventsPage } from './events.page';
import { EventsPageRoutingModule } from './events-routing.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    EventsPageRoutingModule,
    AxComponentsModule,
    PipesModule,
  ],
  declarations: [EventsPage],
})
export class EventsPageModule {
}
