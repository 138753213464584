import { Component, Input, OnInit } from '@angular/core';
import { AxProject, AxTask } from 'src/app/_generated/axova-rest-api';

@Component({
  selector: 'ax-project-appointments',
  templateUrl: './ax-project-appointments.component.html',
  styleUrls: ['./ax-project-appointments.component.scss'],
})
export class AxProjectAppointmentsComponent implements OnInit {
  @Input() project: AxProject;
  @Input() appointments: AxTask[];
  @Input() isInternalView: boolean = false;
  public accordionStates: any = {};

  constructor() {
  }

  ngOnInit() {
  }
}
