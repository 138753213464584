export class AppStateSetProfile {
  static readonly type = '[app] Set profile';
  constructor(public profile: any) {
  }
}

export class AppStateSetAppDeviceId {
  static readonly type = '[app] Set device id';
  constructor(public deviceId: number) {
  }
}
