import { Injectable } from '@angular/core';
import { App } from '@capacitor/app';
import { Capacitor } from '@capacitor/core';
import { Device } from '@capacitor/device';
import { Store } from '@ngxs/store';
import { AxAppDevicesService, AxCreatePushNotificationTokenDto } from 'src/app/_generated/axova-rest-api';
import { AppStateSetAppDeviceId } from 'src/app/ngxs/app/app.actions';

@Injectable({
  providedIn: 'root',
})
export class AppDeviceService {
  static IS_STORING = false;

  constructor(
    private axAppDevicesService: AxAppDevicesService,
    private store: Store,
  ) {
  }

  /**
   * Initialize app device information and send to API. Either with or without
   * push notification token.
   *
   * @param pushNotificationToken
   */
  public async initializeAppDeviceInformation(pushNotificationToken: string = '') {
    let appInfo = {version: '1.0.0'};
    if (Capacitor.isNativePlatform()) {
      appInfo = await App.getInfo();
    }
    const deviceId = await Device.getId();
    const deviceInfo = await Device.getInfo();
    const createPushNotificationTokenDto: AxCreatePushNotificationTokenDto = {
      token: pushNotificationToken,
    };
    this.axAppDevicesService.appDevicesControllerRegisterOrUpdate({
      body: {
        model: deviceInfo.model,
        osVersion: deviceInfo.osVersion,
        platform: deviceInfo.platform,
        uniqueIdentifier: deviceId.identifier,
        appVersion: appInfo.version,
        pushNotificationDto: pushNotificationToken ? createPushNotificationTokenDto : null,
      },
    }).subscribe({
      next: appDevice => {
        if (appDevice && appDevice.appDeviceId) {
          this.store.dispatch(new AppStateSetAppDeviceId(appDevice.appDeviceId));
        }
      },
    });
  }
}
