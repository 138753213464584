import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'trimWhitespaces'})
export class TrimWhitespacesPipe implements PipeTransform {
  transform(value: any) {
    if (!value) {
      return '';
    }

    return value.trim().replaceAll(' ', '');
  }
}
