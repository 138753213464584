import { Component, Input, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { ModalController, NavController } from '@ionic/angular';
import { Store } from '@ngxs/store';
import { AxHelpersService, AxSendLeadDto } from 'src/app/_generated/axova-rest-api';
import { AxUiService } from 'src/app/services/ax-ui.service';
import { Browser } from '@capacitor/browser';

@Component({
  selector: 'app-create-lead-form',
  templateUrl: './create-lead-form.page.html',
  styleUrls: ['./create-lead-form.page.scss'],
})
export class CreateLeadFormPage implements OnInit {
  @Input() isModal = false;
  public formData: AxSendLeadDto = {
    firstname: '',
    lastname: '',
    street: '',
    zip: '',
    city: '',
    phone: '',
    email: '',
  };

  constructor(
    private axUiService: AxUiService,
    private store: Store,
    private axHelpersService: AxHelpersService,
    private modalController: ModalController,
    private router: Router,
    private navController: NavController,
    private title: Title,
  ) {
  }

  ngOnInit() {
  }

  ionViewDidEnter() {
    this.title.setTitle(`Interessent vermitteln`);
  }

  public close() {
    if (this.isModal) {
      this.modalController.dismiss().then();
    } else {
      this.navController.pop().then();
    }
  }

  public submit() {
    this.axHelpersService.helperControllerSendLead({
      body: this.formData,
    }).subscribe({
      next: value => {
        this.axUiService.showSuccess('Herzlichen Dank für die Vermittlung. Wir werden uns schnellstmöglich mit dem Interessenten in Verbindung setzen.').then();
        this.close();
      },
      error: err => {
        this.axUiService.showError('Ihre Nachricht konnte leider nicht versendet werden.').then();
      },
    });
  }

  public async openTerminAssistent() {
    await Browser.open({
      url: 'https://termin.axova.ch',
    });
  }
}
