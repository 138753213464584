
import { FormGroup, FormControl, FormArray, Validators } from '@angular/forms';


export function getUpdateTaskSortableRankDtoFormGroup() {
return new FormGroup({
    taskId: new FormControl<number | undefined>(undefined, [Validators.required]),
    sortableRank: new FormControl<number | undefined>(undefined, [Validators.required]),
})
;
}
      