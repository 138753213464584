import { Pipe, PipeTransform } from '@angular/core';
import { AxStocklocation, AxStocklocationsV2Service } from '../_generated/axova-rest-api';

@Pipe({
  name: 'getStocklocationBreadcrumb',
})
export class GetStocklocationBreadcrumbPipe implements PipeTransform {
  constructor(
    private readonly axStocklocationsV2Service: AxStocklocationsV2Service,
  ) {
  }

  async transform(stocklocation: AxStocklocation): Promise<string> {
    const stocklocationWithParents = await this.axStocklocationsV2Service.stocklocationsControllerGetAllParentStocklocationsForId({ id: stocklocation.id }).toPromise();
    const stocklocationNames: string[] = [`<strong>${stocklocationWithParents.name}</strong>`];
    let currentStocklocation = stocklocationWithParents;
    while (currentStocklocation.parent && currentStocklocation.parent.name) {
      stocklocationNames.unshift(currentStocklocation.parent.name);
      currentStocklocation = currentStocklocation.parent;
    }
    return stocklocationNames.join(' → ');
  }
}
