import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AxDocument, AxDocumentsService, AxItem } from 'src/app/_generated/axova-rest-api';
import { PhotoViewerService } from '../../services/photo-viewer.service';
import { DocumentsService } from '../../services/documents.service';

@Component({
  selector: 'ax-item-details',
  templateUrl: './ax-item-details.component.html',
  styleUrls: ['./ax-item-details.component.scss'],
})
export class AxItemDetailsComponent implements OnInit, OnDestroy {
  @Input() item: AxItem;
  @Input() documents: AxDocument[] = [];
  public productImages: AxDocument[] = [];
  public slidesOptions = {
    allowSlideNext: false,
    allowSlidePrev: false,
  };

  constructor(
    private documentsService: DocumentsService,
    private axDocumentsService: AxDocumentsService,
    private photoViewerService: PhotoViewerService,
  ) {
  }

  public ngOnInit() {
    this.loadProductImages().then();
  }

  public ngOnDestroy() {
  }

  public async loadProductImages() {
    if (this.item.id) {
      this.axDocumentsService.documentsControllerGetProductImagesForItem({ id: this.item.id }).subscribe({
        next: productImages => {
          if (productImages && productImages.length) {
            if (productImages.length > 1) {
              this.slidesOptions.allowSlideNext = true;
              this.slidesOptions.allowSlidePrev = true;
            }
            this.productImages = productImages;
          }
        },
      });
    }
  }

  public async open(document: AxDocument) {
    if (document.name && document.name.indexOf('.pdf') > -1) {
      await this.openPdf(document);
    } else {
      await this.photoViewerService.openAxovaDocumentImage(document);
    }
  }

  public async openPdf(document: AxDocument) {
    await this.documentsService.openPdf(document);
  }
}
