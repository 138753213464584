/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { AxCreateScreenslideDto } from '../models/ax-create-screenslide-dto';
import { AxPaginatedDocumented } from '../models/ax-paginated-documented';
import { AxScreenslide } from '../models/ax-screenslide';
import { AxScreenslideComponent } from '../models/ax-screenslide-component';
import { AxSuccessDto } from '../models/ax-success-dto';
import { AxUpdateScreenslideDto } from '../models/ax-update-screenslide-dto';

@Injectable({ providedIn: 'root' })
export class AxScreenslidesV2Service extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `screenslidesControllerFindAllPaginated()` */
  static readonly ScreenslidesControllerFindAllPaginatedPath = '/v2/screenslides';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `screenslidesControllerFindAllPaginated()` instead.
   *
   * This method doesn't expect any request body.
   */
  screenslidesControllerFindAllPaginated$Response(
    params?: {

    /**
     * Page number to retrieve.If you provide invalid value the default page number will applied
     *         <p>
     *              <b>Example: </b> 1
     *           </p>
     *         <p>
     *              <b>Default Value: </b> 1
     *           </p>
     */
      page?: number;

    /**
     * Number of records per page.
     *       <p>
     *              <b>Example: </b> 20
     *           </p>
     *       <p>
     *              <b>Default Value: </b> 25
     *           </p>
     *       <p>
     *              <b>Max Value: </b> 100
     *           </p>
     *
     *       If provided value is greater than max value, max value will be applied.
     */
      limit?: number;

    /**
     * Filter by active query param.
     *           <p>
     *              <b>Format: </b> filter.active={$not}:OPERATION:VALUE
     *           </p>
     *           <p>
     *              <b>Example: </b> filter.active=$not:$like:John Doe&filter.active=like:John
     *           </p>
     *           <h4>Available Operations</h4><ul><li>$eq</li></ul>
     */
      'filter.active'?: Array<string>;

    /**
     * Filter by screendeviceSlides.screendeviceId query param.
     *           <p>
     *              <b>Format: </b> filter.screendeviceSlides.screendeviceId={$not}:OPERATION:VALUE
     *           </p>
     *           <p>
     *              <b>Example: </b> filter.screendeviceSlides.screendeviceId=$not:$like:John Doe&filter.screendeviceSlides.screendeviceId=like:John
     *           </p>
     *           <h4>Available Operations</h4><ul><li>$eq</li></ul>
     */
      'filter.screendeviceSlides.screendeviceId'?: Array<string>;

    /**
     * Parameter to sort by.
     *       <p>To sort by multiple fields, just provide query param multiple types. The order in url defines an order of sorting</p>
     *       <p>
     *              <b>Format: </b> fieldName:DIRECTION
     *           </p>
     *       <p>
     *              <b>Example: </b> sortBy=id:DESC&sortBy=createdAt:ASC
     *           </p>
     *       <p>
     *              <b>Default Value: </b> name:ASC
     *           </p>
     *       <h4>Available Fields</h4><ul><li>name</li></ul>
     */
      sortBy?: Array<'name:ASC' | 'name:DESC'>;

    /**
     * Search term to filter result values
     *         <p>
     *              <b>Example: </b> John
     *           </p>
     *         <p>
     *              <b>Default Value: </b> No default value
     *           </p>
     */
      search?: string;

    /**
     * List of fields to search by term to filter result values
     *         <p>
     *              <b>Example: </b> name
     *           </p>
     *         <p>
     *              <b>Default Value: </b> By default all fields mentioned below will be used to search by term
     *           </p>
     *         <h4>Available Fields</h4><ul><li>name</li></ul>
     */
      searchBy?: Array<string>;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AxPaginatedDocumented & {
'data'?: Array<AxScreenslide>;
'meta'?: {
'select'?: Array<string>;
'filter'?: {
'active'?: (string | Array<string>);
'screendeviceSlides.screendeviceId'?: (string | Array<string>);
};
};
}>> {
    const rb = new RequestBuilder(this.rootUrl, AxScreenslidesV2Service.ScreenslidesControllerFindAllPaginatedPath, 'get');
    if (params) {
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.query('filter.active', params['filter.active'], {});
      rb.query('filter.screendeviceSlides.screendeviceId', params['filter.screendeviceSlides.screendeviceId'], {});
      rb.query('sortBy', params.sortBy, {});
      rb.query('search', params.search, {});
      rb.query('searchBy', params.searchBy, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AxPaginatedDocumented & {
        'data'?: Array<AxScreenslide>;
        'meta'?: {
        'select'?: Array<string>;
        'filter'?: {
        'active'?: (string | Array<string>);
        'screendeviceSlides.screendeviceId'?: (string | Array<string>);
        };
        };
        }>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `screenslidesControllerFindAllPaginated$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  screenslidesControllerFindAllPaginated(
    params?: {

    /**
     * Page number to retrieve.If you provide invalid value the default page number will applied
     *         <p>
     *              <b>Example: </b> 1
     *           </p>
     *         <p>
     *              <b>Default Value: </b> 1
     *           </p>
     */
      page?: number;

    /**
     * Number of records per page.
     *       <p>
     *              <b>Example: </b> 20
     *           </p>
     *       <p>
     *              <b>Default Value: </b> 25
     *           </p>
     *       <p>
     *              <b>Max Value: </b> 100
     *           </p>
     *
     *       If provided value is greater than max value, max value will be applied.
     */
      limit?: number;

    /**
     * Filter by active query param.
     *           <p>
     *              <b>Format: </b> filter.active={$not}:OPERATION:VALUE
     *           </p>
     *           <p>
     *              <b>Example: </b> filter.active=$not:$like:John Doe&filter.active=like:John
     *           </p>
     *           <h4>Available Operations</h4><ul><li>$eq</li></ul>
     */
      'filter.active'?: Array<string>;

    /**
     * Filter by screendeviceSlides.screendeviceId query param.
     *           <p>
     *              <b>Format: </b> filter.screendeviceSlides.screendeviceId={$not}:OPERATION:VALUE
     *           </p>
     *           <p>
     *              <b>Example: </b> filter.screendeviceSlides.screendeviceId=$not:$like:John Doe&filter.screendeviceSlides.screendeviceId=like:John
     *           </p>
     *           <h4>Available Operations</h4><ul><li>$eq</li></ul>
     */
      'filter.screendeviceSlides.screendeviceId'?: Array<string>;

    /**
     * Parameter to sort by.
     *       <p>To sort by multiple fields, just provide query param multiple types. The order in url defines an order of sorting</p>
     *       <p>
     *              <b>Format: </b> fieldName:DIRECTION
     *           </p>
     *       <p>
     *              <b>Example: </b> sortBy=id:DESC&sortBy=createdAt:ASC
     *           </p>
     *       <p>
     *              <b>Default Value: </b> name:ASC
     *           </p>
     *       <h4>Available Fields</h4><ul><li>name</li></ul>
     */
      sortBy?: Array<'name:ASC' | 'name:DESC'>;

    /**
     * Search term to filter result values
     *         <p>
     *              <b>Example: </b> John
     *           </p>
     *         <p>
     *              <b>Default Value: </b> No default value
     *           </p>
     */
      search?: string;

    /**
     * List of fields to search by term to filter result values
     *         <p>
     *              <b>Example: </b> name
     *           </p>
     *         <p>
     *              <b>Default Value: </b> By default all fields mentioned below will be used to search by term
     *           </p>
     *         <h4>Available Fields</h4><ul><li>name</li></ul>
     */
      searchBy?: Array<string>;
    },
    context?: HttpContext
  ): Observable<AxPaginatedDocumented & {
'data'?: Array<AxScreenslide>;
'meta'?: {
'select'?: Array<string>;
'filter'?: {
'active'?: (string | Array<string>);
'screendeviceSlides.screendeviceId'?: (string | Array<string>);
};
};
}> {
    return this.screenslidesControllerFindAllPaginated$Response(params, context).pipe(
      map((r: StrictHttpResponse<AxPaginatedDocumented & {
'data'?: Array<AxScreenslide>;
'meta'?: {
'select'?: Array<string>;
'filter'?: {
'active'?: (string | Array<string>);
'screendeviceSlides.screendeviceId'?: (string | Array<string>);
};
};
}>): AxPaginatedDocumented & {
'data'?: Array<AxScreenslide>;
'meta'?: {
'select'?: Array<string>;
'filter'?: {
'active'?: (string | Array<string>);
'screendeviceSlides.screendeviceId'?: (string | Array<string>);
};
};
} => r.body)
    );
  }

  /** Path part for operation `screenslidesControllerCreate()` */
  static readonly ScreenslidesControllerCreatePath = '/v2/screenslides';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `screenslidesControllerCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  screenslidesControllerCreate$Response(
    params: {
      body: AxCreateScreenslideDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AxScreenslide>> {
    const rb = new RequestBuilder(this.rootUrl, AxScreenslidesV2Service.ScreenslidesControllerCreatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AxScreenslide>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `screenslidesControllerCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  screenslidesControllerCreate(
    params: {
      body: AxCreateScreenslideDto
    },
    context?: HttpContext
  ): Observable<AxScreenslide> {
    return this.screenslidesControllerCreate$Response(params, context).pipe(
      map((r: StrictHttpResponse<AxScreenslide>): AxScreenslide => r.body)
    );
  }

  /** Path part for operation `screenslidesControllerFindById()` */
  static readonly ScreenslidesControllerFindByIdPath = '/v2/screenslides/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `screenslidesControllerFindById()` instead.
   *
   * This method doesn't expect any request body.
   */
  screenslidesControllerFindById$Response(
    params: {
      id: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AxScreenslide>> {
    const rb = new RequestBuilder(this.rootUrl, AxScreenslidesV2Service.ScreenslidesControllerFindByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AxScreenslide>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `screenslidesControllerFindById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  screenslidesControllerFindById(
    params: {
      id: number;
    },
    context?: HttpContext
  ): Observable<AxScreenslide> {
    return this.screenslidesControllerFindById$Response(params, context).pipe(
      map((r: StrictHttpResponse<AxScreenslide>): AxScreenslide => r.body)
    );
  }

  /** Path part for operation `screenslidesControllerDelete()` */
  static readonly ScreenslidesControllerDeletePath = '/v2/screenslides/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `screenslidesControllerDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  screenslidesControllerDelete$Response(
    params: {
      id: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<boolean>> {
    const rb = new RequestBuilder(this.rootUrl, AxScreenslidesV2Service.ScreenslidesControllerDeletePath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `screenslidesControllerDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  screenslidesControllerDelete(
    params: {
      id: number;
    },
    context?: HttpContext
  ): Observable<boolean> {
    return this.screenslidesControllerDelete$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `screenslidesControllerUpdate()` */
  static readonly ScreenslidesControllerUpdatePath = '/v2/screenslides/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `screenslidesControllerUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  screenslidesControllerUpdate$Response(
    params: {
      id: number;
      body: AxUpdateScreenslideDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AxScreenslide>> {
    const rb = new RequestBuilder(this.rootUrl, AxScreenslidesV2Service.ScreenslidesControllerUpdatePath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AxScreenslide>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `screenslidesControllerUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  screenslidesControllerUpdate(
    params: {
      id: number;
      body: AxUpdateScreenslideDto
    },
    context?: HttpContext
  ): Observable<AxScreenslide> {
    return this.screenslidesControllerUpdate$Response(params, context).pipe(
      map((r: StrictHttpResponse<AxScreenslide>): AxScreenslide => r.body)
    );
  }

  /** Path part for operation `screenslidesControllerFindComponentsForSlide()` */
  static readonly ScreenslidesControllerFindComponentsForSlidePath = '/v2/screenslides/{id}/components';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `screenslidesControllerFindComponentsForSlide()` instead.
   *
   * This method doesn't expect any request body.
   */
  screenslidesControllerFindComponentsForSlide$Response(
    params: {
      id: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<AxScreenslideComponent>>> {
    const rb = new RequestBuilder(this.rootUrl, AxScreenslidesV2Service.ScreenslidesControllerFindComponentsForSlidePath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AxScreenslideComponent>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `screenslidesControllerFindComponentsForSlide$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  screenslidesControllerFindComponentsForSlide(
    params: {
      id: number;
    },
    context?: HttpContext
  ): Observable<Array<AxScreenslideComponent>> {
    return this.screenslidesControllerFindComponentsForSlide$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<AxScreenslideComponent>>): Array<AxScreenslideComponent> => r.body)
    );
  }

  /** Path part for operation `screenslidesControllerAddComponentToSlide()` */
  static readonly ScreenslidesControllerAddComponentToSlidePath = '/v2/screenslides/{id}/components/{componentId}/{position}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `screenslidesControllerAddComponentToSlide()` instead.
   *
   * This method doesn't expect any request body.
   */
  screenslidesControllerAddComponentToSlide$Response(
    params: {
      id: number;
      componentId: number;
      position: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AxSuccessDto>> {
    const rb = new RequestBuilder(this.rootUrl, AxScreenslidesV2Service.ScreenslidesControllerAddComponentToSlidePath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('componentId', params.componentId, {});
      rb.path('position', params.position, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AxSuccessDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `screenslidesControllerAddComponentToSlide$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  screenslidesControllerAddComponentToSlide(
    params: {
      id: number;
      componentId: number;
      position: number;
    },
    context?: HttpContext
  ): Observable<AxSuccessDto> {
    return this.screenslidesControllerAddComponentToSlide$Response(params, context).pipe(
      map((r: StrictHttpResponse<AxSuccessDto>): AxSuccessDto => r.body)
    );
  }

  /** Path part for operation `screenslidesControllerRemoveComponentFromSlide()` */
  static readonly ScreenslidesControllerRemoveComponentFromSlidePath = '/v2/screenslides/{id}/components/{componentId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `screenslidesControllerRemoveComponentFromSlide()` instead.
   *
   * This method doesn't expect any request body.
   */
  screenslidesControllerRemoveComponentFromSlide$Response(
    params: {
      id: number;
      componentId: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AxSuccessDto>> {
    const rb = new RequestBuilder(this.rootUrl, AxScreenslidesV2Service.ScreenslidesControllerRemoveComponentFromSlidePath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('componentId', params.componentId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AxSuccessDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `screenslidesControllerRemoveComponentFromSlide$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  screenslidesControllerRemoveComponentFromSlide(
    params: {
      id: number;
      componentId: number;
    },
    context?: HttpContext
  ): Observable<AxSuccessDto> {
    return this.screenslidesControllerRemoveComponentFromSlide$Response(params, context).pipe(
      map((r: StrictHttpResponse<AxSuccessDto>): AxSuccessDto => r.body)
    );
  }

  /** Path part for operation `screenslidesControllerFindPublicDataById()` */
  static readonly ScreenslidesControllerFindPublicDataByIdPath = '/v2/screenslides/data/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `screenslidesControllerFindPublicDataById()` instead.
   *
   * This method doesn't expect any request body.
   */
  screenslidesControllerFindPublicDataById$Response(
    params: {
      id: number;

    /**
     * This request requires a valid API key.
     */
      'x-api-key'?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AxScreenslide>> {
    const rb = new RequestBuilder(this.rootUrl, AxScreenslidesV2Service.ScreenslidesControllerFindPublicDataByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.header('x-api-key', params['x-api-key'], {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AxScreenslide>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `screenslidesControllerFindPublicDataById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  screenslidesControllerFindPublicDataById(
    params: {
      id: number;

    /**
     * This request requires a valid API key.
     */
      'x-api-key'?: string;
    },
    context?: HttpContext
  ): Observable<AxScreenslide> {
    return this.screenslidesControllerFindPublicDataById$Response(params, context).pipe(
      map((r: StrictHttpResponse<AxScreenslide>): AxScreenslide => r.body)
    );
  }

}
