import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AxIsCustomerGuard } from 'src/app/ax-commons-api/guards/ax-is-customer-guard.service';
import { AxIsEmployeeGuard } from 'src/app/ax-commons-api/guards/ax-is-employee-guard.service';
import { AxIsLoggedInGuard } from 'src/app/ax-commons-api/guards/ax-is-logged-in-guard.service';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: '/auth/login',
  },
  {
    path: 'employees-tabs',
    loadChildren: () => import('./pages/employees-tabs/employees-tabs.module').then(m => m.EmployeesTabsPageModule),
    canActivate: [AxIsLoggedInGuard, AxIsEmployeeGuard],
  },
  {
    path: 'customers-tabs',
    loadChildren: () => import('./pages/customers-tabs/customers-tabs.module').then(m => m.CustomersTabsPageModule),
    canActivate: [AxIsLoggedInGuard, AxIsCustomerGuard],
  },
  {
    path: 'auth',
    loadChildren: () => import('./pages/auth/auth.module').then(m => m.AuthPageModule),
  },
  {
    path: 'global',
    canActivate: [AxIsLoggedInGuard],
    children: [
      {
        path: 'item-details/:id',
        loadChildren: () => import('./pages/global/item-details/item-details.module').then(m => m.ItemDetailsPageModule),
      },
      {
        path: 'item-details/:id/:calculationitemId',
        loadChildren: () => import('./pages/global/item-details/item-details.module').then(m => m.ItemDetailsPageModule),
      },
      {
        path: 'news',
        loadChildren: () => import('./pages/global/news/news.module').then(m => m.NewsPageModule),
      },
      {
        path: 'news-detail/:id',
        loadChildren: () => import('./pages/global/news-detail/news-detail.module').then(m => m.NewsDetailPageModule),
      },
      {
        path: 'event-detail/:id',
        loadChildren: () => import('./pages/global/event-detail/event-detail.module').then(m => m.EventDetailPageModule),
      },
      {
        path: 'user/:id',
        loadChildren: () => import('./pages/global/user/user.module').then(m => m.UserPageModule),
      },
      {
        path: 'contact-details/:id',
        loadChildren: () => import('./pages/global/contact-details/contact-details.module').then(m => m.ContactDetailsPageModule),
      },
      {
        path: 'project-detail/:id',
        loadChildren: () => import('./pages/global/project-detail/project-detail.module').then(m => m.ProjectDetailPageModule),
      },
      {
        path: 'project-photos/:id',
        loadChildren: () => import('./pages/global/project-photos/project-photos.module').then(m => m.ProjectPhotosPageModule),
      },
      {
        path: 'create-electrical-measurement/:id',
        loadChildren: () => import('./pages/global/create-electrical-measurement/create-electrical-measurement.module').then(m => m.CreateElectricalMeasurementPageModule),
      },
      {
        path: 'debug',
        loadChildren: () => import('./pages/global/debug/debug.module').then(m => m.DebugPageModule),
      },
      {
        path: 'contact-form',
        loadChildren: () => import('./pages/global/contact-form/contact-form.module').then(m => m.ContactFormPageModule),
      },
      {
        path: 'create-lead-form',
        loadChildren: () => import('./pages/global/create-lead-form/create-lead-form.module').then(m => m.CreateLeadFormPageModule),
      },
      {
        path: 'upload-photos-to-project/:id',
        loadChildren: () => import('./pages/global/upload-photos-to-project/upload-photos-to-project.module').then(m => m.UploadPhotosToProjectPageModule),
        canActivate: [AxIsLoggedInGuard, AxIsEmployeeGuard],
      },
      {
        path: 'wochenuebersicht',
        loadChildren: () => import('./pages/global/wochenuebersicht/wochenuebersicht.module').then(m => m.WochenuebersichtPageModule),
        canActivate: [AxIsLoggedInGuard, AxIsEmployeeGuard],
      },
      {
        path: 'create-or-update-arbeitszeit',
        loadChildren: () => import('./pages/global/create-or-update-arbeitszeit/create-or-update-arbeitszeit.module').then(m => m.CreateOrUpdateArbeitszeitPageModule),
        canActivate: [AxIsLoggedInGuard, AxIsEmployeeGuard],
      },
      {
        path: 'equipment-details/:id',
        loadChildren: () => import('./pages/global/equipment-details/equipment-details.module').then(m => m.EquipmentDetailsModule),
        canActivate: [AxIsLoggedInGuard, AxIsEmployeeGuard],
      },
      {
        path: 'equipmentlog-form/:id',
        loadChildren: () => import('./pages/global/equipmentlog-form/equipmentlog-form.module').then(m => m.EquipmentlogFormModule),
        canActivate: [AxIsLoggedInGuard, AxIsEmployeeGuard],
      },
      {
        path: 'equipment-qrcode-admin/:qrcodeId',
        loadChildren: () => import('./pages/global/equipment-qrcode-admin/equipment-qrcode-admin.module').then(m => m.EquipmentQrcodeAdminModule),
        canActivate: [AxIsLoggedInGuard, AxIsEmployeeGuard],
      },
    ],
  },
  {
    path: 'absences',
    loadChildren: () => import('./pages/employees-tabs/absences/absences.module').then(m => m.AbsencesPageModule),
  },
  {
    path: 'create-or-update-absence',
    loadChildren: () => import('./pages/employees-tabs/absences/create-or-update-absence/create-or-update-absence.module').then(m => m.CreateOrUpdateAbsencePageModule),
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
