
import { FormGroup, FormControl, FormArray, Validators } from '@angular/forms';
import { requiredBoolean } from './required-boolean.validator';

export function getCreateWorkflowDocumenttypeDtoFormGroup() {
return new FormGroup({
    documenttypeId: new FormControl<number | undefined>(undefined, [Validators.required]),
    name: new FormControl<string | undefined>('', [Validators.required]),
    active: new FormControl<boolean | undefined>(true, [requiredBoolean()]),
    containertypeId: new FormControl<number | undefined>(undefined, [Validators.required]),
})
;
}
      