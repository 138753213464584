export { ApiConfiguration } from './api-configuration';
export { BaseService } from './base-service';
export { RequestBuilder } from './request-builder';
export { StrictHttpResponse } from './strict-http-response';
export { ApiModule } from './api.module';
export { AxMontageplanOrientationTypeEnum } from './models/ax-montageplan-orientation-type-enum';
export { AxAgent } from './models/ax-agent';
export { AxUseremploymentGavType } from './models/ax-useremployment-gav-type';
export { AxUseremploymentSalaryIntervalEnum } from './models/ax-useremployment-salary-interval-enum';
export { AxUseremployment } from './models/ax-useremployment';
export { AxSupplier } from './models/ax-supplier';
export { AxPowermembership } from './models/ax-powermembership';
export { AxLocation } from './models/ax-location';
export { AxTasktype } from './models/ax-tasktype';
export { AxTaskStatusEnum } from './models/ax-task-status-enum';
export { AxMontageplanLogActionEnum } from './models/ax-montageplan-log-action-enum';
export { AxMontageplanLog } from './models/ax-montageplan-log';
export { AxActivitytype } from './models/ax-activitytype';
export { AxOutcometype } from './models/ax-outcometype';
export { AxDebtor } from './models/ax-debtor';
export { AxInteractiontype } from './models/ax-interactiontype';
export { AxInteraction } from './models/ax-interaction';
export { AxTask } from './models/ax-task';
export { AxTaskgroup } from './models/ax-taskgroup';
export { AxPermission } from './models/ax-permission';
export { AxUpload } from './models/ax-upload';
export { AxEventInvitationType } from './models/ax-event-invitation-type';
export { AxEventInvitationDeclinedStatusEnum } from './models/ax-event-invitation-declined-status-enum';
export { AxEventInvitationExamResultEnum } from './models/ax-event-invitation-exam-result-enum';
export { AxEventInvitation } from './models/ax-event-invitation';
export { AxEventRegistrationSelectedOption } from './models/ax-event-registration-selected-option';
export { AxEventRegistrationOption } from './models/ax-event-registration-option';
export { AxEventRegistrationOptionGroup } from './models/ax-event-registration-option-group';
export { AxEventType } from './models/ax-event-type';
export { AxEvent } from './models/ax-event';
export { AxTimerecord } from './models/ax-timerecord';
export { AxTimerecorddetail } from './models/ax-timerecorddetail';
export { AxTimerecordtype } from './models/ax-timerecordtype';
export { AxEquipmenttype } from './models/ax-equipmenttype';
export { AxEquipmentlogtype } from './models/ax-equipmentlogtype';
export { AxEquipmentinterval } from './models/ax-equipmentinterval';
export { AxEquipment } from './models/ax-equipment';
export { AxCalculationitemsGroup } from './models/ax-calculationitems-group';
export { AxCreditorTypeEnum } from './models/ax-creditor-type-enum';
export { AxCreditor } from './models/ax-creditor';
export { AxBuildingservice } from './models/ax-buildingservice';
export { AxCalculation } from './models/ax-calculation';
export { AxProductgroup } from './models/ax-productgroup';
export { AxUnit } from './models/ax-unit';
export { AxItemInstallationLocationEnum } from './models/ax-item-installation-location-enum';
export { AxElectricalMeasurementDevice } from './models/ax-electrical-measurement-device';
export { AxAcDcEnum } from './models/ax-ac-dc-enum';
export { AxElectricalMeasurementType } from './models/ax-electrical-measurement-type';
export { AxElectricalMeasurementCable } from './models/ax-electrical-measurement-cable';
export { AxLsCharakteristikEnum } from './models/ax-ls-charakteristik-enum';
export { AxFiDeltaNEnum } from './models/ax-fi-delta-n-enum';
export { AxElectricalMeasurement } from './models/ax-electrical-measurement';
export { AxItem } from './models/ax-item';
export { AxCalculationitem } from './models/ax-calculationitem';
export { AxOrder } from './models/ax-order';
export { AxStocklocation } from './models/ax-stocklocation';
export { AxEquipmentlog } from './models/ax-equipmentlog';
export { AxStatus } from './models/ax-status';
export { AxAbsence } from './models/ax-absence';
export { AxNotification } from './models/ax-notification';
export { AxAudience } from './models/ax-audience';
export { AxComment } from './models/ax-comment';
export { AxTag } from './models/ax-tag';
export { AxNews } from './models/ax-news';
export { AxRoletype } from './models/ax-roletype';
export { AxStandardtaskgroup } from './models/ax-standardtaskgroup';
export { AxStandardtaskResponsibleUsertypeEnum } from './models/ax-standardtask-responsible-usertype-enum';
export { AxWorkflowtasktype } from './models/ax-workflowtasktype';
export { AxMessagingTemplate } from './models/ax-messaging-template';
export { AxWorkflowtask } from './models/ax-workflowtask';
export { AxWorkflow } from './models/ax-workflow';
export { AxStandardtask } from './models/ax-standardtask';
export { AxDocumentsubtype } from './models/ax-documentsubtype';
export { AxDocumenttype } from './models/ax-documenttype';
export { AxDocumentProcessingStatusEnum } from './models/ax-document-processing-status-enum';
export { AxTrainingtype } from './models/ax-trainingtype';
export { AxUsertraining } from './models/ax-usertraining';
export { AxDocument } from './models/ax-document';
export { AxUserPublicJoblevelEnum } from './models/ax-user-public-joblevel-enum';
export { AxRefreshtoken } from './models/ax-refreshtoken';
export { AxAppDevice } from './models/ax-app-device';
export { AxPushNotificationToken } from './models/ax-push-notification-token';
export { AxMailtype } from './models/ax-mailtype';
export { AxPhonetype } from './models/ax-phonetype';
export { AxPhone } from './models/ax-phone';
export { AxOnetimepassword } from './models/ax-onetimepassword';
export { AxMail } from './models/ax-mail';
export { AxCategory } from './models/ax-category';
export { AxLeadsubcategory } from './models/ax-leadsubcategory';
export { AxLeadcategory } from './models/ax-leadcategory';
export { AxTimerecordBalance } from './models/ax-timerecord-balance';
export { AxUserconfiguration } from './models/ax-userconfiguration';
export { AxUserV2 } from './models/ax-user-v-2';
export { AxTimerecordHoliday } from './models/ax-timerecord-holiday';
export { AxBusinessdivision } from './models/ax-businessdivision';
export { AxTeam } from './models/ax-team';
export { AxAuthenticator } from './models/ax-authenticator';
export { AxRealEstateType } from './models/ax-real-estate-type';
export { AxProjectAnlageSupplyTypesEnum } from './models/ax-project-anlage-supply-types-enum';
export { AxProjectAnlageTypesEnum } from './models/ax-project-anlage-types-enum';
export { AxProjectSystemTypesEnum } from './models/ax-project-system-types-enum';
export { AxProjectrevenue } from './models/ax-projectrevenue';
export { AxProject } from './models/ax-project';
export { AxContainer } from './models/ax-container';
export { AxContainertype } from './models/ax-containertype';
export { AxGooglecalendar } from './models/ax-googlecalendar';
export { AxReservationtype } from './models/ax-reservationtype';
export { AxReservation } from './models/ax-reservation';
export { AxTitle } from './models/ax-title';
export { AxArchivebox } from './models/ax-archivebox';
export { AxReferralSource } from './models/ax-referral-source';
export { AxReferral } from './models/ax-referral';
export { AxContact } from './models/ax-contact';
export { AxBusinessunit } from './models/ax-businessunit';
export { AxUser } from './models/ax-user';
export { AxUpdateUseremploymentDto } from './models/ax-update-useremployment-dto';
export { AxCreateUseremploymentDto } from './models/ax-create-useremployment-dto';
export { AxDropdownOptionDto } from './models/ax-dropdown-option-dto';
export { AxUserStatsDto } from './models/ax-user-stats-dto';
export { AxPaginatedMetaDocumented } from './models/ax-paginated-meta-documented';
export { AxPaginatedLinksDocumented } from './models/ax-paginated-links-documented';
export { AxPaginatedDocumented } from './models/ax-paginated-documented';
export { AxUsersGroupedForUserDto } from './models/ax-users-grouped-for-user-dto';
export { AxUserPublicInformationJoblevelEnum } from './models/ax-user-public-information-joblevel-enum';
export { AxUpdateUserDto } from './models/ax-update-user-dto';
export { AxCreateUserDto } from './models/ax-create-user-dto';
export { AxAddRoletypeDto } from './models/ax-add-roletype-dto';
export { AxSuccessDto } from './models/ax-success-dto';
export { AxRemoveRoletypeDto } from './models/ax-remove-roletype-dto';
export { AxUpdateProjectDto } from './models/ax-update-project-dto';
export { AxProjectPowerWrAcDto } from './models/ax-project-power-wr-ac-dto';
export { AxFoerderungLocationCommunitySponsorshipDto } from './models/ax-foerderung-location-community-sponsorship-dto';
export { AxProjectFoerderungUsedItemWithAmountDto } from './models/ax-project-foerderung-used-item-with-amount-dto';
export { AxProjectFoerderungUsedItemsWithAmountDto } from './models/ax-project-foerderung-used-items-with-amount-dto';
export { AxEivCalculationResult } from './models/ax-eiv-calculation-result';
export { AxEivCalculationsDto } from './models/ax-eiv-calculations-dto';
export { AxProjectModulesAreaDto } from './models/ax-project-modules-area-dto';
export { AxUpdateProjectAnlageUndObjektdatenDto } from './models/ax-update-project-anlage-und-objektdaten-dto';
export { AxUpdateProjectFoerderungDto } from './models/ax-update-project-foerderung-dto';
export { AxProjectWithLocationCommunitySponsorshipDto } from './models/ax-project-with-location-community-sponsorship-dto';
export { AxMontageplanUsersUserPartial } from './models/ax-montageplan-users-user-partial';
export { AxMontageplanUsersTeamPartial } from './models/ax-montageplan-users-team-partial';
export { AxTimerecordtypeNameEnum } from './models/ax-timerecordtype-name-enum';
export { AxMontageplanUsersTimerecordPartial } from './models/ax-montageplan-users-timerecord-partial';
export { AxMontageplanBlockStatusEnum } from './models/ax-montageplan-block-status-enum';
export { AxMontageplanCalculationStatusIdentifierEnum } from './models/ax-montageplan-calculation-status-identifier-enum';
export { AxMontageplanBlockStatusPartial } from './models/ax-montageplan-block-status-partial';
export { AxMontageplanCalculationStatusMessageEnum } from './models/ax-montageplan-calculation-status-message-enum';
export { AxMontageplanProjectStatusDetailModel } from './models/ax-montageplan-project-status-detail-model';
export { AxMontageplanBlockStatusDto } from './models/ax-montageplan-block-status-dto';
export { AxMontageplanLinkedProjectTypeEnum } from './models/ax-montageplan-linked-project-type-enum';
export { AxMontageplanLinkedProjectDto } from './models/ax-montageplan-linked-project-dto';
export { AxMontageplanBlockDto } from './models/ax-montageplan-block-dto';
export { AxMontageplanTeamDto } from './models/ax-montageplan-team-dto';
export { AxMontageplanDayDto } from './models/ax-montageplan-day-dto';
export { AxMontageplanUnplannedBlockDto } from './models/ax-montageplan-unplanned-block-dto';
export { AxMontageplanBlockOrientationTypeEnum } from './models/ax-montageplan-block-orientation-type-enum';
export { AxMoveMontageplanBlockBehaviourEnum } from './models/ax-move-montageplan-block-behaviour-enum';
export { AxMoveMontageplanBlockDto } from './models/ax-move-montageplan-block-dto';
export { AxInsertMontageplanBlockDto } from './models/ax-insert-montageplan-block-dto';
export { AxUpdateFixedDateDto } from './models/ax-update-fixed-date-dto';
export { AxMontageplanBlockDateDto } from './models/ax-montageplan-block-date-dto';
export { AxUpdateMontageplanBlockDatesDto } from './models/ax-update-montageplan-block-dates-dto';
export { AxMontageplanUsersDto } from './models/ax-montageplan-users-dto';
export { AxMontageplanUserAvailabilityDto } from './models/ax-montageplan-user-availability-dto';
export { AxMontageplanSyncStatusForProject } from './models/ax-montageplan-sync-status-for-project';
export { AxMontageplanSyncStatusDto } from './models/ax-montageplan-sync-status-dto';
export { AxMontageplanSyncStatusDtoEvent } from './models/ax-montageplan-sync-status-dto-event';
export { AxMontageplanLockStatusForTeam } from './models/ax-montageplan-lock-status-for-team';
export { AxMontageplanLockStatusDto } from './models/ax-montageplan-lock-status-dto';
export { AxMontageplanLockStatusDtoEvent } from './models/ax-montageplan-lock-status-dto-event';
export { AxGeruestbauProjectDto } from './models/ax-geruestbau-project-dto';
export { AxUpdateTaskDto } from './models/ax-update-task-dto';
export { AxElektroDrittkontrolleProjectDto } from './models/ax-elektro-drittkontrolle-project-dto';
export { AxDocxOutputFormatEnum } from './models/ax-docx-output-format-enum';
export { AxGenerateFromTemplateDto } from './models/ax-generate-from-template-dto';
export { AxGenerateFromImagesDto } from './models/ax-generate-from-images-dto';
export { AxCreatePhoneDto } from './models/ax-create-phone-dto';
export { AxUpdatePhoneDto } from './models/ax-update-phone-dto';
export { AxCreateNotificationDto } from './models/ax-create-notification-dto';
export { AxUpdateNotificationDto } from './models/ax-update-notification-dto';
export { AxSendMailDto } from './models/ax-send-mail-dto';
export { AxSendLeadDto } from './models/ax-send-lead-dto';
export { AxSendMailTemplateMeta } from './models/ax-send-mail-template-meta';
export { AxMailTemplateInformation } from './models/ax-mail-template-information';
export { AxAttachementUrl } from './models/ax-attachement-url';
export { AxSendMailFromTemplateDto } from './models/ax-send-mail-from-template-dto';
export { AxQrCodeContentDto } from './models/ax-qr-code-content-dto';
export { AxAuthenticateWithSecretDto } from './models/ax-authenticate-with-secret-dto';
export { AxUpdateWelcomescreenDto } from './models/ax-update-welcomescreen-dto';
export { AxItemsForProjectDto } from './models/ax-items-for-project-dto';
export { AxSearchResultMatch } from './models/ax-search-result-match';
export { AxSearchResultContactDto } from './models/ax-search-result-contact-dto';
export { AxDocumentsGroupedByDocumenttypeDto } from './models/ax-documents-grouped-by-documenttype-dto';
export { AxCreateDocumentDto } from './models/ax-create-document-dto';
export { AxCreateMailDto } from './models/ax-create-mail-dto';
export { AxUpdateMailDto } from './models/ax-update-mail-dto';
export { AxBusySlot } from './models/ax-busy-slot';
export { AxIsAvailableDto } from './models/ax-is-available-dto';
export { AxDeleteCalendarEntryDto } from './models/ax-delete-calendar-entry-dto';
export { AxInteractionRelationTypeEnum } from './models/ax-interaction-relation-type-enum';
export { AxCreateInteractionDto } from './models/ax-create-interaction-dto';
export { AxCreateInteractionActivityDto } from './models/ax-create-interaction-activity-dto';
export { AxCreateInteractionNoteDto } from './models/ax-create-interaction-note-dto';
export { AxCreateInteractionTaskDto } from './models/ax-create-interaction-task-dto';
export { AxUpdateInteractionDto } from './models/ax-update-interaction-dto';
export { AxFindFreeTimeslotsDto } from './models/ax-find-free-timeslots-dto';
export { AxTimeslot } from './models/ax-timeslot';
export { AxTimeslotsDto } from './models/ax-timeslots-dto';
export { AxCreateReservationDto } from './models/ax-create-reservation-dto';
export { AxUpdateReservationDto } from './models/ax-update-reservation-dto';
export { AxConfirmGoogleCalendarEventDto } from './models/ax-confirm-google-calendar-event-dto';
export { AxVerifyReservationOtpDto } from './models/ax-verify-reservation-otp-dto';
export { AxSendReservationFeedbackDto } from './models/ax-send-reservation-feedback-dto';
export { AxCreateAbsenceDto } from './models/ax-create-absence-dto';
export { AxUpdateAbsenceDto } from './models/ax-update-absence-dto';
export { AxCreatePushNotificationTokenDto } from './models/ax-create-push-notification-token-dto';
export { AxCreateAppDeviceDto } from './models/ax-create-app-device-dto';
export { AxAppDeviceResponseDto } from './models/ax-app-device-response-dto';
export { AxCreateAudienceDto } from './models/ax-create-audience-dto';
export { AxAuthDto } from './models/ax-auth-dto';
export { AxTokenResponseDto } from './models/ax-token-response-dto';
export { AxRefreshtokenDto } from './models/ax-refreshtoken-dto';
export { AxGetMailsAndPhonesDto } from './models/ax-get-mails-and-phones-dto';
export { AxMailOrPhone } from './models/ax-mail-or-phone';
export { AxMailsAndPhonesDto } from './models/ax-mails-and-phones-dto';
export { AxRequestOtpDto } from './models/ax-request-otp-dto';
export { AxVerifyOtpDto } from './models/ax-verify-otp-dto';
export { AxCreateCommentDto } from './models/ax-create-comment-dto';
export { AxCreateElectricalMeasurementDto } from './models/ax-create-electrical-measurement-dto';
export { AxUpdateElectricalMeasurementDto } from './models/ax-update-electrical-measurement-dto';
export { AxCreateEventDto } from './models/ax-create-event-dto';
export { AxUpdateEventDto } from './models/ax-update-event-dto';
export { AxEventInvitationStatusEnum } from './models/ax-event-invitation-status-enum';
export { AxCreateEventInvitationDto } from './models/ax-create-event-invitation-dto';
export { AxCreateEventRegistrationSelectedOptionDto } from './models/ax-create-event-registration-selected-option-dto';
export { AxUpdateEventInvitationAdminDto } from './models/ax-update-event-invitation-admin-dto';
export { AxUpdateEventInvitationOrganisatorDto } from './models/ax-update-event-invitation-organisator-dto';
export { AxCreateEventRegistrationOptionDto } from './models/ax-create-event-registration-option-dto';
export { AxCreateEventRegistrationOptionGroupDto } from './models/ax-create-event-registration-option-group-dto';
export { AxUpdateEventRegistrationOptionDto } from './models/ax-update-event-registration-option-dto';
export { AxUpdateEventRegistrationOptionGroupDto } from './models/ax-update-event-registration-option-group-dto';
export { AxLogEntryInterface } from './models/ax-log-entry-interface';
export { AxTasksForProjectDto } from './models/ax-tasks-for-project-dto';
export { AxRolesEnum } from './models/ax-roles-enum';
export { AxProfileDto } from './models/ax-profile-dto';
export { AxUpdateProfileDto } from './models/ax-update-profile-dto';
export { AxUpdateEventInvitationDto } from './models/ax-update-event-invitation-dto';
export { AxSearchResultDto } from './models/ax-search-result-dto';
export { AxDownloadTimerecordsExportDto } from './models/ax-download-timerecords-export-dto';
export { AxCreateUploadDto } from './models/ax-create-upload-dto';
export { AxCreateUploadsDto } from './models/ax-create-uploads-dto';
export { AxUpdateAbsenceV2Dto } from './models/ax-update-absence-v-2-dto';
export { AxCreateAbsenceV2Dto } from './models/ax-create-absence-v-2-dto';
export { AxAddDocumentDto } from './models/ax-add-document-dto';
export { AxRemoveDocumentDto } from './models/ax-remove-document-dto';
export { AxLogoutDto } from './models/ax-logout-dto';
export { AxVerifyOtpV2Dto } from './models/ax-verify-otp-v-2-dto';
export { AxCalculationAnalysisResultDto } from './models/ax-calculation-analysis-result-dto';
export { AxAppAccessCodeDto } from './models/ax-app-access-code-dto';
export { AxUpdateContactDto } from './models/ax-update-contact-dto';
export { AxCreateContactDto } from './models/ax-create-contact-dto';
export { AxCreateCreditorDto } from './models/ax-create-creditor-dto';
export { AxUpdateCreditorDto } from './models/ax-update-creditor-dto';
export { AxCreateDocumentV2Dto } from './models/ax-create-document-v-2-dto';
export { AxUpdateDocumentV2Dto } from './models/ax-update-document-v-2-dto';
export { AxSendDocumentsByEmailV2Dto } from './models/ax-send-documents-by-email-v-2-dto';
export { AxCreateEquipmentintervalDto } from './models/ax-create-equipmentinterval-dto';
export { AxUpdateEquipmentintervalDto } from './models/ax-update-equipmentinterval-dto';
export { AxCreateEquipmentlogDto } from './models/ax-create-equipmentlog-dto';
export { AxCreateEquipmentlogCheckinDto } from './models/ax-create-equipmentlog-checkin-dto';
export { AxCreateEquipmentlogCheckoutDto } from './models/ax-create-equipmentlog-checkout-dto';
export { AxCreateEquipmentlogDefektDto } from './models/ax-create-equipmentlog-defekt-dto';
export { AxCreateEquipmentlogReparaturDto } from './models/ax-create-equipmentlog-reparatur-dto';
export { AxUpdateEquipmentlogDto } from './models/ax-update-equipmentlog-dto';
export { AxCreateEquipmentlogtypeDto } from './models/ax-create-equipmentlogtype-dto';
export { AxUpdateEquipmentlogtypeDto } from './models/ax-update-equipmentlogtype-dto';
export { AxCreateEquipmentDto } from './models/ax-create-equipment-dto';
export { AxUpdateEquipmentDto } from './models/ax-update-equipment-dto';
export { AxAddOrRemoveDocumentToEquipmentDto } from './models/ax-add-or-remove-document-to-equipment-dto';
export { AxCreateEquipmenttypeDto } from './models/ax-create-equipmenttype-dto';
export { AxUpdateEquipmenttypeDto } from './models/ax-update-equipmenttype-dto';
export { AxUpdateEventRegistrationSelectedOptionDto } from './models/ax-update-event-registration-selected-option-dto';
export { AxCreateEventInvitationV2Dto } from './models/ax-create-event-invitation-v-2-dto';
export { AxUpdateEventInvitationV2Dto } from './models/ax-update-event-invitation-v-2-dto';
export { AxUserWithAudienceDto } from './models/ax-user-with-audience-dto';
export { AxContactWithAudienceDto } from './models/ax-contact-with-audience-dto';
export { AxNotInvitedFromAudienceDto } from './models/ax-not-invited-from-audience-dto';
export { AxAddOrRemoveDocumentToEventDto } from './models/ax-add-or-remove-document-to-event-dto';
export { AxUpdateLocationDto } from './models/ax-update-location-dto';
export { AxSearchChResultDto } from './models/ax-search-ch-result-dto';
export { AxMessagingPhone } from './models/ax-messaging-phone';
export { AxMessagingMail } from './models/ax-messaging-mail';
export { AxMessagingOptionsDto } from './models/ax-messaging-options-dto';
export { AxMessagingTemplateNameEnum } from './models/ax-messaging-template-name-enum';
export { AxMessagingTemplateDto } from './models/ax-messaging-template-dto';
export { AxProjectAppointmentDatesOverviewMessageOptionsDto } from './models/ax-project-appointment-dates-overview-message-options-dto';
export { AxSendMessageDto } from './models/ax-send-message-dto';
export { AxCreateNewsDto } from './models/ax-create-news-dto';
export { AxUpdateNewsDto } from './models/ax-update-news-dto';
export { AxUpdatePermissionDto } from './models/ax-update-permission-dto';
export { AxCreatePermissionDto } from './models/ax-create-permission-dto';
export { AxReferralSummaryDto } from './models/ax-referral-summary-dto';
export { AxCreatedReferralCodeDto } from './models/ax-created-referral-code-dto';
export { AxProjectWithRevenueAndDeckungsbeitragDto } from './models/ax-project-with-revenue-and-deckungsbeitrag-dto';
export { AxRevenueByYearAndMonthDto } from './models/ax-revenue-by-year-and-month-dto';
export { AxBuildsByContainerIdYearAndMonthDto } from './models/ax-builds-by-container-id-year-and-month-dto';
export { AxActualTargetAndSaldo } from './models/ax-actual-target-and-saldo';
export { AxSubtotalSaldo } from './models/ax-subtotal-saldo';
export { AxTimerecordBalancesByYearMonthAndUserIdDto } from './models/ax-timerecord-balances-by-year-month-and-user-id-dto';
export { AxUploadBankFileDto } from './models/ax-upload-bank-file-dto';
export { AxTransformedBankFileDto } from './models/ax-transformed-bank-file-dto';
export { AxKrankheitUnfallTageDto } from './models/ax-krankheit-unfall-tage-dto';
export { AxUseremploymentTypeEnum } from './models/ax-useremployment-type-enum';
export { AxLeadconversionItemDto } from './models/ax-leadconversion-item-dto';
export { AxLeadConversionDto } from './models/ax-lead-conversion-dto';
export { AxSpesentageDto } from './models/ax-spesentage-dto';
export { AxWegspesenDetails } from './models/ax-wegspesen-details';
export { AxWegspesenDto } from './models/ax-wegspesen-dto';
export { AxLohnlisteEntryDto } from './models/ax-lohnliste-entry-dto';
export { AxPerformanceKundenberaterDto } from './models/ax-performance-kundenberater-dto';
export { AxSalesForPvByUserDto } from './models/ax-sales-for-pv-by-user-dto';
export { AxSalesForPvDto } from './models/ax-sales-for-pv-dto';
export { AxSendEmailToAgentsDto } from './models/ax-send-email-to-agents-dto';
export { AxAggregatedTimerecordBalanceDto } from './models/ax-aggregated-timerecord-balance-dto';
export { AxUpdateTimerecordBalanceDto } from './models/ax-update-timerecord-balance-dto';
export { AxUpdateRoletypeDto } from './models/ax-update-roletype-dto';
export { AxCreateRoletypeDto } from './models/ax-create-roletype-dto';
export { AxAddUserDto } from './models/ax-add-user-dto';
export { AxRemoveUserDto } from './models/ax-remove-user-dto';
export { AxAddPermissionDto } from './models/ax-add-permission-dto';
export { AxRemovePermissionDto } from './models/ax-remove-permission-dto';
export { AxScreendevice } from './models/ax-screendevice';
export { AxScreencomponent } from './models/ax-screencomponent';
export { AxScreenslideComponent } from './models/ax-screenslide-component';
export { AxScreenslide } from './models/ax-screenslide';
export { AxScreendeviceSlide } from './models/ax-screendevice-slide';
export { AxUpdateScreendeviceDto } from './models/ax-update-screendevice-dto';
export { AxCreateScreendeviceDto } from './models/ax-create-screendevice-dto';
export { AxRegisterScreendeviceDto } from './models/ax-register-screendevice-dto';
export { AxConfirmScreendeviceResponseDto } from './models/ax-confirm-screendevice-response-dto';
export { AxQrcodeData } from './models/ax-qrcode-data';
export { AxQrcodeDto } from './models/ax-qrcode-dto';
export { AxUpdateScreenslideDto } from './models/ax-update-screenslide-dto';
export { AxCreateScreenslideDto } from './models/ax-create-screenslide-dto';
export { AxUpdateScreencomponentDto } from './models/ax-update-screencomponent-dto';
export { AxCreateScreencomponentDto } from './models/ax-create-screencomponent-dto';
export { AxContactAndProjectsResultDto } from './models/ax-contact-and-projects-result-dto';
export { AxItemAndMatchDto } from './models/ax-item-and-match-dto';
export { AxUserAndMatchDto } from './models/ax-user-and-match-dto';
export { AxEquipmentAndMatchDto } from './models/ax-equipment-and-match-dto';
export { AxStocklocationAndMatchDto } from './models/ax-stocklocation-and-match-dto';
export { AxMegasearchResultDto } from './models/ax-megasearch-result-dto';
export { AxSearchCategoryEnum } from './models/ax-search-category-enum';
export { AxSearchIndexKeyEnum } from './models/ax-search-index-key-enum';
export { AxSearchindexStatusForIndex } from './models/ax-searchindex-status-for-index';
export { AxSearchindexStatusDto } from './models/ax-searchindex-status-dto';
export { AxSearchindexStatusDtoEvent } from './models/ax-searchindex-status-dto-event';
export { AxUpdateStocklocationDto } from './models/ax-update-stocklocation-dto';
export { AxCreateStocklocationDto } from './models/ax-create-stocklocation-dto';
export { AxAddStandardTaskGroupsToProjectDto } from './models/ax-add-standard-task-groups-to-project-dto';
export { AxStatusSelectorEnum } from './models/ax-status-selector-enum';
export { AxUpdateTagDto } from './models/ax-update-tag-dto';
export { AxCreateTagDto } from './models/ax-create-tag-dto';
export { AxCreateTaskDto } from './models/ax-create-task-dto';
export { AxUpdateTaskSortableRankDto } from './models/ax-update-task-sortable-rank-dto';
export { AxTeammembershipDto } from './models/ax-teammembership-dto';
export { AxHasTimerecorddetailsByUserIdAndDateDto } from './models/ax-has-timerecorddetails-by-user-id-and-date-dto';
export { AxTimerecorddetailsByDateDto } from './models/ax-timerecorddetails-by-date-dto';
export { AxTimerecorddetailsByTimerecordtypeDto } from './models/ax-timerecorddetails-by-timerecordtype-dto';
export { AxCreateTimerecorddetailDto } from './models/ax-create-timerecorddetail-dto';
export { AxUpdateTimerecorddetailDto } from './models/ax-update-timerecorddetail-dto';
export { AxDeleteTimerecorddetailDto } from './models/ax-delete-timerecorddetail-dto';
export { AxCreateTimerecordHolidayDto } from './models/ax-create-timerecord-holiday-dto';
export { AxUpdateTimerecordHolidayDto } from './models/ax-update-timerecord-holiday-dto';
export { AxUpdateTimerecordDto } from './models/ax-update-timerecord-dto';
export { AxCreateOrUpdateUserconfigurationDto } from './models/ax-create-or-update-userconfiguration-dto';
export { AxUpdateUsertrainingDto } from './models/ax-update-usertraining-dto';
export { AxCreateUsertrainingDto } from './models/ax-create-usertraining-dto';
export { AxGoogleReviewDto } from './models/ax-google-review-dto';
export { AxGoogleReviewOverallDto } from './models/ax-google-review-overall-dto';
export { AxCompanyStatisticDto } from './models/ax-company-statistic-dto';
export { AxWebsiteEmployeeTagDto } from './models/ax-website-employee-tag-dto';
export { AxEmployeeWebsiteDto } from './models/ax-employee-website-dto';
export { AxEmployeeGroupWebsiteDto } from './models/ax-employee-group-website-dto';
export { AxCreateWorkflowDocumenttypeDto } from './models/ax-create-workflow-documenttype-dto';
export { AxCreateWorkflowStandardtaskDto } from './models/ax-create-workflow-standardtask-dto';
export { AxUpdateWorkflowDocumenttypeDto } from './models/ax-update-workflow-documenttype-dto';
export { AxUpdateWorkflowStandardtaskDto } from './models/ax-update-workflow-standardtask-dto';
export { AxCreateWorkflowtaskDto } from './models/ax-create-workflowtask-dto';
export { AxUpdateWorkflowtaskDto } from './models/ax-update-workflowtask-dto';
export { AxUpdateWorkflowtaskSortableRankDto } from './models/ax-update-workflowtask-sortable-rank-dto';
export { AxApiService } from './services/ax-api.service';
export { AxUseremploymentsV2Service } from './services/ax-useremployments-v-2.service';
export { AxUsersV2Service } from './services/ax-users-v-2.service';
export { AxProjectsV2Service } from './services/ax-projects-v-2.service';
export { AxMontageplanV2Service } from './services/ax-montageplan-v-2.service';
export { AxGerustbauV2Service } from './services/ax-gerustbau-v-2.service';
export { AxElektroDrittkontrolleV2Service } from './services/ax-elektro-drittkontrolle-v-2.service';
export { AxDocumentsGeneratorService } from './services/ax-documents-generator.service';
export { AxUsersService } from './services/ax-users.service';
export { AxPhonesV2Service } from './services/ax-phones-v-2.service';
export { AxNotificationsV2Service } from './services/ax-notifications-v-2.service';
export { AxHelpersService } from './services/ax-helpers.service';
export { AxProjectsService } from './services/ax-projects.service';
export { AxContactsService } from './services/ax-contacts.service';
export { AxDocumentsService } from './services/ax-documents.service';
export { AxMailsV2Service } from './services/ax-mails-v-2.service';
export { AxGoogleCalendarApiService } from './services/ax-google-calendar-api.service';
export { AxInteractionsV2Service } from './services/ax-interactions-v-2.service';
export { AxReservationsService } from './services/ax-reservations.service';
export { AxReservationtypesService } from './services/ax-reservationtypes.service';
export { AxLocationsService } from './services/ax-locations.service';
export { AxAbsencesService } from './services/ax-absences.service';
export { AxAppDevicesService } from './services/ax-app-devices.service';
export { AxPushNotificationTokensService } from './services/ax-push-notification-tokens.service';
export { AxAudiencesService } from './services/ax-audiences.service';
export { AxAuthenticationService } from './services/ax-authentication.service';
export { AxOnetimepasswordsV2Service } from './services/ax-onetimepasswords-v-2.service';
export { AxCategoriesService } from './services/ax-categories.service';
export { AxCommentsService } from './services/ax-comments.service';
export { AxNewsService } from './services/ax-news.service';
export { AxDocumentsubtypesService } from './services/ax-documentsubtypes.service';
export { AxElectricalMeasurementsService } from './services/ax-electrical-measurements.service';
export { AxEventsService } from './services/ax-events.service';
export { AxEventInvitationsService } from './services/ax-event-invitations.service';
export { AxEventTypesService } from './services/ax-event-types.service';
export { AxEventRegistrationOptionsService } from './services/ax-event-registration-options.service';
export { AxEventInvitationtypesService } from './services/ax-event-invitationtypes.service';
export { AxFilesService } from './services/ax-files.service';
export { AxHealthService } from './services/ax-health.service';
export { AxItemsService } from './services/ax-items.service';
export { AxLoggingService } from './services/ax-logging.service';
export { AxMyService } from './services/ax-my.service';
export { AxProjectrevenuesService } from './services/ax-projectrevenues.service';
export { AxRoletypesService } from './services/ax-roletypes.service';
export { AxSearchService } from './services/ax-search.service';
export { AxStatusesService } from './services/ax-statuses.service';
export { AxTimerecorddetailsService } from './services/ax-timerecorddetails.service';
export { AxTimerecordtypesService } from './services/ax-timerecordtypes.service';
export { AxUploadsService } from './services/ax-uploads.service';
export { AxAbsencesV2Service } from './services/ax-absences-v-2.service';
export { AxActivityTypesV2Service } from './services/ax-activity-types-v-2.service';
export { AxAgentsV2Service } from './services/ax-agents-v-2.service';
export { AxAppDevicesV2Service } from './services/ax-app-devices-v-2.service';
export { AxArchiveboxesV2Service } from './services/ax-archiveboxes-v-2.service';
export { AxAudiencesV2Service } from './services/ax-audiences-v-2.service';
export { AxAuthenticationV2Service } from './services/ax-authentication-v-2.service';
export { AxAuthenticatorsV2Service } from './services/ax-authenticators-v-2.service';
export { AxBusinessdivisionsV2Service } from './services/ax-businessdivisions-v-2.service';
export { AxBusinessUnitsV2Service } from './services/ax-business-units-v-2.service';
export { AxCalculationsV2Service } from './services/ax-calculations-v-2.service';
export { AxCategoriesV2Service } from './services/ax-categories-v-2.service';
export { AxContactsV2Service } from './services/ax-contacts-v-2.service';
export { AxContainersV2Service } from './services/ax-containers-v-2.service';
export { AxContainertypesV2Service } from './services/ax-containertypes-v-2.service';
export { AxCreditorsV2Service } from './services/ax-creditors-v-2.service';
export { AxDebtorsV2Service } from './services/ax-debtors-v-2.service';
export { AxDocumentsV2Service } from './services/ax-documents-v-2.service';
export { AxDocumentsGeneratorV2Service } from './services/ax-documents-generator-v-2.service';
export { AxParashiftV2Service } from './services/ax-parashift-v-2.service';
export { AxDocumentsubtypesV2Service } from './services/ax-documentsubtypes-v-2.service';
export { AxDocumenttypesV2Service } from './services/ax-documenttypes-v-2.service';
export { AxEquipmentintervalsV2Service } from './services/ax-equipmentintervals-v-2.service';
export { AxEquipmentlogsV2Service } from './services/ax-equipmentlogs-v-2.service';
export { AxEquipmentlogtypesV2Service } from './services/ax-equipmentlogtypes-v-2.service';
export { AxEquipmentsV2Service } from './services/ax-equipments-v-2.service';
export { AxEquipmenttypesV2Service } from './services/ax-equipmenttypes-v-2.service';
export { AxEventInvitationsV2Service } from './services/ax-event-invitations-v-2.service';
export { AxEventsV2Service } from './services/ax-events-v-2.service';
export { AxItemsV2Service } from './services/ax-items-v-2.service';
export { AxLeadCategoriesV2Service } from './services/ax-lead-categories-v-2.service';
export { AxLeadSubCategoriesV2Service } from './services/ax-lead-sub-categories-v-2.service';
export { AxLocationsV2Service } from './services/ax-locations-v-2.service';
export { AxMailtypesV2Service } from './services/ax-mailtypes-v-2.service';
export { AxMessagingV2Service } from './services/ax-messaging-v-2.service';
export { AxNewsV2Service } from './services/ax-news-v-2.service';
export { AxOutcometypesV2Service } from './services/ax-outcometypes-v-2.service';
export { AxPermissionsV2Service } from './services/ax-permissions-v-2.service';
export { AxPhonetypesV2Service } from './services/ax-phonetypes-v-2.service';
export { AxRealEstateTypesV2Service } from './services/ax-real-estate-types-v-2.service';
export { AxReferralsV2Service } from './services/ax-referrals-v-2.service';
export { AxReportsV2Service } from './services/ax-reports-v-2.service';
export { AxTimerecordBalancesV2Service } from './services/ax-timerecord-balances-v-2.service';
export { AxReservationtypesV2Service } from './services/ax-reservationtypes-v-2.service';
export { AxRoletypesV2Service } from './services/ax-roletypes-v-2.service';
export { AxScreendevicesV2Service } from './services/ax-screendevices-v-2.service';
export { AxScreenslidesV2Service } from './services/ax-screenslides-v-2.service';
export { AxScreencomponentsV2Service } from './services/ax-screencomponents-v-2.service';
export { AxSearchV2Service } from './services/ax-search-v-2.service';
export { AxStocklocationsV2Service } from './services/ax-stocklocations-v-2.service';
export { AxStandardtasksV2Service } from './services/ax-standardtasks-v-2.service';
export { AxStandardtaskgroupsV2Service } from './services/ax-standardtaskgroups-v-2.service';
export { AxStatusV2Service } from './services/ax-status-v-2.service';
export { AxSuppliersV2Service } from './services/ax-suppliers-v-2.service';
export { AxTagsV2Service } from './services/ax-tags-v-2.service';
export { AxTasksV2Service } from './services/ax-tasks-v-2.service';
export { AxTasktypesV2Service } from './services/ax-tasktypes-v-2.service';
export { AxTeammembershipsV2Service } from './services/ax-teammemberships-v-2.service';
export { AxTeamsV2Service } from './services/ax-teams-v-2.service';
export { AxTimerecorddetailsV2Service } from './services/ax-timerecorddetails-v-2.service';
export { AxTimerecordHolidaysV2Service } from './services/ax-timerecord-holidays-v-2.service';
export { AxTimerecordsV2Service } from './services/ax-timerecords-v-2.service';
export { AxTimerecordtypesV2Service } from './services/ax-timerecordtypes-v-2.service';
export { AxTitlesV2Service } from './services/ax-titles-v-2.service';
export { AxTrainingtypesV2Service } from './services/ax-trainingtypes-v-2.service';
export { AxUserconfigurationsV2Service } from './services/ax-userconfigurations-v-2.service';
export { AxUsertrainingsV2Service } from './services/ax-usertrainings-v-2.service';
export { AxWebsiteGoogleReviewsV2Service } from './services/ax-website-google-reviews-v-2.service';
export { AxWebsiteStatisticsV2Service } from './services/ax-website-statistics-v-2.service';
export { AxWebsiteEmployeesV2Service } from './services/ax-website-employees-v-2.service';
export { AxWebsiteArticlesAndBannersV2Service } from './services/ax-website-articles-and-banners-v-2.service';
export { AxWorkflowsV2Service } from './services/ax-workflows-v-2.service';
export { AxWorkflowtasksV2Service } from './services/ax-workflowtasks-v-2.service';
export { AxWorkflowtasktypesV2Service } from './services/ax-workflowtasktypes-v-2.service';


export * from './formgroups/_index';

