/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { AxAddOrRemoveDocumentToEquipmentDto } from '../models/ax-add-or-remove-document-to-equipment-dto';
import { AxCreateEquipmentDto } from '../models/ax-create-equipment-dto';
import { AxDocument } from '../models/ax-document';
import { AxEquipment } from '../models/ax-equipment';
import { AxPaginatedDocumented } from '../models/ax-paginated-documented';
import { AxSuccessDto } from '../models/ax-success-dto';
import { AxUpdateEquipmentDto } from '../models/ax-update-equipment-dto';

@Injectable({ providedIn: 'root' })
export class AxEquipmentsV2Service extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `equipmentsControllerFindAllPaginated()` */
  static readonly EquipmentsControllerFindAllPaginatedPath = '/v2/equipments';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `equipmentsControllerFindAllPaginated()` instead.
   *
   * This method doesn't expect any request body.
   */
  equipmentsControllerFindAllPaginated$Response(
    params?: {

    /**
     * Page number to retrieve.If you provide invalid value the default page number will applied
     *         <p>
     *              <b>Example: </b> 1
     *           </p>
     *         <p>
     *              <b>Default Value: </b> 1
     *           </p>
     */
      page?: number;

    /**
     * Number of records per page.
     *       <p>
     *              <b>Example: </b> 20
     *           </p>
     *       <p>
     *              <b>Default Value: </b> 25
     *           </p>
     *       <p>
     *              <b>Max Value: </b> 100
     *           </p>
     *
     *       If provided value is greater than max value, max value will be applied.
     */
      limit?: number;

    /**
     * Filter by active query param.
     *           <p>
     *              <b>Format: </b> filter.active={$not}:OPERATION:VALUE
     *           </p>
     *           <p>
     *              <b>Example: </b> filter.active=$not:$like:John Doe&filter.active=like:John
     *           </p>
     *           <h4>Available Operations</h4><ul><li>$eq</li></ul>
     */
      'filter.active'?: Array<string>;

    /**
     * Filter by equipmenttypeId query param.
     *           <p>
     *              <b>Format: </b> filter.equipmenttypeId={$not}:OPERATION:VALUE
     *           </p>
     *           <p>
     *              <b>Example: </b> filter.equipmenttypeId=$not:$like:John Doe&filter.equipmenttypeId=like:John
     *           </p>
     *           <h4>Available Operations</h4><ul><li>$eq</li></ul>
     */
      'filter.equipmenttypeId'?: Array<string>;

    /**
     * Filter by businessunitId query param.
     *           <p>
     *              <b>Format: </b> filter.businessunitId={$not}:OPERATION:VALUE
     *           </p>
     *           <p>
     *              <b>Example: </b> filter.businessunitId=$not:$like:John Doe&filter.businessunitId=like:John
     *           </p>
     *           <h4>Available Operations</h4><ul><li>$eq</li></ul>
     */
      'filter.businessunitId'?: Array<string>;

    /**
     * Filter by businessdivisionId query param.
     *           <p>
     *              <b>Format: </b> filter.businessdivisionId={$not}:OPERATION:VALUE
     *           </p>
     *           <p>
     *              <b>Example: </b> filter.businessdivisionId=$not:$like:John Doe&filter.businessdivisionId=like:John
     *           </p>
     *           <h4>Available Operations</h4><ul><li>$eq</li></ul>
     */
      'filter.businessdivisionId'?: Array<string>;

    /**
     * Filter by parentId query param.
     *           <p>
     *              <b>Format: </b> filter.parentId={$not}:OPERATION:VALUE
     *           </p>
     *           <p>
     *              <b>Example: </b> filter.parentId=$not:$like:John Doe&filter.parentId=like:John
     *           </p>
     *           <h4>Available Operations</h4><ul><li>$eq</li>
     * <li>$null</li></ul>
     */
      'filter.parentId'?: Array<string>;

    /**
     * Filter by parentId.parentId query param.
     *           <p>
     *              <b>Format: </b> filter.parentId.parentId={$not}:OPERATION:VALUE
     *           </p>
     *           <p>
     *              <b>Example: </b> filter.parentId.parentId=$not:$like:John Doe&filter.parentId.parentId=like:John
     *           </p>
     *           <h4>Available Operations</h4><ul><li>$eq</li>
     * <li>$null</li></ul>
     */
      'filter.parentId.parentId'?: Array<string>;

    /**
     * Parameter to sort by.
     *       <p>To sort by multiple fields, just provide query param multiple types. The order in url defines an order of sorting</p>
     *       <p>
     *              <b>Format: </b> fieldName:DIRECTION
     *           </p>
     *       <p>
     *              <b>Example: </b> sortBy=id:DESC&sortBy=createdAt:ASC
     *           </p>
     *       <p>
     *              <b>Default Value: </b> created:DESC
     *           </p>
     *       <h4>Available Fields</h4><ul><li>equipmenttypeId</li>
     * <li>brand</li>
     * <li>model</li>
     * <li>identifier</li></ul>
     */
      sortBy?: Array<string>;

    /**
     * Search term to filter result values
     *         <p>
     *              <b>Example: </b> John
     *           </p>
     *         <p>
     *              <b>Default Value: </b> No default value
     *           </p>
     */
      search?: string;

    /**
     * List of fields to search by term to filter result values
     *         <p>
     *              <b>Example: </b> brand,model,identifier,equipmenttype.name,vendor.company
     *           </p>
     *         <p>
     *              <b>Default Value: </b> By default all fields mentioned below will be used to search by term
     *           </p>
     *         <h4>Available Fields</h4><ul><li>brand</li>
     * <li>model</li>
     * <li>identifier</li>
     * <li>equipmenttype.name</li>
     * <li>vendor.company</li></ul>
     */
      searchBy?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AxPaginatedDocumented & {
'data'?: Array<AxEquipment>;
'meta'?: {
'select'?: Array<string>;
'filter'?: {
'active'?: (string | Array<string>);
'equipmenttypeId'?: (string | Array<string>);
'businessunitId'?: (string | Array<string>);
'businessdivisionId'?: (string | Array<string>);
'parentId'?: (string | Array<string>);
'parentId.parentId'?: (string | Array<string>);
};
};
}>> {
    const rb = new RequestBuilder(this.rootUrl, AxEquipmentsV2Service.EquipmentsControllerFindAllPaginatedPath, 'get');
    if (params) {
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.query('filter.active', params['filter.active'], {});
      rb.query('filter.equipmenttypeId', params['filter.equipmenttypeId'], {});
      rb.query('filter.businessunitId', params['filter.businessunitId'], {});
      rb.query('filter.businessdivisionId', params['filter.businessdivisionId'], {});
      rb.query('filter.parentId', params['filter.parentId'], {});
      rb.query('filter.parentId.parentId', params['filter.parentId.parentId'], {});
      rb.query('sortBy', params.sortBy, {});
      rb.query('search', params.search, {});
      rb.query('searchBy', params.searchBy, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AxPaginatedDocumented & {
        'data'?: Array<AxEquipment>;
        'meta'?: {
        'select'?: Array<string>;
        'filter'?: {
        'active'?: (string | Array<string>);
        'equipmenttypeId'?: (string | Array<string>);
        'businessunitId'?: (string | Array<string>);
        'businessdivisionId'?: (string | Array<string>);
        'parentId'?: (string | Array<string>);
        'parentId.parentId'?: (string | Array<string>);
        };
        };
        }>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `equipmentsControllerFindAllPaginated$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  equipmentsControllerFindAllPaginated(
    params?: {

    /**
     * Page number to retrieve.If you provide invalid value the default page number will applied
     *         <p>
     *              <b>Example: </b> 1
     *           </p>
     *         <p>
     *              <b>Default Value: </b> 1
     *           </p>
     */
      page?: number;

    /**
     * Number of records per page.
     *       <p>
     *              <b>Example: </b> 20
     *           </p>
     *       <p>
     *              <b>Default Value: </b> 25
     *           </p>
     *       <p>
     *              <b>Max Value: </b> 100
     *           </p>
     *
     *       If provided value is greater than max value, max value will be applied.
     */
      limit?: number;

    /**
     * Filter by active query param.
     *           <p>
     *              <b>Format: </b> filter.active={$not}:OPERATION:VALUE
     *           </p>
     *           <p>
     *              <b>Example: </b> filter.active=$not:$like:John Doe&filter.active=like:John
     *           </p>
     *           <h4>Available Operations</h4><ul><li>$eq</li></ul>
     */
      'filter.active'?: Array<string>;

    /**
     * Filter by equipmenttypeId query param.
     *           <p>
     *              <b>Format: </b> filter.equipmenttypeId={$not}:OPERATION:VALUE
     *           </p>
     *           <p>
     *              <b>Example: </b> filter.equipmenttypeId=$not:$like:John Doe&filter.equipmenttypeId=like:John
     *           </p>
     *           <h4>Available Operations</h4><ul><li>$eq</li></ul>
     */
      'filter.equipmenttypeId'?: Array<string>;

    /**
     * Filter by businessunitId query param.
     *           <p>
     *              <b>Format: </b> filter.businessunitId={$not}:OPERATION:VALUE
     *           </p>
     *           <p>
     *              <b>Example: </b> filter.businessunitId=$not:$like:John Doe&filter.businessunitId=like:John
     *           </p>
     *           <h4>Available Operations</h4><ul><li>$eq</li></ul>
     */
      'filter.businessunitId'?: Array<string>;

    /**
     * Filter by businessdivisionId query param.
     *           <p>
     *              <b>Format: </b> filter.businessdivisionId={$not}:OPERATION:VALUE
     *           </p>
     *           <p>
     *              <b>Example: </b> filter.businessdivisionId=$not:$like:John Doe&filter.businessdivisionId=like:John
     *           </p>
     *           <h4>Available Operations</h4><ul><li>$eq</li></ul>
     */
      'filter.businessdivisionId'?: Array<string>;

    /**
     * Filter by parentId query param.
     *           <p>
     *              <b>Format: </b> filter.parentId={$not}:OPERATION:VALUE
     *           </p>
     *           <p>
     *              <b>Example: </b> filter.parentId=$not:$like:John Doe&filter.parentId=like:John
     *           </p>
     *           <h4>Available Operations</h4><ul><li>$eq</li>
     * <li>$null</li></ul>
     */
      'filter.parentId'?: Array<string>;

    /**
     * Filter by parentId.parentId query param.
     *           <p>
     *              <b>Format: </b> filter.parentId.parentId={$not}:OPERATION:VALUE
     *           </p>
     *           <p>
     *              <b>Example: </b> filter.parentId.parentId=$not:$like:John Doe&filter.parentId.parentId=like:John
     *           </p>
     *           <h4>Available Operations</h4><ul><li>$eq</li>
     * <li>$null</li></ul>
     */
      'filter.parentId.parentId'?: Array<string>;

    /**
     * Parameter to sort by.
     *       <p>To sort by multiple fields, just provide query param multiple types. The order in url defines an order of sorting</p>
     *       <p>
     *              <b>Format: </b> fieldName:DIRECTION
     *           </p>
     *       <p>
     *              <b>Example: </b> sortBy=id:DESC&sortBy=createdAt:ASC
     *           </p>
     *       <p>
     *              <b>Default Value: </b> created:DESC
     *           </p>
     *       <h4>Available Fields</h4><ul><li>equipmenttypeId</li>
     * <li>brand</li>
     * <li>model</li>
     * <li>identifier</li></ul>
     */
      sortBy?: Array<string>;

    /**
     * Search term to filter result values
     *         <p>
     *              <b>Example: </b> John
     *           </p>
     *         <p>
     *              <b>Default Value: </b> No default value
     *           </p>
     */
      search?: string;

    /**
     * List of fields to search by term to filter result values
     *         <p>
     *              <b>Example: </b> brand,model,identifier,equipmenttype.name,vendor.company
     *           </p>
     *         <p>
     *              <b>Default Value: </b> By default all fields mentioned below will be used to search by term
     *           </p>
     *         <h4>Available Fields</h4><ul><li>brand</li>
     * <li>model</li>
     * <li>identifier</li>
     * <li>equipmenttype.name</li>
     * <li>vendor.company</li></ul>
     */
      searchBy?: string;
    },
    context?: HttpContext
  ): Observable<AxPaginatedDocumented & {
'data'?: Array<AxEquipment>;
'meta'?: {
'select'?: Array<string>;
'filter'?: {
'active'?: (string | Array<string>);
'equipmenttypeId'?: (string | Array<string>);
'businessunitId'?: (string | Array<string>);
'businessdivisionId'?: (string | Array<string>);
'parentId'?: (string | Array<string>);
'parentId.parentId'?: (string | Array<string>);
};
};
}> {
    return this.equipmentsControllerFindAllPaginated$Response(params, context).pipe(
      map((r: StrictHttpResponse<AxPaginatedDocumented & {
'data'?: Array<AxEquipment>;
'meta'?: {
'select'?: Array<string>;
'filter'?: {
'active'?: (string | Array<string>);
'equipmenttypeId'?: (string | Array<string>);
'businessunitId'?: (string | Array<string>);
'businessdivisionId'?: (string | Array<string>);
'parentId'?: (string | Array<string>);
'parentId.parentId'?: (string | Array<string>);
};
};
}>): AxPaginatedDocumented & {
'data'?: Array<AxEquipment>;
'meta'?: {
'select'?: Array<string>;
'filter'?: {
'active'?: (string | Array<string>);
'equipmenttypeId'?: (string | Array<string>);
'businessunitId'?: (string | Array<string>);
'businessdivisionId'?: (string | Array<string>);
'parentId'?: (string | Array<string>);
'parentId.parentId'?: (string | Array<string>);
};
};
} => r.body)
    );
  }

  /** Path part for operation `equipmentsControllerCreate()` */
  static readonly EquipmentsControllerCreatePath = '/v2/equipments';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `equipmentsControllerCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  equipmentsControllerCreate$Response(
    params: {
      body: AxCreateEquipmentDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AxEquipment>> {
    const rb = new RequestBuilder(this.rootUrl, AxEquipmentsV2Service.EquipmentsControllerCreatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AxEquipment>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `equipmentsControllerCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  equipmentsControllerCreate(
    params: {
      body: AxCreateEquipmentDto
    },
    context?: HttpContext
  ): Observable<AxEquipment> {
    return this.equipmentsControllerCreate$Response(params, context).pipe(
      map((r: StrictHttpResponse<AxEquipment>): AxEquipment => r.body)
    );
  }

  /** Path part for operation `equipmentsControllerFindOneById()` */
  static readonly EquipmentsControllerFindOneByIdPath = '/v2/equipments/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `equipmentsControllerFindOneById()` instead.
   *
   * This method doesn't expect any request body.
   */
  equipmentsControllerFindOneById$Response(
    params: {
      id: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AxEquipment>> {
    const rb = new RequestBuilder(this.rootUrl, AxEquipmentsV2Service.EquipmentsControllerFindOneByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AxEquipment>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `equipmentsControllerFindOneById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  equipmentsControllerFindOneById(
    params: {
      id: number;
    },
    context?: HttpContext
  ): Observable<AxEquipment> {
    return this.equipmentsControllerFindOneById$Response(params, context).pipe(
      map((r: StrictHttpResponse<AxEquipment>): AxEquipment => r.body)
    );
  }

  /** Path part for operation `equipmentsControllerDelete()` */
  static readonly EquipmentsControllerDeletePath = '/v2/equipments/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `equipmentsControllerDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  equipmentsControllerDelete$Response(
    params: {
      id: number;
      includingChildren: boolean;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, AxEquipmentsV2Service.EquipmentsControllerDeletePath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('includingChildren', params.includingChildren, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `equipmentsControllerDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  equipmentsControllerDelete(
    params: {
      id: number;
      includingChildren: boolean;
    },
    context?: HttpContext
  ): Observable<void> {
    return this.equipmentsControllerDelete$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `equipmentsControllerUpdate()` */
  static readonly EquipmentsControllerUpdatePath = '/v2/equipments/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `equipmentsControllerUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  equipmentsControllerUpdate$Response(
    params: {
      id: number;
      body: AxUpdateEquipmentDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AxEquipment>> {
    const rb = new RequestBuilder(this.rootUrl, AxEquipmentsV2Service.EquipmentsControllerUpdatePath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AxEquipment>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `equipmentsControllerUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  equipmentsControllerUpdate(
    params: {
      id: number;
      body: AxUpdateEquipmentDto
    },
    context?: HttpContext
  ): Observable<AxEquipment> {
    return this.equipmentsControllerUpdate$Response(params, context).pipe(
      map((r: StrictHttpResponse<AxEquipment>): AxEquipment => r.body)
    );
  }

  /** Path part for operation `equipmentsControllerFindAllByUserId()` */
  static readonly EquipmentsControllerFindAllByUserIdPath = '/v2/equipments/byUserId';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `equipmentsControllerFindAllByUserId()` instead.
   *
   * This method doesn't expect any request body.
   */
  equipmentsControllerFindAllByUserId$Response(
    params?: {
      userId?: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<AxEquipment>>> {
    const rb = new RequestBuilder(this.rootUrl, AxEquipmentsV2Service.EquipmentsControllerFindAllByUserIdPath, 'get');
    if (params) {
      rb.query('userId', params.userId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AxEquipment>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `equipmentsControllerFindAllByUserId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  equipmentsControllerFindAllByUserId(
    params?: {
      userId?: number;
    },
    context?: HttpContext
  ): Observable<Array<AxEquipment>> {
    return this.equipmentsControllerFindAllByUserId$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<AxEquipment>>): Array<AxEquipment> => r.body)
    );
  }

  /** Path part for operation `equipmentsControllerFindOneByQrcodeId()` */
  static readonly EquipmentsControllerFindOneByQrcodeIdPath = '/v2/equipments/byQrcodeId/{qrcodeId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `equipmentsControllerFindOneByQrcodeId()` instead.
   *
   * This method doesn't expect any request body.
   */
  equipmentsControllerFindOneByQrcodeId$Response(
    params: {
      qrcodeId: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AxEquipment>> {
    const rb = new RequestBuilder(this.rootUrl, AxEquipmentsV2Service.EquipmentsControllerFindOneByQrcodeIdPath, 'get');
    if (params) {
      rb.path('qrcodeId', params.qrcodeId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AxEquipment>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `equipmentsControllerFindOneByQrcodeId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  equipmentsControllerFindOneByQrcodeId(
    params: {
      qrcodeId: number;
    },
    context?: HttpContext
  ): Observable<AxEquipment> {
    return this.equipmentsControllerFindOneByQrcodeId$Response(params, context).pipe(
      map((r: StrictHttpResponse<AxEquipment>): AxEquipment => r.body)
    );
  }

  /** Path part for operation `equipmentsControllerUnlink()` */
  static readonly EquipmentsControllerUnlinkPath = '/v2/equipments/unlink/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `equipmentsControllerUnlink()` instead.
   *
   * This method doesn't expect any request body.
   */
  equipmentsControllerUnlink$Response(
    params: {
      id: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AxEquipment>> {
    const rb = new RequestBuilder(this.rootUrl, AxEquipmentsV2Service.EquipmentsControllerUnlinkPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AxEquipment>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `equipmentsControllerUnlink$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  equipmentsControllerUnlink(
    params: {
      id: number;
    },
    context?: HttpContext
  ): Observable<AxEquipment> {
    return this.equipmentsControllerUnlink$Response(params, context).pipe(
      map((r: StrictHttpResponse<AxEquipment>): AxEquipment => r.body)
    );
  }

  /** Path part for operation `equipmentsControllerLink()` */
  static readonly EquipmentsControllerLinkPath = '/v2/equipments/link/{id}/to/{parentId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `equipmentsControllerLink()` instead.
   *
   * This method doesn't expect any request body.
   */
  equipmentsControllerLink$Response(
    params: {
      id: number;
      parentId: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AxEquipment>> {
    const rb = new RequestBuilder(this.rootUrl, AxEquipmentsV2Service.EquipmentsControllerLinkPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('parentId', params.parentId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AxEquipment>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `equipmentsControllerLink$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  equipmentsControllerLink(
    params: {
      id: number;
      parentId: number;
    },
    context?: HttpContext
  ): Observable<AxEquipment> {
    return this.equipmentsControllerLink$Response(params, context).pipe(
      map((r: StrictHttpResponse<AxEquipment>): AxEquipment => r.body)
    );
  }

  /** Path part for operation `equipmentsControllerGetDocumentsByEquipmentId()` */
  static readonly EquipmentsControllerGetDocumentsByEquipmentIdPath = '/v2/equipments/{id}/documents';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `equipmentsControllerGetDocumentsByEquipmentId()` instead.
   *
   * This method doesn't expect any request body.
   */
  equipmentsControllerGetDocumentsByEquipmentId$Response(
    params: {
      id: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<AxDocument>>> {
    const rb = new RequestBuilder(this.rootUrl, AxEquipmentsV2Service.EquipmentsControllerGetDocumentsByEquipmentIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AxDocument>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `equipmentsControllerGetDocumentsByEquipmentId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  equipmentsControllerGetDocumentsByEquipmentId(
    params: {
      id: number;
    },
    context?: HttpContext
  ): Observable<Array<AxDocument>> {
    return this.equipmentsControllerGetDocumentsByEquipmentId$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<AxDocument>>): Array<AxDocument> => r.body)
    );
  }

  /** Path part for operation `equipmentsControllerAddDocumentToEquipment()` */
  static readonly EquipmentsControllerAddDocumentToEquipmentPath = '/v2/equipments/{id}/documents';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `equipmentsControllerAddDocumentToEquipment()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  equipmentsControllerAddDocumentToEquipment$Response(
    params: {
      id: number;
      body: AxAddOrRemoveDocumentToEquipmentDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AxEquipment>> {
    const rb = new RequestBuilder(this.rootUrl, AxEquipmentsV2Service.EquipmentsControllerAddDocumentToEquipmentPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AxEquipment>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `equipmentsControllerAddDocumentToEquipment$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  equipmentsControllerAddDocumentToEquipment(
    params: {
      id: number;
      body: AxAddOrRemoveDocumentToEquipmentDto
    },
    context?: HttpContext
  ): Observable<AxEquipment> {
    return this.equipmentsControllerAddDocumentToEquipment$Response(params, context).pipe(
      map((r: StrictHttpResponse<AxEquipment>): AxEquipment => r.body)
    );
  }

  /** Path part for operation `equipmentsControllerRemoveDocumentFromEquipment()` */
  static readonly EquipmentsControllerRemoveDocumentFromEquipmentPath = '/v2/equipments/{id}/documents/{documentId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `equipmentsControllerRemoveDocumentFromEquipment()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  equipmentsControllerRemoveDocumentFromEquipment$Response(
    params: {
      id: number;
      body: AxAddOrRemoveDocumentToEquipmentDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AxSuccessDto>> {
    const rb = new RequestBuilder(this.rootUrl, AxEquipmentsV2Service.EquipmentsControllerRemoveDocumentFromEquipmentPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AxSuccessDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `equipmentsControllerRemoveDocumentFromEquipment$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  equipmentsControllerRemoveDocumentFromEquipment(
    params: {
      id: number;
      body: AxAddOrRemoveDocumentToEquipmentDto
    },
    context?: HttpContext
  ): Observable<AxSuccessDto> {
    return this.equipmentsControllerRemoveDocumentFromEquipment$Response(params, context).pipe(
      map((r: StrictHttpResponse<AxSuccessDto>): AxSuccessDto => r.body)
    );
  }

}
