import { Injectable } from '@angular/core';
import { FirebaseAnalytics } from '@capacitor-community/firebase-analytics';
import { Action, NgxsAfterBootstrap, Selector, State, StateContext } from '@ngxs/store';
import { AxContact } from 'src/app/_generated/axova-rest-api/models/ax-contact';
import { AxUser } from 'src/app/_generated/axova-rest-api/models/ax-user';
import { LoggerService } from 'src/app/ax-commons-logger/logger/logger.service';
import { AppStateSetAppDeviceId, AppStateSetProfile } from 'src/app/ngxs/app/app.actions';
import { AppStateModel, RolesEnum } from 'src/app/ngxs/app/app.state.model';

const STATE_NAME = 'axova__appState';
const DEFAULT_STATE: AppStateModel = {
  profile: null,
  appDeviceId: null,
};

@State<AppStateModel>({
  name: STATE_NAME,
  defaults: DEFAULT_STATE,
})
@Injectable()
export class AppState implements NgxsAfterBootstrap {

  constructor() {
  }

  @Selector()
  static profile(state: AppStateModel) {
    return state.profile;
  }

  @Selector()
  static appDeviceId(state: AppStateModel) {
    return state.appDeviceId;
  }

  @Selector()
  static axUser(state: AppStateModel): AxUser {
    return state.profile.meta as AxUser;
  }

  @Selector()
  static axContact(state: AppStateModel): AxContact {
    return state.profile.meta as AxContact;
  }

  @Selector()
  static roles(state: AppStateModel): string[] | RolesEnum[] {
    return state.profile.roles;
  }

  @Selector()
  static permissions(state: AppStateModel): string[] {
    return state.profile.permissions;
  }

  @Action(AppStateSetProfile)
  setProfile(ctx: StateContext<AppStateModel>, action: AppStateSetProfile) {
    ctx.patchState({profile: action.profile});
    try {
      FirebaseAnalytics.setUserId({
        userId: action.profile.id + '',
      }).then();
      FirebaseAnalytics.setUserProperty({
        name: 'isContactEntity',
        value: action.profile.isContactEntity + '',
      }).then();
      FirebaseAnalytics.setUserProperty({
        name: 'isUserEntity',
        value: action.profile.isUserEntity + '',
      }).then();
      FirebaseAnalytics.setUserProperty({
        name: 'lastProfileLoad',
        value: new Date().toISOString(),
      }).then();
    } catch (analyticsException) {
      LoggerService.ERROR(this, 'analyticsException', analyticsException);
    }
  }

  @Action(AppStateSetAppDeviceId)
  setAppDeviceId(ctx: StateContext<AppStateModel>, action: AppStateSetAppDeviceId) {
    ctx.patchState({appDeviceId: action.deviceId});
  }

  ngxsAfterBootstrap(ctx: StateContext<AppStateModel>) {
  }
}
