/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { AxMegasearchResultDto } from '../models/ax-megasearch-result-dto';
import { AxSearchCategoryEnum } from '../models/ax-search-category-enum';
import { AxSearchIndexKeyEnum } from '../models/ax-search-index-key-enum';
import { AxSearchindexStatusDtoEvent } from '../models/ax-searchindex-status-dto-event';
import { AxSuccessDto } from '../models/ax-success-dto';

@Injectable({ providedIn: 'root' })
export class AxSearchV2Service extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `searchControllerMegasearchBySearchterm()` */
  static readonly SearchControllerMegasearchBySearchtermPath = '/v2/search/megasearch/{searchterm}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `searchControllerMegasearchBySearchterm()` instead.
   *
   * This method doesn't expect any request body.
   */
  searchControllerMegasearchBySearchterm$Response(
    params: {
      searchterm: string;
      projectsSoldTrue?: boolean;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AxMegasearchResultDto>> {
    const rb = new RequestBuilder(this.rootUrl, AxSearchV2Service.SearchControllerMegasearchBySearchtermPath, 'get');
    if (params) {
      rb.path('searchterm', params.searchterm, {});
      rb.query('projectsSoldTrue', params.projectsSoldTrue, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AxMegasearchResultDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `searchControllerMegasearchBySearchterm$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  searchControllerMegasearchBySearchterm(
    params: {
      searchterm: string;
      projectsSoldTrue?: boolean;
    },
    context?: HttpContext
  ): Observable<AxMegasearchResultDto> {
    return this.searchControllerMegasearchBySearchterm$Response(params, context).pipe(
      map((r: StrictHttpResponse<AxMegasearchResultDto>): AxMegasearchResultDto => r.body)
    );
  }

  /** Path part for operation `searchControllerMegasearchBySearchtermAndCategory()` */
  static readonly SearchControllerMegasearchBySearchtermAndCategoryPath = '/v2/search/megasearch/{searchterm}/{searchCategory}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `searchControllerMegasearchBySearchtermAndCategory()` instead.
   *
   * This method doesn't expect any request body.
   */
  searchControllerMegasearchBySearchtermAndCategory$Response(
    params: {
      searchterm: string;
      searchCategory: AxSearchCategoryEnum;
      projectsSoldTrue?: boolean;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AxMegasearchResultDto>> {
    const rb = new RequestBuilder(this.rootUrl, AxSearchV2Service.SearchControllerMegasearchBySearchtermAndCategoryPath, 'get');
    if (params) {
      rb.path('searchterm', params.searchterm, {});
      rb.path('searchCategory', params.searchCategory, {});
      rb.query('projectsSoldTrue', params.projectsSoldTrue, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AxMegasearchResultDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `searchControllerMegasearchBySearchtermAndCategory$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  searchControllerMegasearchBySearchtermAndCategory(
    params: {
      searchterm: string;
      searchCategory: AxSearchCategoryEnum;
      projectsSoldTrue?: boolean;
    },
    context?: HttpContext
  ): Observable<AxMegasearchResultDto> {
    return this.searchControllerMegasearchBySearchtermAndCategory$Response(params, context).pipe(
      map((r: StrictHttpResponse<AxMegasearchResultDto>): AxMegasearchResultDto => r.body)
    );
  }

  /** Path part for operation `searchControllerRebuildIndexForSearchIndexKey()` */
  static readonly SearchControllerRebuildIndexForSearchIndexKeyPath = '/v2/search/rebuildIndex/{searchIndexKey}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `searchControllerRebuildIndexForSearchIndexKey()` instead.
   *
   * This method doesn't expect any request body.
   */
  searchControllerRebuildIndexForSearchIndexKey$Response(
    params: {
      searchIndexKey: AxSearchIndexKeyEnum;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AxSuccessDto>> {
    const rb = new RequestBuilder(this.rootUrl, AxSearchV2Service.SearchControllerRebuildIndexForSearchIndexKeyPath, 'post');
    if (params) {
      rb.path('searchIndexKey', params.searchIndexKey, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AxSuccessDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `searchControllerRebuildIndexForSearchIndexKey$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  searchControllerRebuildIndexForSearchIndexKey(
    params: {
      searchIndexKey: AxSearchIndexKeyEnum;
    },
    context?: HttpContext
  ): Observable<AxSuccessDto> {
    return this.searchControllerRebuildIndexForSearchIndexKey$Response(params, context).pipe(
      map((r: StrictHttpResponse<AxSuccessDto>): AxSuccessDto => r.body)
    );
  }

  /** Path part for operation `searchControllerDeleteIndexForSearchIndexKey()` */
  static readonly SearchControllerDeleteIndexForSearchIndexKeyPath = '/v2/search/deleteIndex/{searchIndexKey}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `searchControllerDeleteIndexForSearchIndexKey()` instead.
   *
   * This method doesn't expect any request body.
   */
  searchControllerDeleteIndexForSearchIndexKey$Response(
    params: {
      searchIndexKey: AxSearchIndexKeyEnum;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AxSuccessDto>> {
    const rb = new RequestBuilder(this.rootUrl, AxSearchV2Service.SearchControllerDeleteIndexForSearchIndexKeyPath, 'delete');
    if (params) {
      rb.path('searchIndexKey', params.searchIndexKey, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AxSuccessDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `searchControllerDeleteIndexForSearchIndexKey$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  searchControllerDeleteIndexForSearchIndexKey(
    params: {
      searchIndexKey: AxSearchIndexKeyEnum;
    },
    context?: HttpContext
  ): Observable<AxSuccessDto> {
    return this.searchControllerDeleteIndexForSearchIndexKey$Response(params, context).pipe(
      map((r: StrictHttpResponse<AxSuccessDto>): AxSuccessDto => r.body)
    );
  }

  /** Path part for operation `searchControllerListenToSearchindexStatus()` */
  static readonly SearchControllerListenToSearchindexStatusPath = '/v2/search/searchindexStatus';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `searchControllerListenToSearchindexStatus()` instead.
   *
   * This method doesn't expect any request body.
   */
  searchControllerListenToSearchindexStatus$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AxSearchindexStatusDtoEvent>> {
    const rb = new RequestBuilder(this.rootUrl, AxSearchV2Service.SearchControllerListenToSearchindexStatusPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/event-stream', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AxSearchindexStatusDtoEvent>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `searchControllerListenToSearchindexStatus$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  searchControllerListenToSearchindexStatus(
    params?: {
    },
    context?: HttpContext
  ): Observable<AxSearchindexStatusDtoEvent> {
    return this.searchControllerListenToSearchindexStatus$Response(params, context).pipe(
      map((r: StrictHttpResponse<AxSearchindexStatusDtoEvent>): AxSearchindexStatusDtoEvent => r.body)
    );
  }

}
