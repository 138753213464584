import { Component, Input, OnInit } from '@angular/core';
import { AxProjectsService, AxTask } from '../../_generated/axova-rest-api';

@Component({
  selector: 'ax-project-baustellen-dispo',
  templateUrl: './ax-project-baustellen-dispo.component.html',
  styleUrls: ['./ax-project-baustellen-dispo.component.scss'],
})
export class AxProjectBaustellenDispoComponent implements OnInit {
  @Input() projectId: number;
  public tasks: AxTask[] = [];
  public accordionStates: any = {};

  constructor(
    private readonly axProjectsService: AxProjectsService,
  ) {
  }

  ngOnInit() {
    this.axProjectsService.projectsControllerGetBaustellenDispoTasksForProject({ id: this.projectId })
      .subscribe({
        next: tasks => {
          this.tasks = tasks;
        },
      });
  }
}
