import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';
import { EmailComposer } from 'capacitor-email-composer';

@Injectable({
  providedIn: 'root',
})
export class MailService {
  constructor(
    private toastController: ToastController,
  ) {
  }

  /**
   * Composes a new email if there is an account on the device.
   *
   * @param recipient
   * @param subject
   * @param body
   */
  public async createMail(recipient: string, subject: string, body?: string) {
    try {
      if (await EmailComposer.hasAccount()) {
        await EmailComposer.open({
          to: [recipient],
          subject,
          isHtml: true,
          body: body || '',
        });
      } else {
        this.toastController.create({
          message: 'Kein E-Mail Account auf Ihrem Gerät gefunden.',
        });
      }
    } catch (noMailException) {
      throw Error('Kein E-Mail Account auf Ihrem Gerät gefunden.');
    }
  }
}
