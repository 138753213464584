import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'nullFilter',
})
export class NullFilterPipe implements PipeTransform {

  transform(array: any[], fields: string[] = []): any[] {
    if (!Array.isArray(array)) {
      return [];
    }

    return array.filter(item =>
      fields.every(field =>
        item.hasOwnProperty(field) && (item[field] === null || item[field] === undefined),
      ),
    );
  }

}
