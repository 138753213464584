import { Injectable, NgZone } from '@angular/core';
import { FCM } from '@capacitor-community/fcm';
import { Capacitor } from '@capacitor/core';
import { PushNotifications } from '@capacitor/push-notifications';
import { AlertController } from '@ionic/angular';
import { AndroidSettings, IOSSettings, NativeSettings } from 'capacitor-native-settings';
import { BehaviorSubject } from 'rxjs';
import { AxPushNotificationTokensService } from 'src/app/_generated/axova-rest-api';
import { LoggerService } from 'src/app/ax-commons-logger/logger/logger.service';
import { AppDeviceService } from 'src/app/services/app-device.service';

@Injectable({
  providedIn: 'root',
})
export class PushNotificationsService {
  static isPushNotificationTokenStoredViaApi = false;
  public pushNotificationToken$: BehaviorSubject<string> = new BehaviorSubject<string>('');

  constructor(
    private axPushNotificationTokensService: AxPushNotificationTokensService,
    private ngZone: NgZone,
    private alertController: AlertController,
    private appDeviceService: AppDeviceService,
  ) {
  }

  /**
   * Check for push notification permissions, listen for permissions,
   * tokens, messages etc.
   */
  public async register(force = false) {
    if (PushNotificationsService.isPushNotificationTokenStoredViaApi || !Capacitor.isNativePlatform()) {
      return;
    }

    let permStatus = await PushNotifications.checkPermissions();

    if (permStatus.receive === 'prompt') {
      permStatus = await PushNotifications.requestPermissions();
    }

    if (permStatus.receive !== 'granted') {
      if (force) {
        this.enablePushNotifications();
      }
      throw new Error('User denied permissions!');
    }

    await this.registerListeners();
    await PushNotifications.register();
  }

  /**
   * Listen for registration token, new push notifications etc.
   */
  private async registerListeners() {
    await PushNotifications.addListener('registration', token => {
      if (token) {
        LoggerService.LOG(this, 'Registration token: ', token.value);
        if (Capacitor.getPlatform() === 'ios') {
          FCM.getToken().then(fcmTokenResponse => {
            if (fcmTokenResponse) {
              this.pushNotificationToken$.next(fcmTokenResponse.token);
              this.appDeviceService.initializeAppDeviceInformation(fcmTokenResponse.token).then();
            }
          });
        } else {
          this.pushNotificationToken$.next(token.value);
          this.appDeviceService.initializeAppDeviceInformation(token.value).then();
        }
      }
    });

    await PushNotifications.addListener('registrationError', err => {
      LoggerService.ERROR(this, 'Registration error: ', err.error);
    });

    await PushNotifications.addListener('pushNotificationReceived', notification => {
      LoggerService.LOG(this, 'Push notification received: ', notification);
      // TODO: Anzeige in der App neu laden (Glöggli)
    });

    await PushNotifications.addListener('pushNotificationActionPerformed', notification => {
      LoggerService.LOG(this, 'Push notification action performed', notification.actionId, notification.inputValue);
    });
  }

  private enablePushNotifications() {
    PushNotifications.requestPermissions().then(permissionStatus => {
      this.ngZone.run(async () => {
        if (permissionStatus.receive === 'denied') {
          const toast = await this.alertController.create({
            header: 'Bitte gehe in die Einstellungen und erlaube bei der Axova-App die Mitteilungen/Benachrichtigungen.',
            message: null,
            buttons: [
              {
                text: 'Einstellungen öffnen',
                handler: async () => {
                  await NativeSettings.open({
                    optionAndroid: AndroidSettings.AppNotification,
                    optionIOS: IOSSettings.App,
                  });
                },
              },
              {
                text: 'Abbrechen',
                role: 'cancel',
              },
            ],
          });
          toast.present().then();
        }
      });
    });
  }
}
