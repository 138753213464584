
import { FormGroup, FormControl, FormArray, Validators } from '@angular/forms';


export function getCreateAbsenceV2DtoFormGroup() {
return new FormGroup({
    start: new FormControl<string | undefined>(undefined, [Validators.required]),
    end: new FormControl<string | undefined>(undefined, [Validators.required]),
    timerecordtypeId: new FormControl<number | undefined>(undefined, [Validators.required]),
    createdBy: new FormControl<number | undefined>(undefined, [Validators.required]),
    creator: new FormControl<any>(undefined),
    comment: new FormControl<string | undefined>(undefined),
    percentage: new FormControl<number | undefined>(100, [Validators.required]),
})
;
}
      