import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AxNews } from 'src/app/_generated/axova-rest-api';

@Component({
  selector: 'ax-news-item',
  templateUrl: './ax-news-item.component.html',
  styleUrls: ['./ax-news-item.component.scss'],
})
export class AxNewsItemComponent implements OnInit {
  @Input() newsItem: AxNews;
  @Output() openNewsDetail: EventEmitter<AxNews> = new EventEmitter<AxNews>();

  constructor() {
  }

  ngOnInit() {
  }
}
