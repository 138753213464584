import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AxEventInvitation, AxEventInvitationDeclinedStatusEnum, AxEventInvitationExamResultEnum, AxEventInvitationsService } from 'src/app/_generated/axova-rest-api';
import { AxUiService } from '../../services/ax-ui.service';

@Component({
  selector: 'ax-event-invitation-item',
  templateUrl: './ax-event-invitation-item.component.html',
  styleUrls: ['./ax-event-invitation-item.component.scss'],
})
export class AxEventInvitationItemComponent implements OnInit {
  @Input() eventInvitation: AxEventInvitation;
  @Input() showLine = true;
  @Output() checked: EventEmitter<{ eventInvitation: AxEventInvitation, checked: boolean }> = new EventEmitter<{ eventInvitation: AxEventInvitation, checked: boolean }>();
  public eventInvitationDeclinedStatusEnum = AxEventInvitationDeclinedStatusEnum;
  public eventInvitationExamResultEnum = AxEventInvitationExamResultEnum;
  public closeSlidingItems = false;

  constructor(
    private readonly axEventInvitationsService: AxEventInvitationsService,
    private readonly axUiService: AxUiService,
  ) {
  }

  ngOnInit() {
  }

  check(eventInvitation: AxEventInvitation, event: any) {
    this.checked.emit({ eventInvitation, checked: event.detail.checked });
  }

  public updateExamResult(eventInvitationId: number, eventInvitationExamResultEnum: AxEventInvitationExamResultEnum, slidingItem: any) {
    this.axEventInvitationsService.eventInvitationsControllerUpdateAsOrganisator({
      id: eventInvitationId,
      body: {
        examResult: eventInvitationExamResultEnum,
      },
    }).subscribe({
      next: () => {
        this.eventInvitation.examResult = eventInvitationExamResultEnum;
      },
      error: async error => {
        await this.axUiService.showError('Diese Aktion kann nur vom Organisator ausgeführt werden.');
      },
      complete: () => {
        slidingItem.close();
      },
    });
  }

  public updateDeclinedStatus(eventInvitationId: number, eventInvitationDeclinedStatusEnum: AxEventInvitationDeclinedStatusEnum, slidingItem: any) {
    this.axEventInvitationsService.eventInvitationsControllerUpdateAsOrganisator({
      id: eventInvitationId,
      body: {
        declinedStatus: eventInvitationDeclinedStatusEnum,
      },
    }).subscribe({
      next: () => {
        this.eventInvitation.declinedStatus = eventInvitationDeclinedStatusEnum;
      },
      error: async error => {
        await this.axUiService.showError('Diese Aktion kann nur vom Organisator ausgeführt werden.');
      },
      complete: () => {
        slidingItem.close();
      },
    });
  }
}
