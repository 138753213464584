import { Injectable } from '@angular/core';
import { AxDocument, AxFilesService } from '../_generated/axova-rest-api';
import { PdfModalPage } from '../pages/global/pdf-modal/pdf-modal.page';
import { ModalController } from '@ionic/angular';
import { AxUiService } from './ax-ui.service';

@Injectable({
  providedIn: 'root',
})
export class DocumentsService {
  constructor(
    private readonly axFilesService: AxFilesService,
    private readonly modalController: ModalController,
    private readonly axUiService: AxUiService,
  ) {
  }

  /**
   * Opens a PDF document from Axova Office/API.
   *
   * @param document
   */
  public async openPdf(document: AxDocument) {
    let pdf;
    try {
      pdf = await this.axFilesService.filesControllerView({
        folder: `${document.folder}`,
        filename: `${document.id}_${document.name}`,
      }).toPromise();
    } catch (noPdfException) {
      try {
        pdf = await this.axFilesService.filesControllerView({
          folder: `${document.folder}`,
          filename: `${document.name}`,
        }).toPromise();
      } catch (stillNoPdfException) {
        await this.axUiService.showError('Dokument konnte nicht geladen werden.');
        return false;
      }
    }
    const pdfUrl = URL.createObjectURL(pdf);
    this.modalController.create({
      component: PdfModalPage,
      componentProps: {
        pdfTitle: document.name,
        pdfUrl,
        pdfRawData: pdf,
      },
    }).then(instance => instance.present());
  }
}
