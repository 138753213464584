import { Component, Input, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Directory, Filesystem } from '@capacitor/filesystem';
import { Share } from '@capacitor/share';
import { ModalController } from '@ionic/angular';
import { LoggerService } from 'src/app/ax-commons-logger/logger/logger.service';

@Component({
  selector: 'app-pdf-modal',
  templateUrl: './pdf-modal.page.html',
  styleUrls: ['./pdf-modal.page.scss'],
})
export class PdfModalPage implements OnInit {
  @Input() pdfUrl: string;
  @Input() pdfTitle: string;
  @Input() pdfRawData: any;

  constructor(
    private modalController: ModalController,
    private title: Title,
  ) {
  }

  ngOnInit() {
  }

  ionViewDidEnter() {
    this.title.setTitle(`PDF-Viewer`);
  }

  public close() {
    try {
      this.modalController.getTop().then(instance => instance.dismiss());
    } catch (noModalToCloseException) {
      LoggerService.LOG(this, 'noModalToCloseException', noModalToCloseException);
    }
  }

  public async share() {
    const pdfFile = await Filesystem.writeFile({
      path: this.getSanitizedFilename(this.pdfTitle) + '.pdf',
      data: await this.blobToBase64(this.pdfRawData),
      directory: Directory.Cache,
    });
    await Share.share({
      title: this.pdfTitle,
      url: pdfFile.uri,
      dialogTitle: 'PDF teilen',
    });
  }

  private getSanitizedFilename(filename: string): string {
    return filename.replace(/[^a-z0-9]/gi, '_').toLowerCase();
  }

  private blobToBase64(blob): Promise<string> {
    return new Promise((resolve, _) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result as string);
      reader.readAsDataURL(blob);
    });
  }
}
