import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AxElectricalMeasurement } from 'src/app/_generated/axova-rest-api';

@Component({
  selector: 'ax-project-electrical-measurements',
  templateUrl: './ax-project-electrical-measurements.component.html',
  styleUrls: ['./ax-project-electrical-measurements.component.scss'],
})
export class AxProjectElectricalMeasurementsComponent implements OnInit {
  @Input() electricalMeasurements: AxElectricalMeasurement[];
  @Output() open: EventEmitter<AxElectricalMeasurement> = new EventEmitter<AxElectricalMeasurement>();
  @Input() isInternalView: boolean = false;
  public accordionStates: any = {};

  constructor() {
  }

  ngOnInit() {
  }
}
