import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Store } from '@ngxs/store';
import { AxContact } from 'src/app/_generated/axova-rest-api';
import { AxProject } from 'src/app/_generated/axova-rest-api/models/ax-project';
import { LoggerService } from 'src/app/ax-commons-logger/logger/logger.service';
import { AppState } from 'src/app/ngxs/app/app.state';
import { UserCustomerStateSetSelectedProject } from 'src/app/ngxs/user-customer/user-customer.actions';
import { UserCustomerState } from 'src/app/ngxs/user-customer/user-customer.state';

@Component({
  selector: 'ax-project-selector',
  templateUrl: './ax-project-selector.component.html',
  styleUrls: ['./ax-project-selector.component.scss'],
})
export class AxProjectSelectorComponent implements OnInit {
  @Input() projects: AxProject[] = [];
  @Output() projectSelected: EventEmitter<AxProject>;
  public selectedProjectId: number;
  public contact: AxContact;
  public projectLocation: string;

  constructor(
    private store: Store,
  ) {
    this.projectSelected = new EventEmitter<AxProject>();
    if (!this.projects.length) {
      this.store.select(UserCustomerState.projects).subscribe({
        next: projects => {
          this.projects = projects;
          LoggerService.LOG(this, 'projects', projects);
          if (this.projects.length && this.projects.length) {
            this.selectProject(projects[0]);
          }
        },
      });
    }
  }

  ngOnInit() {
    this.store.select(AppState.axContact).subscribe((contact) => this.contact = contact);
    this.store.select(UserCustomerState.selectedProject).subscribe({
      next: selectedProject => {
        if (selectedProject) {
          this.selectedProjectId = selectedProject.id;
          if (selectedProject.objectStreet && selectedProject.objectCity) {
            this.projectLocation = `(${selectedProject.objectStreet}, ${selectedProject.objectCity})`;
          } else {
            this.projectLocation = `(${this.contact?.street}, ${this.contact?.city})`;
          }
        }
      },
    });
  }

  public selectProject(event): void {
    try {
      const selectedProject = this.projects.find((project) => project.id === event.detail.value);
      this.store.dispatch(new UserCustomerStateSetSelectedProject(selectedProject));
    } catch (selectProjectException) {
      LoggerService.LOG(this, 'selectProjectException', selectProjectException);
    }
  }
}
