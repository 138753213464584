
import { FormGroup, FormControl, FormArray, Validators } from '@angular/forms';


export function getUpdateProjectDtoFormGroup() {
return new FormGroup({
    containerId: new FormControl<number | undefined>(undefined),
    teamId: new FormControl<number | undefined>(undefined),
    supplierId: new FormControl<number | undefined>(undefined),
    authenticatorId: new FormControl<number | undefined>(undefined),
    statusId: new FormControl<number | undefined>(undefined),
    reasontypeId: new FormControl<number | undefined>(undefined),
    salesworkflowId: new FormControl<number | undefined>(undefined),
    userId: new FormControl<number | undefined>(undefined),
    tpbUserId: new FormControl<number | undefined>(undefined),
    realestatetypeId: new FormControl<number | undefined>(undefined),
    number: new FormControl<number | undefined>(undefined),
    numberQualified: new FormControl<string | undefined>(undefined),
    revenueChf: new FormControl<number | undefined>(undefined),
    revenueChfCalculated: new FormControl<number | undefined>(undefined),
    powerKwp: new FormControl<number | undefined>(undefined),
    area: new FormControl<number | undefined>(undefined),
    purchaseChfKwp: new FormControl<number | undefined>(undefined),
    sellingChfKwp: new FormControl<number | undefined>(undefined),
    revenueKwhYear: new FormControl<number | undefined>(undefined),
    revenueKwhSpecific: new FormControl<number | undefined>(undefined),
    moduleOrientation: new FormControl<string | undefined>(undefined),
    priceOffered: new FormControl<number | undefined>(undefined),
    lot: new FormControl<string | undefined>(undefined),
    tobNotes: new FormControl<string | undefined>(undefined),
    tob2Notes: new FormControl<string | undefined>(undefined),
    start: new FormControl<string | undefined>(undefined),
    end: new FormControl<string | undefined>(undefined),
    planned: new FormControl<string | undefined>(undefined),
    sale: new FormControl<string | undefined>(undefined),
    build: new FormControl<string | undefined>(undefined),
    installationStart: new FormControl<string | undefined>(undefined),
    installationEnd: new FormControl<string | undefined>(undefined),
    installationStartFixed: new FormControl<boolean | undefined>(undefined),
    implementing: new FormControl<string | undefined>(undefined),
    supply: new FormControl<string | undefined>(undefined),
    buildingpermit: new FormControl<boolean | undefined>(undefined),
    esti: new FormControl<boolean | undefined>(undefined),
    constructionType: new FormControl<string | undefined>(undefined),
    systemType: new FormControl<string | undefined>(undefined),
    communicationLevel: new FormControl<number | undefined>(undefined),
    kevNumber: new FormControl<string | undefined>(undefined),
    kevRegistration: new FormControl<string | undefined>(undefined),
    kevCertification: new FormControl<string | undefined>(undefined),
    kevIbnComplete: new FormControl<boolean | undefined>(undefined),
    kevStatus: new FormControl<string | undefined>(undefined),
    eivFinancing: new FormControl<boolean | undefined>(undefined),
    eivFinancingAmount: new FormControl<number | undefined>(undefined),
    communitySponsorshipAmount: new FormControl<number | undefined>(undefined),
    tmpSerialnumbers: new FormControl<string | undefined>(undefined),
    partialpayment1: new FormControl<number | undefined>(undefined),
    partialpayment2: new FormControl<number | undefined>(undefined),
    partialpayment3: new FormControl<number | undefined>(undefined),
    partialpayment4: new FormControl<number | undefined>(undefined),
    tax: new FormControl<number | undefined>(undefined),
    salesinformation: new FormControl<string | undefined>(undefined),
    objectStreet: new FormControl<string | undefined>(undefined),
    objectZip: new FormControl<string | undefined>(undefined),
    objectCity: new FormControl<string | undefined>(undefined),
    objectCanton: new FormControl<string | undefined>(undefined),
    longitude: new FormControl<number | undefined>(undefined),
    latitude: new FormControl<number | undefined>(undefined),
    locked: new FormControl<boolean | undefined>(undefined),
    lockedNotes: new FormControl<string | undefined>(undefined),
    specialReference: new FormControl<string | undefined>(undefined),
    appImage: new FormControl<string | undefined>(undefined),
    created: new FormControl<string | undefined>(undefined),
    modified: new FormControl<string | undefined>(undefined),
    createdBy: new FormControl<number | undefined>(undefined),
    modifiedBy: new FormControl<number | undefined>(undefined),
    active: new FormControl<boolean | undefined>(undefined),
    migContactCrmId: new FormControl<string | undefined>(undefined),
})
;
}
      