
import { FormGroup, FormControl, FormArray, Validators } from '@angular/forms';
import { requiredBoolean } from './required-boolean.validator';

export function getCreateScreendeviceDtoFormGroup() {
return new FormGroup({
    uniqueIdentifier: new FormControl<string | undefined>('', [Validators.required]),
    name: new FormControl<string | undefined>('', [Validators.required]),
    ipAddress: new FormControl<string | undefined>('', [Validators.required]),
    active: new FormControl<boolean | undefined>(true, [requiredBoolean()]),
    confirmed: new FormControl<boolean | undefined>(false, [requiredBoolean()]),
    startUpTime: new FormControl<string | undefined>(undefined),
    shutDownTime: new FormControl<string | undefined>(undefined),
    description: new FormControl<string | undefined>(undefined),
})
;
}
      