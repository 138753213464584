/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { AxAddDocumentDto } from '../models/ax-add-document-dto';
import { AxCreateEquipmentlogtypeDto } from '../models/ax-create-equipmentlogtype-dto';
import { AxDropdownOptionDto } from '../models/ax-dropdown-option-dto';
import { AxEquipmentlogtype } from '../models/ax-equipmentlogtype';
import { AxPaginatedDocumented } from '../models/ax-paginated-documented';
import { AxRemoveDocumentDto } from '../models/ax-remove-document-dto';
import { AxSuccessDto } from '../models/ax-success-dto';
import { AxUpdateEquipmentlogtypeDto } from '../models/ax-update-equipmentlogtype-dto';

@Injectable({ providedIn: 'root' })
export class AxEquipmentlogtypesV2Service extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `equipmentlogtypesControllerGetAllDropdownOptions()` */
  static readonly EquipmentlogtypesControllerGetAllDropdownOptionsPath = '/v2/equipmentlogtypes/dropdown-options';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `equipmentlogtypesControllerGetAllDropdownOptions()` instead.
   *
   * This method doesn't expect any request body.
   */
  equipmentlogtypesControllerGetAllDropdownOptions$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<AxDropdownOptionDto>>> {
    const rb = new RequestBuilder(this.rootUrl, AxEquipmentlogtypesV2Service.EquipmentlogtypesControllerGetAllDropdownOptionsPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AxDropdownOptionDto>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `equipmentlogtypesControllerGetAllDropdownOptions$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  equipmentlogtypesControllerGetAllDropdownOptions(
    params?: {
    },
    context?: HttpContext
  ): Observable<Array<AxDropdownOptionDto>> {
    return this.equipmentlogtypesControllerGetAllDropdownOptions$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<AxDropdownOptionDto>>): Array<AxDropdownOptionDto> => r.body)
    );
  }

  /** Path part for operation `equipmentlogtypesControllerFindAllPaginated()` */
  static readonly EquipmentlogtypesControllerFindAllPaginatedPath = '/v2/equipmentlogtypes';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `equipmentlogtypesControllerFindAllPaginated()` instead.
   *
   * This method doesn't expect any request body.
   */
  equipmentlogtypesControllerFindAllPaginated$Response(
    params?: {

    /**
     * Page number to retrieve.If you provide invalid value the default page number will applied
     *         <p>
     *              <b>Example: </b> 1
     *           </p>
     *         <p>
     *              <b>Default Value: </b> 1
     *           </p>
     */
      page?: number;

    /**
     * Number of records per page.
     *       <p>
     *              <b>Example: </b> 20
     *           </p>
     *       <p>
     *              <b>Default Value: </b> 25
     *           </p>
     *       <p>
     *              <b>Max Value: </b> 100
     *           </p>
     *
     *       If provided value is greater than max value, max value will be applied.
     */
      limit?: number;

    /**
     * Filter by isStatus query param.
     *           <p>
     *              <b>Format: </b> filter.isStatus={$not}:OPERATION:VALUE
     *           </p>
     *           <p>
     *              <b>Example: </b> filter.isStatus=$not:$like:John Doe&filter.isStatus=like:John
     *           </p>
     *           <h4>Available Operations</h4><ul><li>$eq</li></ul>
     */
      'filter.isStatus'?: Array<string>;

    /**
     * Parameter to sort by.
     *       <p>To sort by multiple fields, just provide query param multiple types. The order in url defines an order of sorting</p>
     *       <p>
     *              <b>Format: </b> fieldName:DIRECTION
     *           </p>
     *       <p>
     *              <b>Example: </b> sortBy=id:DESC&sortBy=createdAt:ASC
     *           </p>
     *       <p>
     *              <b>Default Value: </b> name:ASC
     *           </p>
     *       <h4>Available Fields</h4><ul><li>name</li>
     * <li>description</li>
     * <li>allowStocklocation</li>
     * <li>isStatus</li></ul>
     */
      sortBy?: Array<'name:ASC' | 'name:DESC' | 'description:ASC' | 'description:DESC' | 'allowStocklocation:ASC' | 'allowStocklocation:DESC' | 'isStatus:ASC' | 'isStatus:DESC'>;

    /**
     * Search term to filter result values
     *         <p>
     *              <b>Example: </b> John
     *           </p>
     *         <p>
     *              <b>Default Value: </b> No default value
     *           </p>
     */
      search?: string;

    /**
     * List of fields to search by term to filter result values
     *         <p>
     *              <b>Example: </b> 
     *           </p>
     *         <p>
     *              <b>Default Value: </b> By default all fields mentioned below will be used to search by term
     *           </p>
     *         <h4>Available Fields</h4><ul></ul>
     */
      searchBy?: Array<string>;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AxPaginatedDocumented & {
'data'?: Array<AxEquipmentlogtype>;
'meta'?: {
'select'?: Array<string>;
'filter'?: {
'isStatus'?: (string | Array<string>);
};
};
}>> {
    const rb = new RequestBuilder(this.rootUrl, AxEquipmentlogtypesV2Service.EquipmentlogtypesControllerFindAllPaginatedPath, 'get');
    if (params) {
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.query('filter.isStatus', params['filter.isStatus'], {});
      rb.query('sortBy', params.sortBy, {});
      rb.query('search', params.search, {});
      rb.query('searchBy', params.searchBy, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AxPaginatedDocumented & {
        'data'?: Array<AxEquipmentlogtype>;
        'meta'?: {
        'select'?: Array<string>;
        'filter'?: {
        'isStatus'?: (string | Array<string>);
        };
        };
        }>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `equipmentlogtypesControllerFindAllPaginated$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  equipmentlogtypesControllerFindAllPaginated(
    params?: {

    /**
     * Page number to retrieve.If you provide invalid value the default page number will applied
     *         <p>
     *              <b>Example: </b> 1
     *           </p>
     *         <p>
     *              <b>Default Value: </b> 1
     *           </p>
     */
      page?: number;

    /**
     * Number of records per page.
     *       <p>
     *              <b>Example: </b> 20
     *           </p>
     *       <p>
     *              <b>Default Value: </b> 25
     *           </p>
     *       <p>
     *              <b>Max Value: </b> 100
     *           </p>
     *
     *       If provided value is greater than max value, max value will be applied.
     */
      limit?: number;

    /**
     * Filter by isStatus query param.
     *           <p>
     *              <b>Format: </b> filter.isStatus={$not}:OPERATION:VALUE
     *           </p>
     *           <p>
     *              <b>Example: </b> filter.isStatus=$not:$like:John Doe&filter.isStatus=like:John
     *           </p>
     *           <h4>Available Operations</h4><ul><li>$eq</li></ul>
     */
      'filter.isStatus'?: Array<string>;

    /**
     * Parameter to sort by.
     *       <p>To sort by multiple fields, just provide query param multiple types. The order in url defines an order of sorting</p>
     *       <p>
     *              <b>Format: </b> fieldName:DIRECTION
     *           </p>
     *       <p>
     *              <b>Example: </b> sortBy=id:DESC&sortBy=createdAt:ASC
     *           </p>
     *       <p>
     *              <b>Default Value: </b> name:ASC
     *           </p>
     *       <h4>Available Fields</h4><ul><li>name</li>
     * <li>description</li>
     * <li>allowStocklocation</li>
     * <li>isStatus</li></ul>
     */
      sortBy?: Array<'name:ASC' | 'name:DESC' | 'description:ASC' | 'description:DESC' | 'allowStocklocation:ASC' | 'allowStocklocation:DESC' | 'isStatus:ASC' | 'isStatus:DESC'>;

    /**
     * Search term to filter result values
     *         <p>
     *              <b>Example: </b> John
     *           </p>
     *         <p>
     *              <b>Default Value: </b> No default value
     *           </p>
     */
      search?: string;

    /**
     * List of fields to search by term to filter result values
     *         <p>
     *              <b>Example: </b> 
     *           </p>
     *         <p>
     *              <b>Default Value: </b> By default all fields mentioned below will be used to search by term
     *           </p>
     *         <h4>Available Fields</h4><ul></ul>
     */
      searchBy?: Array<string>;
    },
    context?: HttpContext
  ): Observable<AxPaginatedDocumented & {
'data'?: Array<AxEquipmentlogtype>;
'meta'?: {
'select'?: Array<string>;
'filter'?: {
'isStatus'?: (string | Array<string>);
};
};
}> {
    return this.equipmentlogtypesControllerFindAllPaginated$Response(params, context).pipe(
      map((r: StrictHttpResponse<AxPaginatedDocumented & {
'data'?: Array<AxEquipmentlogtype>;
'meta'?: {
'select'?: Array<string>;
'filter'?: {
'isStatus'?: (string | Array<string>);
};
};
}>): AxPaginatedDocumented & {
'data'?: Array<AxEquipmentlogtype>;
'meta'?: {
'select'?: Array<string>;
'filter'?: {
'isStatus'?: (string | Array<string>);
};
};
} => r.body)
    );
  }

  /** Path part for operation `equipmentlogtypesControllerCreate()` */
  static readonly EquipmentlogtypesControllerCreatePath = '/v2/equipmentlogtypes';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `equipmentlogtypesControllerCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  equipmentlogtypesControllerCreate$Response(
    params: {
      body: AxCreateEquipmentlogtypeDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AxEquipmentlogtype>> {
    const rb = new RequestBuilder(this.rootUrl, AxEquipmentlogtypesV2Service.EquipmentlogtypesControllerCreatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AxEquipmentlogtype>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `equipmentlogtypesControllerCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  equipmentlogtypesControllerCreate(
    params: {
      body: AxCreateEquipmentlogtypeDto
    },
    context?: HttpContext
  ): Observable<AxEquipmentlogtype> {
    return this.equipmentlogtypesControllerCreate$Response(params, context).pipe(
      map((r: StrictHttpResponse<AxEquipmentlogtype>): AxEquipmentlogtype => r.body)
    );
  }

  /** Path part for operation `equipmentlogtypesControllerFindOneById()` */
  static readonly EquipmentlogtypesControllerFindOneByIdPath = '/v2/equipmentlogtypes/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `equipmentlogtypesControllerFindOneById()` instead.
   *
   * This method doesn't expect any request body.
   */
  equipmentlogtypesControllerFindOneById$Response(
    params: {
      id: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AxEquipmentlogtype>> {
    const rb = new RequestBuilder(this.rootUrl, AxEquipmentlogtypesV2Service.EquipmentlogtypesControllerFindOneByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AxEquipmentlogtype>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `equipmentlogtypesControllerFindOneById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  equipmentlogtypesControllerFindOneById(
    params: {
      id: number;
    },
    context?: HttpContext
  ): Observable<AxEquipmentlogtype> {
    return this.equipmentlogtypesControllerFindOneById$Response(params, context).pipe(
      map((r: StrictHttpResponse<AxEquipmentlogtype>): AxEquipmentlogtype => r.body)
    );
  }

  /** Path part for operation `equipmentlogtypesControllerDelete()` */
  static readonly EquipmentlogtypesControllerDeletePath = '/v2/equipmentlogtypes/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `equipmentlogtypesControllerDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  equipmentlogtypesControllerDelete$Response(
    params: {
      id: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, AxEquipmentlogtypesV2Service.EquipmentlogtypesControllerDeletePath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `equipmentlogtypesControllerDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  equipmentlogtypesControllerDelete(
    params: {
      id: number;
    },
    context?: HttpContext
  ): Observable<void> {
    return this.equipmentlogtypesControllerDelete$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `equipmentlogtypesControllerUpdate()` */
  static readonly EquipmentlogtypesControllerUpdatePath = '/v2/equipmentlogtypes/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `equipmentlogtypesControllerUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  equipmentlogtypesControllerUpdate$Response(
    params: {
      id: number;
      body: AxUpdateEquipmentlogtypeDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AxEquipmentlogtype>> {
    const rb = new RequestBuilder(this.rootUrl, AxEquipmentlogtypesV2Service.EquipmentlogtypesControllerUpdatePath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AxEquipmentlogtype>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `equipmentlogtypesControllerUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  equipmentlogtypesControllerUpdate(
    params: {
      id: number;
      body: AxUpdateEquipmentlogtypeDto
    },
    context?: HttpContext
  ): Observable<AxEquipmentlogtype> {
    return this.equipmentlogtypesControllerUpdate$Response(params, context).pipe(
      map((r: StrictHttpResponse<AxEquipmentlogtype>): AxEquipmentlogtype => r.body)
    );
  }

  /** Path part for operation `equipmentlogtypesControllerAddDocumentToEquipmentlogtype()` */
  static readonly EquipmentlogtypesControllerAddDocumentToEquipmentlogtypePath = '/v2/equipmentlogtypes/{id}/documents';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `equipmentlogtypesControllerAddDocumentToEquipmentlogtype()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  equipmentlogtypesControllerAddDocumentToEquipmentlogtype$Response(
    params: {
      id: number;
      body: AxAddDocumentDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AxSuccessDto>> {
    const rb = new RequestBuilder(this.rootUrl, AxEquipmentlogtypesV2Service.EquipmentlogtypesControllerAddDocumentToEquipmentlogtypePath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AxSuccessDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `equipmentlogtypesControllerAddDocumentToEquipmentlogtype$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  equipmentlogtypesControllerAddDocumentToEquipmentlogtype(
    params: {
      id: number;
      body: AxAddDocumentDto
    },
    context?: HttpContext
  ): Observable<AxSuccessDto> {
    return this.equipmentlogtypesControllerAddDocumentToEquipmentlogtype$Response(params, context).pipe(
      map((r: StrictHttpResponse<AxSuccessDto>): AxSuccessDto => r.body)
    );
  }

  /** Path part for operation `equipmentlogtypesControllerRemoveDocumentFromEquipmentlogtype()` */
  static readonly EquipmentlogtypesControllerRemoveDocumentFromEquipmentlogtypePath = '/v2/equipmentlogtypes/{id}/documents';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `equipmentlogtypesControllerRemoveDocumentFromEquipmentlogtype()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  equipmentlogtypesControllerRemoveDocumentFromEquipmentlogtype$Response(
    params: {
      id: number;
      body: AxRemoveDocumentDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AxSuccessDto>> {
    const rb = new RequestBuilder(this.rootUrl, AxEquipmentlogtypesV2Service.EquipmentlogtypesControllerRemoveDocumentFromEquipmentlogtypePath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AxSuccessDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `equipmentlogtypesControllerRemoveDocumentFromEquipmentlogtype$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  equipmentlogtypesControllerRemoveDocumentFromEquipmentlogtype(
    params: {
      id: number;
      body: AxRemoveDocumentDto
    },
    context?: HttpContext
  ): Observable<AxSuccessDto> {
    return this.equipmentlogtypesControllerRemoveDocumentFromEquipmentlogtype$Response(params, context).pipe(
      map((r: StrictHttpResponse<AxSuccessDto>): AxSuccessDto => r.body)
    );
  }

}
