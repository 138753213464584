
import { FormGroup, FormControl, FormArray, Validators } from '@angular/forms';
import { requiredBoolean } from './required-boolean.validator';

export function getCreateStocklocationDtoFormGroup() {
return new FormGroup({
    name: new FormControl<string | undefined>('', [Validators.required]),
    active: new FormControl<boolean | undefined>(true, [requiredBoolean()]),
    hideInV4: new FormControl<boolean | undefined>(true, [requiredBoolean()]),
    qrcodeId: new FormControl<number | undefined>(undefined),
    parentId: new FormControl<number | undefined>(undefined),
    businessunitId: new FormControl<number | undefined>(undefined),
})
;
}
      