/* tslint:disable */
/* eslint-disable */
export enum AxMessagingTemplateNameEnum {
  ObBestaetigung = 'ob_bestaetigung',
  ObErinnerung = 'ob_erinnerung',
  ObStornierung = 'ob_stornierung',
  ObTerminassistent = 'ob_terminassistent',
  Speicherinformation = 'speicherinformation',
  SpeicherinformationSolaredge = 'speicherinformation_solaredge',
  Terminbestaetigung = 'terminbestaetigung',
  TerminbestaetigungMitVerantwortlichem = 'terminbestaetigung_mit_verantwortlichem',
  Zahlungserinnerung = 'zahlungserinnerung',
  MitarbeiterWillkommenstext = 'mitarbeiter_willkommenstext',
  Drittkontrolle = 'drittkontrolle',
  ProjektPlanungsunterlagen = 'projekt_planungsunterlagen',
  MaengelberichtBehoben = 'maengelbericht_behoben',
  IaEinreichen = 'ia_einreichen',
  SinaErstellen = 'sina_erstellen',
  AcDcSinaErstellen = 'ac_dc_sina_erstellen'
}
