/**
 * This global environment variables are equal to production.
 * The GLOBAL constant shouldn't be imported directly. Only import them via an environment variable.
 */
export const GLOBALS = {
  name: 'production',

  /**
   * prod, abna, entw, qa etc. Can be used to implement specific functionality for dedicated environments.
   */
  apiConfiguration: {
    baseUrl: 'https://api.axova.cloud',
  },

  /**
   * Axova Office.
   */
  axovaOffice: {
    baseUrl: 'office.axova.cloud',
  },

  /**
   * This is an ionic internal flag which states how to compile the app.
   */
  production: true,

  /**
   * Language configuration, setting the default language and provide a list of available languages in the app.
   */
  languagesDefault: 'de',
  languagesAvailable: ['de'],

  /**
   * Firebase.
   */
  firebase: {
    configuration: {
      apiKey: 'AIzaSyC7GCgMgxeAp-LLxLXukYRz837p-jqvlr0',
      authDomain: 'axova-9c0f8.firebaseapp.com',
      databaseURL: 'https://axova-9c0f8.firebaseio.com',
      projectId: 'axova-9c0f8',
      storageBucket: 'axova-9c0f8.appspot.com',
      messagingSenderId: '671948311025',
      appId: '1:671948311025:web:5cfce08fb0afcc8db91c50',
      measurementId: 'G-BJMXMSLM2V',
    },
  },

  /**
   * Logging settings for NGXS and general.
   */
  isLoggingEnabled: {
    logger: false,
    ngxs: false,
  },
};
