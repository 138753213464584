import { Injectable } from '@angular/core';
import { AxDocument, AxFilesService } from 'src/app/_generated/axova-rest-api';
import { PhotoViewer } from '@digitale-menschen/capacitor-photoviewer';
import { AxUiService } from './ax-ui.service';

@Injectable({
  providedIn: 'root',
})
export class PhotoViewerService {
  constructor(
    private axFilesService: AxFilesService,
    private axUiService: AxUiService,
  ) {
  }

  /**
   * Pass a local URL or a web URL, which does not require authentication.
   *
   * @param url
   * @param title
   */
  async openImage(url: string, title: string) {
    await PhotoViewer.show({
      images: [
        {
          url,
          title,
        },
      ],
      mode: 'one',
      options: {},
    });
  }

  /**
   * Pass an Axova document of type image (jpg/png).
   *
   * @param document
   */
  async openAxovaDocumentImage(document: AxDocument) {
    await this.axUiService.showLoading();
    let image;
    try {
      image = await this.axFilesService.filesControllerView({
        folder: document.folder,
        filename: `${document.id}_${document.name}`,
      }).toPromise();
    } catch (imageNotFoundException) {
      image = await this.axFilesService.filesControllerView({
        folder: document.folder,
        filename: `${document.name}`,
      }).toPromise();
    }
    await this.axUiService.hideLoading();
    if (!image) {
      return false;
    }
    await PhotoViewer.show({
      images: [
        {
          url: await this.blobToBase64(image),
          title: document.name,
        },
      ],
      mode: 'one',
      options: {
        maxzoomscale: 5,
      },
    });
  }

  /**
   * Converts an image blob to base64, which is required for the photoviewer.
   *
   * @param blob
   * @private
   */
  private async blobToBase64(blob) {
    return new Promise<string>((resolve, _) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result as string);
      reader.readAsDataURL(blob);
    });
  }
}
