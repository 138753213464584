/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { AxReservationtype } from '../models/ax-reservationtype';

@Injectable({ providedIn: 'root' })
export class AxReservationtypesV2Service extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `reservationtypesControllerFindOneByContainerTypeId()` */
  static readonly ReservationtypesControllerFindOneByContainerTypeIdPath = '/v2/reservationtypes/byContainerTypeId/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `reservationtypesControllerFindOneByContainerTypeId()` instead.
   *
   * This method doesn't expect any request body.
   */
  reservationtypesControllerFindOneByContainerTypeId$Response(
    params: {
      id: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AxReservationtype>> {
    const rb = new RequestBuilder(this.rootUrl, AxReservationtypesV2Service.ReservationtypesControllerFindOneByContainerTypeIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AxReservationtype>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `reservationtypesControllerFindOneByContainerTypeId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  reservationtypesControllerFindOneByContainerTypeId(
    params: {
      id: number;
    },
    context?: HttpContext
  ): Observable<AxReservationtype> {
    return this.reservationtypesControllerFindOneByContainerTypeId$Response(params, context).pipe(
      map((r: StrictHttpResponse<AxReservationtype>): AxReservationtype => r.body)
    );
  }

}
