import { Injectable } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { AppUpdate } from '@capawesome/capacitor-app-update';
import { AlertController } from '@ionic/angular';

@Injectable({
  providedIn: 'root',
})
export class AppUpdateService {
  constructor(
    private readonly alertController: AlertController,
  ) {
  }

  public async checkForAppUpdate() {
    if (Capacitor.isNativePlatform()) {
      const installedVersion = parseInt((await this.getInstalledVersion()).replace(/\./g, ''), 10);
      const latestVersion = parseInt((await this.getLatestAvailableVersion()).replace(/\./g, ''), 10);
      if (installedVersion < latestVersion) {
        await this.presentAlert();
      }
    }
  }

  private async presentAlert() {
    const alert = await this.alertController.create({
      header: 'App-Update verfügbar',
      message: 'Es steht eine neue Version unserer App bereit. Um alle Funktionen in vollem Umfang nutzen zu können, empfehlen wir, das Update durchzuführen.',
      buttons: [
        {
          text: 'Jetzt aktualisieren',
          handler: async () => {
            await AppUpdate.openAppStore();
          },
        },
        {
          text: 'Später',
          role: 'cancel',
        },
      ],
    });

    await alert.present();
  }

  private async getLatestAvailableVersion() {
    const result = await AppUpdate.getAppUpdateInfo();
    return result.availableVersion;
  }

  private async getInstalledVersion() {
    const result = await AppUpdate.getAppUpdateInfo();
    return result.currentVersion;
  }
}
