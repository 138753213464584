/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { AxConfirmScreendeviceResponseDto } from '../models/ax-confirm-screendevice-response-dto';
import { AxCreateScreendeviceDto } from '../models/ax-create-screendevice-dto';
import { AxPaginatedDocumented } from '../models/ax-paginated-documented';
import { AxRegisterScreendeviceDto } from '../models/ax-register-screendevice-dto';
import { AxScreendevice } from '../models/ax-screendevice';
import { AxSuccessDto } from '../models/ax-success-dto';
import { AxUpdateScreendeviceDto } from '../models/ax-update-screendevice-dto';

@Injectable({ providedIn: 'root' })
export class AxScreendevicesV2Service extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `screendevicesControllerFindAllPaginated()` */
  static readonly ScreendevicesControllerFindAllPaginatedPath = '/v2/screendevices';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `screendevicesControllerFindAllPaginated()` instead.
   *
   * This method doesn't expect any request body.
   */
  screendevicesControllerFindAllPaginated$Response(
    params?: {

    /**
     * Page number to retrieve.If you provide invalid value the default page number will applied
     *         <p>
     *              <b>Example: </b> 1
     *           </p>
     *         <p>
     *              <b>Default Value: </b> 1
     *           </p>
     */
      page?: number;

    /**
     * Number of records per page.
     *       <p>
     *              <b>Example: </b> 20
     *           </p>
     *       <p>
     *              <b>Default Value: </b> 25
     *           </p>
     *       <p>
     *              <b>Max Value: </b> 100
     *           </p>
     *
     *       If provided value is greater than max value, max value will be applied.
     */
      limit?: number;

    /**
     * Filter by active query param.
     *           <p>
     *              <b>Format: </b> filter.active={$not}:OPERATION:VALUE
     *           </p>
     *           <p>
     *              <b>Example: </b> filter.active=$not:$like:John Doe&filter.active=like:John
     *           </p>
     *           <h4>Available Operations</h4><ul><li>$eq</li></ul>
     */
      'filter.active'?: Array<string>;

    /**
     * Filter by confirmed query param.
     *           <p>
     *              <b>Format: </b> filter.confirmed={$not}:OPERATION:VALUE
     *           </p>
     *           <p>
     *              <b>Example: </b> filter.confirmed=$not:$like:John Doe&filter.confirmed=like:John
     *           </p>
     *           <h4>Available Operations</h4><ul><li>$eq</li></ul>
     */
      'filter.confirmed'?: Array<string>;

    /**
     * Parameter to sort by.
     *       <p>To sort by multiple fields, just provide query param multiple types. The order in url defines an order of sorting</p>
     *       <p>
     *              <b>Format: </b> fieldName:DIRECTION
     *           </p>
     *       <p>
     *              <b>Example: </b> sortBy=id:DESC&sortBy=createdAt:ASC
     *           </p>
     *       <p>
     *              <b>Default Value: </b> name:ASC
     *           </p>
     *       <h4>Available Fields</h4><ul><li>name</li>
     * <li>uniqueIdentifier</li>
     * <li>ipAddress</li></ul>
     */
      sortBy?: Array<'name:ASC' | 'name:DESC' | 'uniqueIdentifier:ASC' | 'uniqueIdentifier:DESC' | 'ipAddress:ASC' | 'ipAddress:DESC'>;

    /**
     * Search term to filter result values
     *         <p>
     *              <b>Example: </b> John
     *           </p>
     *         <p>
     *              <b>Default Value: </b> No default value
     *           </p>
     */
      search?: string;

    /**
     * List of fields to search by term to filter result values
     *         <p>
     *              <b>Example: </b> name,uniqueIdentifier
     *           </p>
     *         <p>
     *              <b>Default Value: </b> By default all fields mentioned below will be used to search by term
     *           </p>
     *         <h4>Available Fields</h4><ul><li>name</li>
     * <li>uniqueIdentifier</li></ul>
     */
      searchBy?: Array<string>;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AxPaginatedDocumented & {
'data'?: Array<AxScreendevice>;
'meta'?: {
'select'?: Array<string>;
'filter'?: {
'active'?: (string | Array<string>);
'confirmed'?: (string | Array<string>);
};
};
}>> {
    const rb = new RequestBuilder(this.rootUrl, AxScreendevicesV2Service.ScreendevicesControllerFindAllPaginatedPath, 'get');
    if (params) {
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.query('filter.active', params['filter.active'], {});
      rb.query('filter.confirmed', params['filter.confirmed'], {});
      rb.query('sortBy', params.sortBy, {});
      rb.query('search', params.search, {});
      rb.query('searchBy', params.searchBy, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AxPaginatedDocumented & {
        'data'?: Array<AxScreendevice>;
        'meta'?: {
        'select'?: Array<string>;
        'filter'?: {
        'active'?: (string | Array<string>);
        'confirmed'?: (string | Array<string>);
        };
        };
        }>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `screendevicesControllerFindAllPaginated$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  screendevicesControllerFindAllPaginated(
    params?: {

    /**
     * Page number to retrieve.If you provide invalid value the default page number will applied
     *         <p>
     *              <b>Example: </b> 1
     *           </p>
     *         <p>
     *              <b>Default Value: </b> 1
     *           </p>
     */
      page?: number;

    /**
     * Number of records per page.
     *       <p>
     *              <b>Example: </b> 20
     *           </p>
     *       <p>
     *              <b>Default Value: </b> 25
     *           </p>
     *       <p>
     *              <b>Max Value: </b> 100
     *           </p>
     *
     *       If provided value is greater than max value, max value will be applied.
     */
      limit?: number;

    /**
     * Filter by active query param.
     *           <p>
     *              <b>Format: </b> filter.active={$not}:OPERATION:VALUE
     *           </p>
     *           <p>
     *              <b>Example: </b> filter.active=$not:$like:John Doe&filter.active=like:John
     *           </p>
     *           <h4>Available Operations</h4><ul><li>$eq</li></ul>
     */
      'filter.active'?: Array<string>;

    /**
     * Filter by confirmed query param.
     *           <p>
     *              <b>Format: </b> filter.confirmed={$not}:OPERATION:VALUE
     *           </p>
     *           <p>
     *              <b>Example: </b> filter.confirmed=$not:$like:John Doe&filter.confirmed=like:John
     *           </p>
     *           <h4>Available Operations</h4><ul><li>$eq</li></ul>
     */
      'filter.confirmed'?: Array<string>;

    /**
     * Parameter to sort by.
     *       <p>To sort by multiple fields, just provide query param multiple types. The order in url defines an order of sorting</p>
     *       <p>
     *              <b>Format: </b> fieldName:DIRECTION
     *           </p>
     *       <p>
     *              <b>Example: </b> sortBy=id:DESC&sortBy=createdAt:ASC
     *           </p>
     *       <p>
     *              <b>Default Value: </b> name:ASC
     *           </p>
     *       <h4>Available Fields</h4><ul><li>name</li>
     * <li>uniqueIdentifier</li>
     * <li>ipAddress</li></ul>
     */
      sortBy?: Array<'name:ASC' | 'name:DESC' | 'uniqueIdentifier:ASC' | 'uniqueIdentifier:DESC' | 'ipAddress:ASC' | 'ipAddress:DESC'>;

    /**
     * Search term to filter result values
     *         <p>
     *              <b>Example: </b> John
     *           </p>
     *         <p>
     *              <b>Default Value: </b> No default value
     *           </p>
     */
      search?: string;

    /**
     * List of fields to search by term to filter result values
     *         <p>
     *              <b>Example: </b> name,uniqueIdentifier
     *           </p>
     *         <p>
     *              <b>Default Value: </b> By default all fields mentioned below will be used to search by term
     *           </p>
     *         <h4>Available Fields</h4><ul><li>name</li>
     * <li>uniqueIdentifier</li></ul>
     */
      searchBy?: Array<string>;
    },
    context?: HttpContext
  ): Observable<AxPaginatedDocumented & {
'data'?: Array<AxScreendevice>;
'meta'?: {
'select'?: Array<string>;
'filter'?: {
'active'?: (string | Array<string>);
'confirmed'?: (string | Array<string>);
};
};
}> {
    return this.screendevicesControllerFindAllPaginated$Response(params, context).pipe(
      map((r: StrictHttpResponse<AxPaginatedDocumented & {
'data'?: Array<AxScreendevice>;
'meta'?: {
'select'?: Array<string>;
'filter'?: {
'active'?: (string | Array<string>);
'confirmed'?: (string | Array<string>);
};
};
}>): AxPaginatedDocumented & {
'data'?: Array<AxScreendevice>;
'meta'?: {
'select'?: Array<string>;
'filter'?: {
'active'?: (string | Array<string>);
'confirmed'?: (string | Array<string>);
};
};
} => r.body)
    );
  }

  /** Path part for operation `screendevicesControllerCreate()` */
  static readonly ScreendevicesControllerCreatePath = '/v2/screendevices';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `screendevicesControllerCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  screendevicesControllerCreate$Response(
    params: {
      body: AxCreateScreendeviceDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AxScreendevice>> {
    const rb = new RequestBuilder(this.rootUrl, AxScreendevicesV2Service.ScreendevicesControllerCreatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AxScreendevice>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `screendevicesControllerCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  screendevicesControllerCreate(
    params: {
      body: AxCreateScreendeviceDto
    },
    context?: HttpContext
  ): Observable<AxScreendevice> {
    return this.screendevicesControllerCreate$Response(params, context).pipe(
      map((r: StrictHttpResponse<AxScreendevice>): AxScreendevice => r.body)
    );
  }

  /** Path part for operation `screendevicesControllerFindById()` */
  static readonly ScreendevicesControllerFindByIdPath = '/v2/screendevices/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `screendevicesControllerFindById()` instead.
   *
   * This method doesn't expect any request body.
   */
  screendevicesControllerFindById$Response(
    params: {
      id: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AxScreendevice>> {
    const rb = new RequestBuilder(this.rootUrl, AxScreendevicesV2Service.ScreendevicesControllerFindByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AxScreendevice>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `screendevicesControllerFindById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  screendevicesControllerFindById(
    params: {
      id: number;
    },
    context?: HttpContext
  ): Observable<AxScreendevice> {
    return this.screendevicesControllerFindById$Response(params, context).pipe(
      map((r: StrictHttpResponse<AxScreendevice>): AxScreendevice => r.body)
    );
  }

  /** Path part for operation `screendevicesControllerDelete()` */
  static readonly ScreendevicesControllerDeletePath = '/v2/screendevices/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `screendevicesControllerDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  screendevicesControllerDelete$Response(
    params: {
      id: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<boolean>> {
    const rb = new RequestBuilder(this.rootUrl, AxScreendevicesV2Service.ScreendevicesControllerDeletePath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `screendevicesControllerDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  screendevicesControllerDelete(
    params: {
      id: number;
    },
    context?: HttpContext
  ): Observable<boolean> {
    return this.screendevicesControllerDelete$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `screendevicesControllerUpdate()` */
  static readonly ScreendevicesControllerUpdatePath = '/v2/screendevices/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `screendevicesControllerUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  screendevicesControllerUpdate$Response(
    params: {
      id: number;
      body: AxUpdateScreendeviceDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AxScreendevice>> {
    const rb = new RequestBuilder(this.rootUrl, AxScreendevicesV2Service.ScreendevicesControllerUpdatePath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AxScreendevice>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `screendevicesControllerUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  screendevicesControllerUpdate(
    params: {
      id: number;
      body: AxUpdateScreendeviceDto
    },
    context?: HttpContext
  ): Observable<AxScreendevice> {
    return this.screendevicesControllerUpdate$Response(params, context).pipe(
      map((r: StrictHttpResponse<AxScreendevice>): AxScreendevice => r.body)
    );
  }

  /** Path part for operation `screendevicesControllerFindByUniqueIdentifier()` */
  static readonly ScreendevicesControllerFindByUniqueIdentifierPath = '/v2/screendevices/byUniqueIdentifier/{uniqueIdentifier}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `screendevicesControllerFindByUniqueIdentifier()` instead.
   *
   * This method doesn't expect any request body.
   */
  screendevicesControllerFindByUniqueIdentifier$Response(
    params: {
      uniqueIdentifier: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AxScreendevice>> {
    const rb = new RequestBuilder(this.rootUrl, AxScreendevicesV2Service.ScreendevicesControllerFindByUniqueIdentifierPath, 'get');
    if (params) {
      rb.path('uniqueIdentifier', params.uniqueIdentifier, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AxScreendevice>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `screendevicesControllerFindByUniqueIdentifier$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  screendevicesControllerFindByUniqueIdentifier(
    params: {
      uniqueIdentifier: string;
    },
    context?: HttpContext
  ): Observable<AxScreendevice> {
    return this.screendevicesControllerFindByUniqueIdentifier$Response(params, context).pipe(
      map((r: StrictHttpResponse<AxScreendevice>): AxScreendevice => r.body)
    );
  }

  /** Path part for operation `screendevicesControllerAddSlideToDevice()` */
  static readonly ScreendevicesControllerAddSlideToDevicePath = '/v2/screendevices/{id}/slides/{slideId}/{position}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `screendevicesControllerAddSlideToDevice()` instead.
   *
   * This method doesn't expect any request body.
   */
  screendevicesControllerAddSlideToDevice$Response(
    params: {
      id: number;
      slideId: number;
      position: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AxSuccessDto>> {
    const rb = new RequestBuilder(this.rootUrl, AxScreendevicesV2Service.ScreendevicesControllerAddSlideToDevicePath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('slideId', params.slideId, {});
      rb.path('position', params.position, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AxSuccessDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `screendevicesControllerAddSlideToDevice$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  screendevicesControllerAddSlideToDevice(
    params: {
      id: number;
      slideId: number;
      position: number;
    },
    context?: HttpContext
  ): Observable<AxSuccessDto> {
    return this.screendevicesControllerAddSlideToDevice$Response(params, context).pipe(
      map((r: StrictHttpResponse<AxSuccessDto>): AxSuccessDto => r.body)
    );
  }

  /** Path part for operation `screendevicesControllerRemoveSlideFromDevice()` */
  static readonly ScreendevicesControllerRemoveSlideFromDevicePath = '/v2/screendevices/{id}/slides/{slideId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `screendevicesControllerRemoveSlideFromDevice()` instead.
   *
   * This method doesn't expect any request body.
   */
  screendevicesControllerRemoveSlideFromDevice$Response(
    params: {
      id: number;
      slideId: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AxSuccessDto>> {
    const rb = new RequestBuilder(this.rootUrl, AxScreendevicesV2Service.ScreendevicesControllerRemoveSlideFromDevicePath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('slideId', params.slideId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AxSuccessDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `screendevicesControllerRemoveSlideFromDevice$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  screendevicesControllerRemoveSlideFromDevice(
    params: {
      id: number;
      slideId: number;
    },
    context?: HttpContext
  ): Observable<AxSuccessDto> {
    return this.screendevicesControllerRemoveSlideFromDevice$Response(params, context).pipe(
      map((r: StrictHttpResponse<AxSuccessDto>): AxSuccessDto => r.body)
    );
  }

  /** Path part for operation `screendevicesControllerRegister()` */
  static readonly ScreendevicesControllerRegisterPath = '/v2/screendevices/register';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `screendevicesControllerRegister()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  screendevicesControllerRegister$Response(
    params: {

    /**
     * This request requires a valid API key.
     */
      'x-api-key'?: string;
      body: AxRegisterScreendeviceDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AxConfirmScreendeviceResponseDto>> {
    const rb = new RequestBuilder(this.rootUrl, AxScreendevicesV2Service.ScreendevicesControllerRegisterPath, 'post');
    if (params) {
      rb.header('x-api-key', params['x-api-key'], {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AxConfirmScreendeviceResponseDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `screendevicesControllerRegister$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  screendevicesControllerRegister(
    params: {

    /**
     * This request requires a valid API key.
     */
      'x-api-key'?: string;
      body: AxRegisterScreendeviceDto
    },
    context?: HttpContext
  ): Observable<AxConfirmScreendeviceResponseDto> {
    return this.screendevicesControllerRegister$Response(params, context).pipe(
      map((r: StrictHttpResponse<AxConfirmScreendeviceResponseDto>): AxConfirmScreendeviceResponseDto => r.body)
    );
  }

  /** Path part for operation `screendevicesControllerFindDataByUniqueIdentifier()` */
  static readonly ScreendevicesControllerFindDataByUniqueIdentifierPath = '/v2/screendevices/data/{uniqueIdentifier}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `screendevicesControllerFindDataByUniqueIdentifier()` instead.
   *
   * This method doesn't expect any request body.
   */
  screendevicesControllerFindDataByUniqueIdentifier$Response(
    params: {
      uniqueIdentifier: string;

    /**
     * This request requires a valid API key.
     */
      'x-api-key'?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AxScreendevice>> {
    const rb = new RequestBuilder(this.rootUrl, AxScreendevicesV2Service.ScreendevicesControllerFindDataByUniqueIdentifierPath, 'get');
    if (params) {
      rb.path('uniqueIdentifier', params.uniqueIdentifier, {});
      rb.header('x-api-key', params['x-api-key'], {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AxScreendevice>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `screendevicesControllerFindDataByUniqueIdentifier$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  screendevicesControllerFindDataByUniqueIdentifier(
    params: {
      uniqueIdentifier: string;

    /**
     * This request requires a valid API key.
     */
      'x-api-key'?: string;
    },
    context?: HttpContext
  ): Observable<AxScreendevice> {
    return this.screendevicesControllerFindDataByUniqueIdentifier$Response(params, context).pipe(
      map((r: StrictHttpResponse<AxScreendevice>): AxScreendevice => r.body)
    );
  }

}
