import { Pipe, PipeTransform } from '@angular/core';

export enum AxTaskStatus {
  inProgress = 'inProgress',
  done = 'done',
  open = 'open',
}

@Pipe({name: 'getIconForStatus'})
export class AxApiGetIconForStatusPipe implements PipeTransform {
  transform(status: AxTaskStatus | string) {
    switch (status) {

      case 'Genehmigt':
        return 'checkmark-outline';

      case 'Abgelehnt':
        return 'close-outline';

      case 'Zurückgezogen':
        return 'close-outline';

      default:
        return 'timer-outline';
    }
  }
}
