import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { AxCommonsApiModule } from 'src/app/ax-commons-api/ax-commons-api.module';
import { InfoModalPage } from 'src/app/pages/global/info-modal/info-modal.page';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    AxCommonsApiModule,
  ],
  declarations: [InfoModalPage],
})
export class InfoModalPageModule {
}
