import { Component, HostBinding, Input, OnChanges } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { AxFilesService } from 'src/app/_generated/axova-rest-api';

@Component({
  selector: 'ax-secured-image',
  template: `
    <img [src]="dataUrl$|async" (load)="onImageLoad()" [hidden]="!isImageLoaded"/>
  `,
  styleUrls: ['ax-secured-image.component.scss'],
})
export class AxSecuredImageComponent implements OnChanges {
  @HostBinding('class.square') isSquare: boolean = false;
  @HostBinding('class.fill') isFill: boolean = false;
  public isImageLoaded = false;

  // This part just creates an rxjs stream from the src
  // this makes sure that we can handle it when the src changes
  // or even when the component gets destroyed
  @Input() folder: string | undefined = undefined;
  @Input() filename: string | undefined = undefined;
  @Input() ratio: 'square' | 'original' = 'square';
  @Input() fill: boolean = false;
  @Input() width: number;
  private src$ = new BehaviorSubject(this.filename);

  // this stream will contain the actual url that our img tag will load
  // everytime the src changes, the previous call would be canceled and the
  // new resource would be loaded
  dataUrl$ = this.src$.pipe(
    switchMap(url => this.loadImage()),
  );

  ngOnChanges(): void {
    this.src$.next(this.filename);
    this.isSquare = this.ratio === 'square';
    this.isFill = this.fill;
  }

  // we need HttpClient to load the image and DomSanitizer to trust the url
  constructor(
    private domSanitizer: DomSanitizer,
    private axFilesService: AxFilesService,
  ) {
  }

  public onImageLoad() {
    this.isImageLoaded = true;
  }

  private loadImage(): Observable<any> {
    return this.axFilesService
      .filesControllerView({
        folder: this.folder,
        filename: this.filename,
        width: this.width ? this.width : null,
      }).pipe(
        map(e => this.domSanitizer.bypassSecurityTrustUrl(URL.createObjectURL(e))),
      );
  }
}
