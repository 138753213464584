import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalController, NavController } from '@ionic/angular';
import { Store } from '@ngxs/store';
import { Subscription } from 'rxjs';
import { AxElectricalMeasurement, AxElectricalMeasurementsService } from 'src/app/_generated/axova-rest-api';
import { CreateElectricalMeasurementClear, CreateElectricalMeasurementDeleteMeasurementAtIndex } from 'src/app/ngxs/create-electrical-measurement/create-electrical-measurement.actions';
import { CreateElectricalMeasurementState } from 'src/app/ngxs/create-electrical-measurement/create-electrical-measurement.state';
import { CreateElectricalMeasurementDetailPage } from 'src/app/pages/global/create-electrical-measurement/create-electrical-measurement-detail/create-electrical-measurement-detail.page';
import { AxUiService } from 'src/app/services/ax-ui.service';

@Component({
  selector: 'app-create-electrical-measurement-overview',
  templateUrl: './create-electrical-measurement-overview.page.html',
  styleUrls: ['./create-electrical-measurement-overview.page.scss'],
})
export class CreateElectricalMeasurementOverviewPage implements OnInit {
  public measurements$ = this.store.select(CreateElectricalMeasurementState.measurements);
  public basemeasurement$ = this.store.select(CreateElectricalMeasurementState.baseMeasurement);
  public projectId: number | undefined;
  private measurementsSubscription: Subscription | undefined;

  constructor(
    private router: Router,
    private title: Title,
    private store: Store,
    private axUiService: AxUiService,
    private activatedRoute: ActivatedRoute,
    private navController: NavController,
    private modalController: ModalController,
    private axElectricalMeasurementsService: AxElectricalMeasurementsService,
  ) {
  }

  ngOnInit() {
    this.activatedRoute.params.subscribe(data => {
      if (data.id) {
        this.projectId = data.id;
      }
    });

    // If no measurement, create new.
    this.measurementsSubscription = this.measurements$.subscribe(measurements => {
      if (!measurements || !measurements.length) {
        this.add();
      }
    });
  }

  ngOnDestroy() {
    if (this.measurementsSubscription) {
      this.measurementsSubscription.unsubscribe();
    }
  }

  ionViewDidEnter() {
    this.title.setTitle(`Projektfotos hochladen`);
  }

  public goBack() {
    this.router.navigateByUrl('');
  }

  public add() {
    this.modalController.create({
      component: CreateElectricalMeasurementDetailPage,
    }).then(instance => {
      instance.present().then();
    });
  }

  public edit(electricalMeasurement: Partial<AxElectricalMeasurement>, indexOfMeasurement: number) {
    this.modalController.create({
      component: CreateElectricalMeasurementDetailPage,
      componentProps: {
        indexOfMeasurement,
        electricalMeasurement: JSON.parse(JSON.stringify(electricalMeasurement)),
      },
    }).then(instance => {
      instance.present().then();
    });
  }

  async deleteAtIndex(indexOfMeasurement: number) {
    await this.store.dispatch(new CreateElectricalMeasurementDeleteMeasurementAtIndex(indexOfMeasurement)).toPromise();
  }

  public async save() {
    await this.axUiService.showLoading();
    const aggregatedMeasurements = this.store.selectSnapshot(CreateElectricalMeasurementState.aggregatedMeasurements);
    const allPromises: Promise<any>[] = [];
    for (const aggregatedMeasurement of aggregatedMeasurements) {
      allPromises.push(this.axElectricalMeasurementsService.electricalMeasurementsControllerCreate({
        body: aggregatedMeasurement as AxElectricalMeasurement,
      }).toPromise());
    }
    Promise.all(allPromises)
      .then(async result => {
        if (this.measurementsSubscription) {
          this.measurementsSubscription.unsubscribe();
        }
        this.axUiService.showSuccess('Messungen wurden übermittelt');
        this.store.dispatch(new CreateElectricalMeasurementClear());
        await this.axUiService.hideLoading();
        this.navController.navigateForward(`/global/project-detail/${aggregatedMeasurements[0].projectId}`).then();
      })
      .catch(async () => {
        await this.axUiService.hideLoading();
        await this.axUiService.showError('Messungen konnte nicht übermittelt werden. Bitte eingegebene Werte prüfen.');
      });
  }
}
