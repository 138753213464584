import { Component, Input, OnInit } from '@angular/core';
import { NavController } from '@ionic/angular';
import { AxUser } from 'src/app/_generated/axova-rest-api';

@Component({
  selector: 'ax-person-infobox',
  templateUrl: './ax-person-infobox.component.html',
  styleUrls: ['./ax-person-infobox.component.scss'],
})
export class AxPersonInfoboxComponent implements OnInit {
  @Input() person: AxUser;
  @Input() jobtitleOverride: string;
  @Input() passUserDetails = false;
  @Input() clickForDetails = true;
  @Input() detailIcon: string | undefined;
  @Input() showLine = true;

  constructor(
    private navController: NavController,
  ) {
  }

  ngOnInit() {
  }

  /**
   * Open user detail page, where detail data will be loaded via API.
   *
   * @param user
   */
  public openUserDetails(user: AxUser): void {
    if (!this.clickForDetails) {
      return;
    }
    let options = {};
    if (this.passUserDetails) {
      options = {
        state: {
          user,
        },
      };
    }
    this.navController.navigateForward(`/global/user/${user.id}`, options).then();
  }
}
