
import { FormGroup, FormControl, FormArray, Validators } from '@angular/forms';
import { requiredBoolean } from './required-boolean.validator';

export function getCreateEquipmentDtoFormGroup() {
return new FormGroup({
    qrcodeId: new FormControl<number | undefined>(undefined),
    equipmenttypeId: new FormControl<number | undefined>(undefined, [Validators.required]),
    parentId: new FormControl<number | undefined>(undefined),
    vendorId: new FormControl<number | undefined>(undefined),
    brand: new FormControl<string | undefined>(undefined),
    model: new FormControl<string | undefined>(undefined),
    description: new FormControl<string | undefined>(undefined),
    identifier: new FormControl<string | undefined>(undefined),
    purchaseChfExcl: new FormControl<number | undefined>(undefined),
    purchased: new FormControl<string | undefined>(undefined),
    leasingStart: new FormControl<string | undefined>(undefined),
    leasingEnd: new FormControl<string | undefined>(undefined),
    expiring: new FormControl<string | undefined>(undefined),
    warrantyValidUntil: new FormControl<string | undefined>(undefined),
    businessunitId: new FormControl<number | undefined>(undefined),
    businessdivisionId: new FormControl<number | undefined>(undefined),
    active: new FormControl<boolean | undefined>(true),
    requiresAdmin: new FormControl<boolean | undefined>(false, [requiredBoolean()]),
})
;
}
      