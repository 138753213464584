import { AxAppDevice } from 'src/app/_generated/axova-rest-api';
import { AxContact } from 'src/app/_generated/axova-rest-api/models/ax-contact';
import { AxUser } from 'src/app/_generated/axova-rest-api/models/ax-user';

// TODO: Alle Gruppen über Schnittstellen ziehen.

export enum RolesEnum {
  abteilungsleiter = 'ax_abteilungsleiter',
  administration = 'ax_administration',
  einkauf = 'ax_einkauf',
  extern = 'ax_extern',
  finanzen = 'ax_finanzen',
  geschaeftsleitung = 'ax_geschaeftsleitung',
  intern = 'ax_intern',
  kundenberater = 'ax_kundenberater',
  monteur = 'ax_monteur',
  hr = 'ax_hr',
  planer = 'ax_planer',
  projektleiter = 'ax_projektleiter',
  teamleiter = 'ax_teamleiter',
  verwaltungsrat = 'ax_verwaltungsrat',
  kunde = 'ax_kunde',
  elektriker = 'ax_elektriker',
}


export class RolesEnumGroups {
  static alle_internen_mitarbeitenden = [
    RolesEnum.intern,
    RolesEnum.abteilungsleiter,
    RolesEnum.administration,
    RolesEnum.einkauf,
    RolesEnum.finanzen,
    RolesEnum.geschaeftsleitung,
    RolesEnum.kundenberater,
    RolesEnum.monteur,
    RolesEnum.hr,
    RolesEnum.planer,
    RolesEnum.projektleiter,
    RolesEnum.teamleiter,
    RolesEnum.verwaltungsrat,
  ];
  static alle_mitarbeitenden = [
    ...RolesEnumGroups.alle_internen_mitarbeitenden,
    RolesEnum.extern,
  ];
  static ab_stufe_abteilungsleiter = [
    RolesEnum.abteilungsleiter,
    RolesEnum.administration,
    RolesEnum.geschaeftsleitung,
    RolesEnum.hr,
  ];
  static ab_stufe_kundenberater = [
    RolesEnum.kundenberater,
    RolesEnum.projektleiter,
    RolesEnum.abteilungsleiter,
    RolesEnum.administration,
    RolesEnum.geschaeftsleitung,
    RolesEnum.hr,
  ];
}


export interface AppStateModel {
  profile: {
    id: number;
    firstname: string;
    lastname: string;
    roles?: string[] | RolesEnum[];
    permissions?: string[] | RolesEnum[];
    isUserEntity: boolean;
    isContactEntity: boolean;
    meta: AxContact | AxUser;
  };
  appDeviceId: number;
}
