
import { FormGroup, FormControl, FormArray, Validators } from '@angular/forms';


export function getAddStandardTaskGroupsToProjectDtoFormGroup() {
return new FormGroup({
    projectId: new FormControl<number | undefined>(undefined, [Validators.required]),
    standardtaskgroupIds: new FormControl<Array<number> | undefined>([], [Validators.required]),
})
;
}
      