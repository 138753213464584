import { Pipe, PipeTransform } from '@angular/core';

export enum AxTaskStatus {
  inProgress = 'inProgress',
  done = 'done',
  open = 'open',
}

@Pipe({name: 'getColorForStatus'})
export class AxApiGetColorForStatusPipe implements PipeTransform {
  transform(status: AxTaskStatus | string) {
    switch (status) {

      case AxTaskStatus.done:
      case 'Beendet':
      case 'Administrativer Abschluss':
      case 'Genehmigt':
        return 'primary';

      case AxTaskStatus.inProgress:
      case 'Planung':
      case 'Ausführung':
      case 'In Prüfung':
      case 'Offen':
        return 'warning';

      case AxTaskStatus.open:
      case 'Nicht realisiert':
      case 'Zurückgezogen':
        return 'light';

      case 'Abgelehnt':
        return 'danger';

      default:
        return 'light';
    }
  }
}
