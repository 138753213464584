import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
@Pipe({
  name: 'prependServerToFileSrc',
})
export class PrependServerToFileSrcPipe implements PipeTransform {
  transform(fileSrc: string | null | undefined): string {
    if (fileSrc) {
      return `${environment.apiConfiguration.baseUrl}${fileSrc}`;
    }
    return '';
  }
}
