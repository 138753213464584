import { Injectable } from '@angular/core';
import { AxTokenService } from 'src/app/ax-commons-api/ax-api-auth/ax-token.service';
import { LoggerService } from 'src/app/ax-commons-logger/logger/logger.service';
import * as moment from 'moment';

@Injectable()
export class AppInitializer {

  constructor(
    private axTokenService: AxTokenService,
  ) {
  }

  init() {
    return new Promise<void>(async (resolve, reject) => {
      try {
        const accessTokenLoadedFromSecureStorage = await this.axTokenService.getAccessToken();
        const refreshTokenLoadedFromSecureStorage = await this.axTokenService.getRefreshToken();
        LoggerService.LOG(this, 'accessTokenLoadedFromSecureStorage', accessTokenLoadedFromSecureStorage);
        LoggerService.LOG(this, 'refreshTokenLoadedFromSecureStorage', refreshTokenLoadedFromSecureStorage);
        moment.updateLocale('de-ch', {
          week: {
            dow: 2,
          },
        });
        resolve();
      } catch (appInitializerException) {
        LoggerService.ERROR(this, 'appInitializerException', appInitializerException);
        resolve();
      }
    });
  }
}
