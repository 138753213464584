import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { AxEquipmentlog, AxEquipmentlogsV2Service } from '../../_generated/axova-rest-api';

@Component({
  selector: 'ax-office-betriebsmittel-aktueller-ort',
  templateUrl: './betriebsmittel-aktueller-ort.component.html',
  styleUrls: ['./betriebsmittel-aktueller-ort.component.scss'],
})
export class BetriebsmittelAktuellerOrtComponent implements OnChanges {
  @Input() equipmentId!: number;
  @Input() renderTextOnly = false;
  @Input() withBadge = true;
  public equipmentlog: AxEquipmentlog | undefined;
  public hasReadPermission = true;

  constructor(
    private readonly axEquipmentlogsV2Service: AxEquipmentlogsV2Service,
  ) {
  }

  async ngOnChanges(changes: SimpleChanges) {
    if (changes.equipmentId && changes.equipmentId.currentValue !== changes.equipmentId.previousValue) {
      await this.loadLatestEquipmentlogForEquipment();
    }
  }

  public async loadLatestEquipmentlogForEquipment() {
    try {
      const paginatedData = await this.axEquipmentlogsV2Service.equipmentlogsControllerFindAllPaginated({
        'filter.equipmentId': [`$eq:${this.equipmentId}`],
        limit: 1,
        sortBy: ['created:DESC'],
        'filter.equipmentlogtype.isStatus': ['1'],
      }).toPromise();
      if (paginatedData.data.length) {
        this.equipmentlog = paginatedData.data[0];
      }
    } catch (loadAxEventException) {
    }
  }
}
