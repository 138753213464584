/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { AxCreateStocklocationDto } from '../models/ax-create-stocklocation-dto';
import { AxDropdownOptionDto } from '../models/ax-dropdown-option-dto';
import { AxPaginatedDocumented } from '../models/ax-paginated-documented';
import { AxStocklocation } from '../models/ax-stocklocation';
import { AxUpdateStocklocationDto } from '../models/ax-update-stocklocation-dto';

@Injectable({ providedIn: 'root' })
export class AxStocklocationsV2Service extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `stocklocationsControllerGetAllDropdownOptions()` */
  static readonly StocklocationsControllerGetAllDropdownOptionsPath = '/v2/stocklocations/dropdown-options';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `stocklocationsControllerGetAllDropdownOptions()` instead.
   *
   * This method doesn't expect any request body.
   */
  stocklocationsControllerGetAllDropdownOptions$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<AxDropdownOptionDto>>> {
    const rb = new RequestBuilder(this.rootUrl, AxStocklocationsV2Service.StocklocationsControllerGetAllDropdownOptionsPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AxDropdownOptionDto>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `stocklocationsControllerGetAllDropdownOptions$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  stocklocationsControllerGetAllDropdownOptions(
    params?: {
    },
    context?: HttpContext
  ): Observable<Array<AxDropdownOptionDto>> {
    return this.stocklocationsControllerGetAllDropdownOptions$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<AxDropdownOptionDto>>): Array<AxDropdownOptionDto> => r.body)
    );
  }

  /** Path part for operation `stocklocationsControllerGetAllStocklocations()` */
  static readonly StocklocationsControllerGetAllStocklocationsPath = '/v2/stocklocations/all';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `stocklocationsControllerGetAllStocklocations()` instead.
   *
   * This method doesn't expect any request body.
   */
  stocklocationsControllerGetAllStocklocations$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<AxStocklocation>>> {
    const rb = new RequestBuilder(this.rootUrl, AxStocklocationsV2Service.StocklocationsControllerGetAllStocklocationsPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AxStocklocation>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `stocklocationsControllerGetAllStocklocations$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  stocklocationsControllerGetAllStocklocations(
    params?: {
    },
    context?: HttpContext
  ): Observable<Array<AxStocklocation>> {
    return this.stocklocationsControllerGetAllStocklocations$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<AxStocklocation>>): Array<AxStocklocation> => r.body)
    );
  }

  /** Path part for operation `stocklocationsControllerGetAllChildrenStocklocationsForId()` */
  static readonly StocklocationsControllerGetAllChildrenStocklocationsForIdPath = '/v2/stocklocations/children/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `stocklocationsControllerGetAllChildrenStocklocationsForId()` instead.
   *
   * This method doesn't expect any request body.
   */
  stocklocationsControllerGetAllChildrenStocklocationsForId$Response(
    params: {
      id: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AxStocklocation>> {
    const rb = new RequestBuilder(this.rootUrl, AxStocklocationsV2Service.StocklocationsControllerGetAllChildrenStocklocationsForIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AxStocklocation>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `stocklocationsControllerGetAllChildrenStocklocationsForId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  stocklocationsControllerGetAllChildrenStocklocationsForId(
    params: {
      id: number;
    },
    context?: HttpContext
  ): Observable<AxStocklocation> {
    return this.stocklocationsControllerGetAllChildrenStocklocationsForId$Response(params, context).pipe(
      map((r: StrictHttpResponse<AxStocklocation>): AxStocklocation => r.body)
    );
  }

  /** Path part for operation `stocklocationsControllerGetAllParentStocklocationsForId()` */
  static readonly StocklocationsControllerGetAllParentStocklocationsForIdPath = '/v2/stocklocations/parent/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `stocklocationsControllerGetAllParentStocklocationsForId()` instead.
   *
   * This method doesn't expect any request body.
   */
  stocklocationsControllerGetAllParentStocklocationsForId$Response(
    params: {
      id: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AxStocklocation>> {
    const rb = new RequestBuilder(this.rootUrl, AxStocklocationsV2Service.StocklocationsControllerGetAllParentStocklocationsForIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AxStocklocation>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `stocklocationsControllerGetAllParentStocklocationsForId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  stocklocationsControllerGetAllParentStocklocationsForId(
    params: {
      id: number;
    },
    context?: HttpContext
  ): Observable<AxStocklocation> {
    return this.stocklocationsControllerGetAllParentStocklocationsForId$Response(params, context).pipe(
      map((r: StrictHttpResponse<AxStocklocation>): AxStocklocation => r.body)
    );
  }

  /** Path part for operation `stocklocationsControllerFindAllPaginated()` */
  static readonly StocklocationsControllerFindAllPaginatedPath = '/v2/stocklocations';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `stocklocationsControllerFindAllPaginated()` instead.
   *
   * This method doesn't expect any request body.
   */
  stocklocationsControllerFindAllPaginated$Response(
    params?: {

    /**
     * Page number to retrieve.If you provide invalid value the default page number will applied
     *         <p>
     *              <b>Example: </b> 1
     *           </p>
     *         <p>
     *              <b>Default Value: </b> 1
     *           </p>
     */
      page?: number;

    /**
     * Number of records per page.
     *       <p>
     *              <b>Example: </b> 20
     *           </p>
     *       <p>
     *              <b>Default Value: </b> 25
     *           </p>
     *       <p>
     *              <b>Max Value: </b> 100
     *           </p>
     *
     *       If provided value is greater than max value, max value will be applied.
     */
      limit?: number;

    /**
     * Filter by active query param.
     *           <p>
     *              <b>Format: </b> filter.active={$not}:OPERATION:VALUE
     *           </p>
     *           <p>
     *              <b>Example: </b> filter.active=$not:$like:John Doe&filter.active=like:John
     *           </p>
     *           <h4>Available Operations</h4><ul><li>$eq</li></ul>
     */
      'filter.active'?: Array<string>;

    /**
     * Filter by businessunitId query param.
     *           <p>
     *              <b>Format: </b> filter.businessunitId={$not}:OPERATION:VALUE
     *           </p>
     *           <p>
     *              <b>Example: </b> filter.businessunitId=$not:$like:John Doe&filter.businessunitId=like:John
     *           </p>
     *           <h4>Available Operations</h4><ul><li>$eq</li></ul>
     */
      'filter.businessunitId'?: Array<string>;

    /**
     * Filter by id query param.
     *           <p>
     *              <b>Format: </b> filter.id={$not}:OPERATION:VALUE
     *           </p>
     *           <p>
     *              <b>Example: </b> filter.id=$not:$like:John Doe&filter.id=like:John
     *           </p>
     *           <h4>Available Operations</h4><ul><li>$eq</li></ul>
     */
      'filter.id'?: Array<string>;

    /**
     * Parameter to sort by.
     *       <p>To sort by multiple fields, just provide query param multiple types. The order in url defines an order of sorting</p>
     *       <p>
     *              <b>Format: </b> fieldName:DIRECTION
     *           </p>
     *       <p>
     *              <b>Example: </b> sortBy=id:DESC&sortBy=createdAt:ASC
     *           </p>
     *       <p>
     *              <b>Default Value: </b> name:ASC
     *           </p>
     *       <h4>Available Fields</h4><ul><li>name</li></ul>
     */
      sortBy?: Array<string>;

    /**
     * Search term to filter result values
     *         <p>
     *              <b>Example: </b> John
     *           </p>
     *         <p>
     *              <b>Default Value: </b> No default value
     *           </p>
     */
      search?: string;

    /**
     * List of fields to search by term to filter result values
     *         <p>
     *              <b>Example: </b> name
     *           </p>
     *         <p>
     *              <b>Default Value: </b> By default all fields mentioned below will be used to search by term
     *           </p>
     *         <h4>Available Fields</h4><ul><li>name</li></ul>
     */
      searchBy?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AxPaginatedDocumented & {
'data'?: Array<AxStocklocation>;
'meta'?: {
'select'?: Array<string>;
'filter'?: {
'active'?: (string | Array<string>);
'businessunitId'?: (string | Array<string>);
'id'?: (string | Array<string>);
};
};
}>> {
    const rb = new RequestBuilder(this.rootUrl, AxStocklocationsV2Service.StocklocationsControllerFindAllPaginatedPath, 'get');
    if (params) {
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.query('filter.active', params['filter.active'], {});
      rb.query('filter.businessunitId', params['filter.businessunitId'], {});
      rb.query('filter.id', params['filter.id'], {});
      rb.query('sortBy', params.sortBy, {});
      rb.query('search', params.search, {});
      rb.query('searchBy', params.searchBy, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AxPaginatedDocumented & {
        'data'?: Array<AxStocklocation>;
        'meta'?: {
        'select'?: Array<string>;
        'filter'?: {
        'active'?: (string | Array<string>);
        'businessunitId'?: (string | Array<string>);
        'id'?: (string | Array<string>);
        };
        };
        }>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `stocklocationsControllerFindAllPaginated$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  stocklocationsControllerFindAllPaginated(
    params?: {

    /**
     * Page number to retrieve.If you provide invalid value the default page number will applied
     *         <p>
     *              <b>Example: </b> 1
     *           </p>
     *         <p>
     *              <b>Default Value: </b> 1
     *           </p>
     */
      page?: number;

    /**
     * Number of records per page.
     *       <p>
     *              <b>Example: </b> 20
     *           </p>
     *       <p>
     *              <b>Default Value: </b> 25
     *           </p>
     *       <p>
     *              <b>Max Value: </b> 100
     *           </p>
     *
     *       If provided value is greater than max value, max value will be applied.
     */
      limit?: number;

    /**
     * Filter by active query param.
     *           <p>
     *              <b>Format: </b> filter.active={$not}:OPERATION:VALUE
     *           </p>
     *           <p>
     *              <b>Example: </b> filter.active=$not:$like:John Doe&filter.active=like:John
     *           </p>
     *           <h4>Available Operations</h4><ul><li>$eq</li></ul>
     */
      'filter.active'?: Array<string>;

    /**
     * Filter by businessunitId query param.
     *           <p>
     *              <b>Format: </b> filter.businessunitId={$not}:OPERATION:VALUE
     *           </p>
     *           <p>
     *              <b>Example: </b> filter.businessunitId=$not:$like:John Doe&filter.businessunitId=like:John
     *           </p>
     *           <h4>Available Operations</h4><ul><li>$eq</li></ul>
     */
      'filter.businessunitId'?: Array<string>;

    /**
     * Filter by id query param.
     *           <p>
     *              <b>Format: </b> filter.id={$not}:OPERATION:VALUE
     *           </p>
     *           <p>
     *              <b>Example: </b> filter.id=$not:$like:John Doe&filter.id=like:John
     *           </p>
     *           <h4>Available Operations</h4><ul><li>$eq</li></ul>
     */
      'filter.id'?: Array<string>;

    /**
     * Parameter to sort by.
     *       <p>To sort by multiple fields, just provide query param multiple types. The order in url defines an order of sorting</p>
     *       <p>
     *              <b>Format: </b> fieldName:DIRECTION
     *           </p>
     *       <p>
     *              <b>Example: </b> sortBy=id:DESC&sortBy=createdAt:ASC
     *           </p>
     *       <p>
     *              <b>Default Value: </b> name:ASC
     *           </p>
     *       <h4>Available Fields</h4><ul><li>name</li></ul>
     */
      sortBy?: Array<string>;

    /**
     * Search term to filter result values
     *         <p>
     *              <b>Example: </b> John
     *           </p>
     *         <p>
     *              <b>Default Value: </b> No default value
     *           </p>
     */
      search?: string;

    /**
     * List of fields to search by term to filter result values
     *         <p>
     *              <b>Example: </b> name
     *           </p>
     *         <p>
     *              <b>Default Value: </b> By default all fields mentioned below will be used to search by term
     *           </p>
     *         <h4>Available Fields</h4><ul><li>name</li></ul>
     */
      searchBy?: string;
    },
    context?: HttpContext
  ): Observable<AxPaginatedDocumented & {
'data'?: Array<AxStocklocation>;
'meta'?: {
'select'?: Array<string>;
'filter'?: {
'active'?: (string | Array<string>);
'businessunitId'?: (string | Array<string>);
'id'?: (string | Array<string>);
};
};
}> {
    return this.stocklocationsControllerFindAllPaginated$Response(params, context).pipe(
      map((r: StrictHttpResponse<AxPaginatedDocumented & {
'data'?: Array<AxStocklocation>;
'meta'?: {
'select'?: Array<string>;
'filter'?: {
'active'?: (string | Array<string>);
'businessunitId'?: (string | Array<string>);
'id'?: (string | Array<string>);
};
};
}>): AxPaginatedDocumented & {
'data'?: Array<AxStocklocation>;
'meta'?: {
'select'?: Array<string>;
'filter'?: {
'active'?: (string | Array<string>);
'businessunitId'?: (string | Array<string>);
'id'?: (string | Array<string>);
};
};
} => r.body)
    );
  }

  /** Path part for operation `stocklocationsControllerCreate()` */
  static readonly StocklocationsControllerCreatePath = '/v2/stocklocations';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `stocklocationsControllerCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  stocklocationsControllerCreate$Response(
    params: {
      body: AxCreateStocklocationDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AxStocklocation>> {
    const rb = new RequestBuilder(this.rootUrl, AxStocklocationsV2Service.StocklocationsControllerCreatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AxStocklocation>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `stocklocationsControllerCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  stocklocationsControllerCreate(
    params: {
      body: AxCreateStocklocationDto
    },
    context?: HttpContext
  ): Observable<AxStocklocation> {
    return this.stocklocationsControllerCreate$Response(params, context).pipe(
      map((r: StrictHttpResponse<AxStocklocation>): AxStocklocation => r.body)
    );
  }

  /** Path part for operation `stocklocationsControllerFindOneById()` */
  static readonly StocklocationsControllerFindOneByIdPath = '/v2/stocklocations/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `stocklocationsControllerFindOneById()` instead.
   *
   * This method doesn't expect any request body.
   */
  stocklocationsControllerFindOneById$Response(
    params: {
      id: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AxStocklocation>> {
    const rb = new RequestBuilder(this.rootUrl, AxStocklocationsV2Service.StocklocationsControllerFindOneByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AxStocklocation>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `stocklocationsControllerFindOneById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  stocklocationsControllerFindOneById(
    params: {
      id: number;
    },
    context?: HttpContext
  ): Observable<AxStocklocation> {
    return this.stocklocationsControllerFindOneById$Response(params, context).pipe(
      map((r: StrictHttpResponse<AxStocklocation>): AxStocklocation => r.body)
    );
  }

  /** Path part for operation `stocklocationsControllerDelete()` */
  static readonly StocklocationsControllerDeletePath = '/v2/stocklocations/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `stocklocationsControllerDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  stocklocationsControllerDelete$Response(
    params: {
      id: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<boolean>> {
    const rb = new RequestBuilder(this.rootUrl, AxStocklocationsV2Service.StocklocationsControllerDeletePath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `stocklocationsControllerDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  stocklocationsControllerDelete(
    params: {
      id: number;
    },
    context?: HttpContext
  ): Observable<boolean> {
    return this.stocklocationsControllerDelete$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `stocklocationsControllerUpdate()` */
  static readonly StocklocationsControllerUpdatePath = '/v2/stocklocations/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `stocklocationsControllerUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  stocklocationsControllerUpdate$Response(
    params: {
      id: number;
      body: AxUpdateStocklocationDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, AxStocklocationsV2Service.StocklocationsControllerUpdatePath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `stocklocationsControllerUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  stocklocationsControllerUpdate(
    params: {
      id: number;
      body: AxUpdateStocklocationDto
    },
    context?: HttpContext
  ): Observable<void> {
    return this.stocklocationsControllerUpdate$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
