import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { PinchZoomModule } from '@meddv/ngx-pinch-zoom';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { AxCommonsApiModule } from 'src/app/ax-commons-api/ax-commons-api.module';
import { PdfModalPage } from 'src/app/pages/global/pdf-modal/pdf-modal.page';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    AxCommonsApiModule,
    PdfViewerModule,
    PinchZoomModule,
  ],
  declarations: [PdfModalPage],
})
export class PdfModalPageModule {
}
