import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { AxComponentsModule } from 'src/app/components/ax-components.module';
import { EventsAdminDetailPage } from 'src/app/pages/global/events-admin-detail/events-admin-detail.page';
import { PipesModule } from 'src/app/pipes/pipes.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    AxComponentsModule,
    PipesModule,
  ],
  declarations: [EventsAdminDetailPage],
})
export class EventsAdminDetailPageModule {
}
