import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'groupByEventRegistrationOption',
})
export class GroupByEventRegistrationOptionPipe implements PipeTransform {

  transform(array: any[], field: string): any[] {
    const groupedObj = array.reduce((prev, cur) => {
      if (!prev[cur[field]]) {
        prev[cur[field]] = {
          totalAmount: 0,
          title: cur.eventRegistrationOption.title,
          items: [],
        };
      }
      prev[cur[field]].totalAmount++;
      prev[cur[field]].items.push(cur);
      return prev;
    }, {});

    return Object.keys(groupedObj).map(key => ({
      eventRegistrationOptionId: key,
      ...groupedObj[key],
    }));
  }

}
