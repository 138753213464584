
import { FormGroup, FormControl, FormArray, Validators } from '@angular/forms';
import { requiredBoolean } from './required-boolean.validator';

export function getUpdateEquipmentlogtypeDtoFormGroup() {
return new FormGroup({
    name: new FormControl<string | undefined>('', [Validators.required]),
    technicalName: new FormControl<string | undefined>('', [Validators.required]),
    isStatus: new FormControl<boolean | undefined>(true, [requiredBoolean()]),
    allowStocklocation: new FormControl<boolean | undefined>(true, [requiredBoolean()]),
    description: new FormControl<string | undefined>(undefined),
    documentIds: new FormControl<Array<number> | undefined>([]),
})
;
}
      