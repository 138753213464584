import { Pipe, PipeTransform } from '@angular/core';
import { AxEventInvitation } from '../_generated/axova-rest-api';

@Pipe({ name: 'getDetailIconForEventInvitation' })
export class AxApiGetDetailIconForEventInvitationPipe implements PipeTransform {
  transform(eventInvitation: AxEventInvitation) {
    console.log(eventInvitation);
    if (eventInvitation.acceptedAt) {
      return 'checkmark-circle-outline';
    } else if (eventInvitation.declinedAt) {
      return 'close-circle-outline';
    } else {
      return 'ellipse-outline';
    }
  }
}
