import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {
  CreateElectricalMeasurementDetailPage,
} from 'src/app/pages/global/create-electrical-measurement/create-electrical-measurement-detail/create-electrical-measurement-detail.page';
import {
  CreateElectricalMeasurementOverviewPage,
} from 'src/app/pages/global/create-electrical-measurement/create-electrical-measurement-overview/create-electrical-measurement-overview.page';
import {
  CreateElectricalMeasurementStartPage,
} from 'src/app/pages/global/create-electrical-measurement/create-electrical-measurement-start/create-electrical-measurement-start.page';

const routes: Routes = [
  {
    path: '',
    component: CreateElectricalMeasurementStartPage,
  },
  {
    path: 'overview',
    component: CreateElectricalMeasurementOverviewPage,
  },
  {
    path: 'detail/:measurementId',
    component: CreateElectricalMeasurementDetailPage,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class CreateElectricalMeasurementRoutingModule {
}
