import { Pipe, PipeTransform } from '@angular/core';
import { LoggerService } from 'src/app/ax-commons-logger/logger/logger.service';

@Pipe({
  name: 'orderBy',
})
export class OrderByPipe implements PipeTransform {

  transform(array: any[], field: string, direction: string = 'asc'): any[] {
    if (!Array.isArray(array)) {
      return;
    }

    const dirMultiplier = direction.toLowerCase() === 'desc' ? -1 : 1;

    return array.sort((a, b) => {
      LoggerService.LOG(this, '===', a[field], b[field]);
      if (a[field] === null) {
        return -1;
      } // Push items with null to the end
      if (b[field] === null) {
        return 1;
      } // Push items with null to the end
      const dateA = new Date(a[field]);
      const dateB = new Date(b[field]);

      if (dateA < dateB) {
        return -1 * dirMultiplier;
      } else if (dateA > dateB) {
        return 1 * dirMultiplier;
      } else {
        return 0;
      }
    });
  }

}
