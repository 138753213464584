import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { AxComponentsModule } from 'src/app/components/ax-components.module';
import { CreateLeadPageRoutingModule } from 'src/app/pages/global/create-lead-form/create-lead-form-routing.module';
import { CreateLeadFormPage } from 'src/app/pages/global/create-lead-form/create-lead-form.page';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    AxComponentsModule,
    CreateLeadPageRoutingModule,
  ],
  declarations: [CreateLeadFormPage],
})
export class CreateLeadFormPageModule {
}
