import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { NavController } from '@ionic/angular';
import { Store } from '@ngxs/store';
import { format, utcToZonedTime } from 'date-fns-tz';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  AxElectricalMeasurement,
  AxElectricalMeasurementDevice,
  AxElectricalMeasurementsService,
  AxElectricalMeasurementType,
  AxProjectsService,
} from 'src/app/_generated/axova-rest-api';
import { LoggerService } from 'src/app/ax-commons-logger/logger/logger.service';
import { AppState } from 'src/app/ngxs/app/app.state';
import { RolesEnum } from 'src/app/ngxs/app/app.state.model';
import {
  CreateElectricalMeasurementClear,
  CreateElectricalMeasurementSetBase,
  CreateElectricalMeasurementSetProject,
} from 'src/app/ngxs/create-electrical-measurement/create-electrical-measurement.actions';
import {
  CreateElectricalMeasurementState,
} from 'src/app/ngxs/create-electrical-measurement/create-electrical-measurement.state';
import { AxUiService } from 'src/app/services/ax-ui.service';

@Component({
  selector: 'app-create-electrical-measurement-start',
  templateUrl: './create-electrical-measurement-start.page.html',
  styleUrls: ['./create-electrical-measurement-start.page.scss'],
})
export class CreateElectricalMeasurementStartPage implements OnInit {
  public electricalMeasurementTypes: AxElectricalMeasurementType[] = [];
  public electricalMeasurementDevices: AxElectricalMeasurementDevice[] = [];
  public baseMeasurement$ = this.store.select(CreateElectricalMeasurementState.baseMeasurement);
  public baseMeasurement: Partial<AxElectricalMeasurement> | undefined;
  public roles$ = this.store.select(AppState.roles);
  public isMonteurOnly = false;

  constructor(
    private router: Router,
    private title: Title,
    private store: Store,
    private axUiService: AxUiService,
    private activatedRoute: ActivatedRoute,
    private navController: NavController,
    private axElectricalMeasurementsService: AxElectricalMeasurementsService,
    private axProjectsService: AxProjectsService,
  ) {
  }

  ngOnInit() {
    // this.store.dispatch(new CreateElectricalMeasurementClear());
    this.activatedRoute.params.subscribe(async data => {
      if (data.id) {
        const project = await this.axProjectsService.projectsControllerFindOne({id: data.id}).toPromise();
        this.store.dispatch(new CreateElectricalMeasurementSetProject(project));

        combineLatest([
          this.baseMeasurement$,
          this.roles$,
          this.axElectricalMeasurementsService.electricalMeasurementsControllerGetTypes(),
          this.axElectricalMeasurementsService.electricalMeasurementsControllerGetDevices(),
        ])
          .pipe(
            map(([baseMeasurement, roles, types, devices]) => {
              this.electricalMeasurementTypes = types;
              this.electricalMeasurementDevices = devices;
              return {baseMeasurement, roles};
            }))
          .subscribe((result) => {
            if (result.roles) {
              this.isMonteurOnly = result.roles.indexOf(RolesEnum.monteur) > -1 && result.roles.indexOf(RolesEnum.elektriker) === -1;
            }

            // Create created date with timezone for datepicker.
            const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
            const date = new Date();
            const zonedTime = utcToZonedTime(date, userTimeZone);
            let formattedLocalDateTime = format(zonedTime, 'yyyy-MM-dd HH:mm:ssXXX', {timeZone: userTimeZone});
            formattedLocalDateTime = formattedLocalDateTime.replace(' ', 'T');

            if (result.baseMeasurement) {
              this.baseMeasurement = JSON.parse(JSON.stringify(result.baseMeasurement));
              this.baseMeasurement.created = formattedLocalDateTime;
            } else {
              this.baseMeasurement = {
                created: formattedLocalDateTime,
              };
            }

            if (this.isMonteurOnly) {
              this.baseMeasurement.electricalMeasurementTypeId = 2;
              this.setDeviceBasedOnType();
            }
          });
      }
    });
  }

  ionViewDidEnter() {
    this.title.setTitle(`Messung erfassen`);
  }

  public setDeviceBasedOnType() {
    if (this.baseMeasurement.electricalMeasurementTypeId) {
      const type = this.electricalMeasurementTypes
        .find(electricalMeasurementType => electricalMeasurementType.id === this.baseMeasurement.electricalMeasurementTypeId);
      if (type && type.defaultElectricalMeasurementDevice) {
        this.baseMeasurement.electricalMeasurementType = type;
        this.baseMeasurement.electricalMeasurementDeviceId = type.defaultElectricalMeasurementDeviceId;
      }
    }
  }

  public continue() {
    this.store.dispatch(new CreateElectricalMeasurementSetBase(this.baseMeasurement));
    this.router.navigateByUrl('overview').then();
  }

  public reset() {
    this.store.dispatch(new CreateElectricalMeasurementClear());
  }
}
