
import { FormGroup, FormControl, FormArray, Validators } from '@angular/forms';
import { requiredBoolean } from './required-boolean.validator';

export function getCreateScreenslideDtoFormGroup() {
return new FormGroup({
    name: new FormControl<string | undefined>('', [Validators.required]),
    layout: new FormControl<string | undefined>('', [Validators.required]),
    active: new FormControl<boolean | undefined>(true, [requiredBoolean()]),
    refreshInSeconds: new FormControl<number | undefined>(undefined),
    durationInSeconds: new FormControl<number | undefined>(undefined),
})
;
}
      